import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
} from "reactstrap"

// availity-reactstrap-validation

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility'

import Loader from "../../components/Loader";
import Table from "./Table";

const Agent = props => {
  const { user } = props;
  const [isAddForm, setIsAddForm] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [path, setPath] = useState("");
  // console.log('props on company', props);

  useEffect(() => {
    if (props.location) {
      // console.log('path on opportunities', props.location.pathname.split("/"));
      const path = props.location.pathname;
      if (path.split("/").length > 1) {
        const role = path.split("/")[2];
        if (role) {
          setPath(role);
        }
      }
    }
  }, [props]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Rashifal" />

        {/* {isAddForm ?
          <AddBannerForm
            role={"Banner"}
            user={user}
            data={currentData}
            close={setIsAddForm}
            loading={loading}
            setLoading={setLoading}
            path={path}
            setCurrentData={setCurrentData}
          />
          :  */}
        <Card>
          <CardBody>
            <Table
              role={"Rashifal"}
              path={path}
              user={props?.user}
              add={setIsAddForm}
              currentData={currentData}
              setCurrentData={setCurrentData}
              getProfile={props?.getProfile}
              updateCompany={props?.updateCompany}
              history={props.history}
              setLoading={setLoading}
            />
          </CardBody>
        </Card>
        {/* } */}
      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStatetoProps, {})(Agent)
)
