import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button,
    Modal, ModalBody, Label
} from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import Utils from '../Utility'

import avatar from "../../assets/images/small/sample.png"
// actions
import { post, upload, put, get } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";


const AddProperty = props => {
    const { user, data, setLoading, role } = props;
    const [file, setFile] = useState(null);
    const [image, setImage] = useState(null);
    const [banner, setBanner] = useState(null);
    const [imageChanged, setImageChanged] = useState(false);
    const imageInput = useRef();
    const imageInput2 = useRef();
    const cropper = useRef();
    const [currentData, setCurrentData] = useState(null);
    const [cropperModal, setCropperModal] = useState(false);
    const [cats, setCats] = useState([]);
    const [authors, setAuthors] = useState([]);
    const [audioFile, setAudioFile] = useState(undefined);
    // console.log('props on company', props?.data);

    useEffect(() => {
        if (data) {
            if (data?.image) {
                setFile(data?.image);
                setImage(data?.image);
            }
            if (data?.banner)
                setBanner(data?.banner);
        }
    }, [data]);

    useEffect(() => {
        if (user) {
            get('/story/category/drop', { token: user?.token })
                .then(res => {
                    setCats(res?.data);
                })
            get('/story/author/list', { token: user?.token })
                .then(res => {
                    setAuthors(res?.data);
                })
        }
    }, [user]);

    const onChangeFile = async (e, type) => {
        console.log('on change file', e.target);
        setFile(URL.createObjectURL(e.target.files[0]));
        setCurrentData(type);
        setCropperModal(true);
    }

    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1800,
                600,
                "webp",
                60,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    const cropImage = (e, v) => {
        setLoading(true);
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        if (currentData == 'file')
            setImage(fileData);
        else
            setBanner(fileData);
        setCropperModal(false);
        setLoading(false);
    }

    const handleValidSubmit = async (e, v) => {
        // console.log('image', image, typeof image);
        console.log('image', v);
        let temp = data?.image;
        let tempBanner = data?.banner;
        let audioUrl = data?.audio;
        if (image?.length > 90) {
            setLoading(true);
            const obj = await urltoFile(image, new Date().getTime() + '.png', 'image/png');
            const tempObj = await resizeFile(obj);
            const form = new FormData();
            form.append("image", tempObj);
            const uploadRes = await upload("/story/image_upload", form);
            if (uploadRes?.statusCode == 200)
                temp = uploadRes?.data;
        }
        if (banner?.length > 90) {
            setLoading(true);
            const obj = await urltoFile(banner, new Date().getTime() + '.png', 'image/png');
            const tempObj = await resizeFile(obj);
            const form = new FormData();
            form.append("image", tempObj);
            const uploadRes = await upload("/story/image_upload", form);
            if (uploadRes?.statusCode == 200)
                tempBanner = uploadRes?.data;
        }
        if (!temp) {
            toast.error("Select Image for this story!");
            return;
        }
        if (audioFile) {
            console.log("audio file", audioFile);
            const audioForm = new FormData();
            audioForm.append("audio", audioFile);
            const uploadRes = await upload("/story/audio_upload", audioForm);
            if (uploadRes?.statusCode == 200)
                audioUrl = uploadRes?.data;
        }
        if (!audioUrl) {
            toast.error("Select audio for this story!");
            return;
        }
        setLoading(true);
        let body = {
            ...v,
            image: temp,
            banner: tempBanner,
            audio: audioUrl,
            duration: {
                hour: v?.hour, minute: v?.minute, second: v?.second
            },
            token: user?.token
        }
        if (data) {
            body = { ...body, storyId: data?._id }
            put("/story/update", body)
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        toast.success(res?.message);
                        setImage(null);
                        setFile(null);
                        setAudioFile(undefined);
                        props?.close(false);
                    } else {
                        toast.success(res?.error);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    console.log('err while adding story', err);
                    toast.error('Something Went Wrong!')
                })
        } else {
            post("/story/add", body)
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        toast.success(res?.message);
                        setImage(null);
                        setFile(null);
                        props?.close(false);
                    } else {
                        toast.success(res?.error);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    console.log('err while adding story', err);
                    toast.error('Something Went Wrong!')
                })
        }
    }

    const handlePrimaryChange = (val) => {
        if (!banner) {
            if (val == "false")
                imageInput2.current.click();
        }
    }

    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg={11} md={11}>
                                        <Row>
                                            {file ?
                                                <Col lg={2} md={2} style={{ marginRight: '2rem' }}>
                                                    <div className="me-3 position-relative" style={{ width: 180, height: 180 }}>
                                                        <img
                                                            src={image ? image : avatar}
                                                            alt=""
                                                            style={{ width: '71%', height: '100%' }}
                                                        />
                                                        <div style={{ position: 'absolute', height: 40, width: 40, right: 35, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                            onClick={(e) => { setFile(null); setImage(null) }}
                                                        >
                                                            <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                                :
                                                <Col lg={2} md={2}>
                                                    <div className="me-3 position-relative" style={{ width: 180, height: 180 }}>
                                                        <div className='image_placeholder' style={{ width: '71%', height: '100%' }}
                                                            onClick={(e) => imageInput.current.click(e)}
                                                        >
                                                            <button style={{ backgroundColor: Utils.themeColor, borderRadius: 5, color: '#fff', padding: '5px 10px', border: 'none' }}> + Add</button>
                                                            <div style={{ position: 'absolute', height: 40, width: 40, right: 35, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                            >
                                                                <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                                                            </div>
                                                            <input type="file" id="file"
                                                                ref={imageInput}
                                                                onChange={(e) => onChangeFile(e, 'file')}
                                                                style={{ display: "none" }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                            }
                                            {banner &&
                                                <Col lg={2} md={2} style={{ marginRight: '2rem' }}>
                                                    <div className="me-3 position-relative" style={{ width: 360, height: 180 }}>
                                                        <img
                                                            src={banner ? banner : avatar}
                                                            alt=""
                                                            style={{ width: '100%', height: '100%' }}
                                                        />
                                                        <div style={{ position: 'absolute', height: 40, width: 40, right: -20, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                            onClick={(e) => { setBanner(null) }}
                                                        >
                                                            <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                            }
                                        </Row>
                                    </Col>
                                    <Col lg={1} md={1}>
                                        <div className="text-center" style={{ marginLeft: 'auto' }}>
                                            <Button color="primary" onClick={() => { props.setCurrentData(null); props?.close(false) }} style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                                                Close
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* Image cropper modal */}
                <Modal isOpen={cropperModal} toggle={() => { setCropperModal(false); setFile(null) }} centered={true}>
                    <ModalBody className="py-3 px-5">
                        <h5 className="text-black font-size-20">Crop Image</h5>
                        <div className="p-2">
                            <AvForm
                                className="form-horizontal"
                                onValidSubmit={(e, v) => {
                                    cropImage()
                                }}
                            >
                                <div className="mb-3 mt-2">
                                    <Cropper
                                        style={{ height: 'auto', width: 400 }}
                                        aspectRatio={currentData == 'file' ? 1000 / 1400 : 600 / 300}
                                        preview=".img-preview"
                                        guides={true}
                                        src={file}
                                        ref={cropper}
                                    />
                                </div>
                                <div className="mt-4">
                                    <Row>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                style={{ backgroundColor: Utils.themeColor }}
                                                onClick={() => { setCropperModal(false); setFile(null) }}
                                                type="reset"
                                            >
                                                Cancel
                                            </button>
                                        </Col>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                type="submit"
                                                style={{ backgroundColor: Utils.themeColor }}
                                            >
                                                Submit
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </AvForm>
                        </div>
                    </ModalBody>
                </Modal>

                <h4 className="card-title mb-4">{data ? 'Update ' + role : 'Add a ' + role}</h4>

                <Card>
                    <CardBody>
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                handleValidSubmit(e, v)
                            }}
                        >
                            <Row>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="titleEn"
                                            label="Title English *"
                                            value={data?.titleEn}
                                            className="form-control"
                                            placeholder="Enter Title in English"
                                            type="text"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="titleHi"
                                            label="Title Hindi *"
                                            value={data?.titleHi}
                                            className="form-control"
                                            placeholder="Enter Title in Hindi"
                                            type="text"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="catId"
                                            label="Category*"
                                            className="form-control"
                                            type="select"
                                            value={data?.catId}
                                            required
                                        >
                                            <option value={""} >Select Category</option>
                                            {cats?.map((item) => (
                                                <option value={item?._id} >{item?.titleEn}</option>
                                            ))}
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="authorId"
                                            label="Author *"
                                            className="form-control"
                                            type="select"
                                            value={data?.authorId}
                                            required
                                        >
                                            <option value={""} >Select Author</option>
                                            {authors?.map((item) => (
                                                <option value={item?._id} >{item?.nameEn}</option>
                                            ))}
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="language"
                                            label="Language *"
                                            className="form-control"
                                            type="select"
                                            value={data?.language}
                                            required
                                        >
                                            <option value={""} >Select Language</option>
                                            <option value={"हिंदी"} >हिंदी</option>
                                            <option value={"English"} >English</option>
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="vocal"
                                            label="Vocal *"
                                            className="form-control"
                                            type="select"
                                            value={data?.vocal}
                                            required
                                        >
                                            <option value={""} >Select Vocal</option>
                                            <option value={"Male"} >Male</option>
                                            <option value={"पुरुष"} >पुरुष</option>
                                            <option value={"Female"} >Female</option>
                                            <option value={"महिला"} >महिला</option>
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="Audio"
                                            label="Audio *"
                                            // value={data?.audio}
                                            className="form-control"
                                            placeholder="Enter banner related color code (i.e) 00ff00"
                                            type="file"
                                            accept="audio/mp3"
                                            multiple={false}
                                            onChange={(e) => setAudioFile(e.target.files[0])}
                                        // required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <Label>Audio Duration *</Label>
                                    <div className="form-group mb-4 d-flex align-items-center">
                                        <AvField
                                            name="hour"
                                            value={data?.duration?.hour}
                                            className="form-control"
                                            type="select"
                                            required
                                        >
                                            <option value="">Select Hour</option>
                                            <option value={0}>0</option>
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                            <option value={4}>4</option>
                                            <option value={5}>5</option>
                                            <option value={6}>6</option>
                                        </AvField>
                                        <span className='mx-2'>:</span>
                                        <AvField
                                            name="minute"
                                            value={data?.duration?.minute}
                                            className="form-control"
                                            type="select"
                                            required
                                        >
                                            <option value="">Select minute</option>
                                            <option value={0}>00</option>
                                            <option value={1}>01</option>
                                            <option value={2}>02</option>
                                            <option value={3}>03</option>
                                            <option value={4}>04</option>
                                            <option value={5}>05</option>
                                            <option value={6}>06</option>
                                            <option value={7}>07</option>
                                            <option value={8}>08</option>
                                            <option value={9}>09</option>
                                            <option value={10}>10</option>
                                            <option value={11}>11</option>
                                            <option value={12}>12</option>
                                            <option value={13}>13</option>
                                            <option value={14}>14</option>
                                            <option value={15}>15</option>
                                            <option value={16}>16</option>
                                            <option value={17}>17</option>
                                            <option value={18}>18</option>
                                            <option value={19}>19</option>
                                            <option value={20}>20</option>
                                            <option value={21}>21</option>
                                            <option value={22}>22</option>
                                            <option value={23}>23</option>
                                            <option value={24}>24</option>
                                            <option value={25}>25</option>
                                            <option value={26}>26</option>
                                            <option value={27}>27</option>
                                            <option value={28}>28</option>
                                            <option value={29}>29</option>
                                            <option value={30}>30</option>
                                            <option value={31}>31</option>
                                            <option value={32}>32</option>
                                            <option value={33}>33</option>
                                            <option value={34}>34</option>
                                            <option value={35}>35</option>
                                            <option value={36}>36</option>
                                            <option value={37}>37</option>
                                            <option value={38}>38</option>
                                            <option value={39}>39</option>
                                            <option value={40}>40</option>
                                            <option value={41}>41</option>
                                            <option value={42}>42</option>
                                            <option value={43}>43</option>
                                            <option value={44}>44</option>
                                            <option value={45}>45</option>
                                            <option value={46}>46</option>
                                            <option value={47}>47</option>
                                            <option value={48}>48</option>
                                            <option value={49}>49</option>
                                            <option value={50}>50</option>
                                            <option value={51}>51</option>
                                            <option value={52}>52</option>
                                            <option value={53}>53</option>
                                            <option value={54}>54</option>
                                            <option value={55}>55</option>
                                            <option value={56}>56</option>
                                            <option value={57}>57</option>
                                            <option value={58}>58</option>
                                            <option value={59}>59</option>
                                        </AvField>
                                        <span className='mx-2'>:</span>
                                        <AvField
                                            name="second"
                                            value={data?.duration?.second}
                                            className="form-control"
                                            type="select"
                                            required
                                        >
                                            <option value="">Select Second</option>
                                            <option value={0}>00</option>
                                            <option value={1}>01</option>
                                            <option value={2}>02</option>
                                            <option value={3}>03</option>
                                            <option value={4}>04</option>
                                            <option value={5}>05</option>
                                            <option value={6}>06</option>
                                            <option value={7}>07</option>
                                            <option value={8}>08</option>
                                            <option value={9}>09</option>
                                            <option value={10}>10</option>
                                            <option value={11}>11</option>
                                            <option value={12}>12</option>
                                            <option value={13}>13</option>
                                            <option value={14}>14</option>
                                            <option value={15}>15</option>
                                            <option value={16}>16</option>
                                            <option value={17}>17</option>
                                            <option value={18}>18</option>
                                            <option value={19}>19</option>
                                            <option value={20}>20</option>
                                            <option value={21}>21</option>
                                            <option value={22}>22</option>
                                            <option value={23}>23</option>
                                            <option value={24}>24</option>
                                            <option value={25}>25</option>
                                            <option value={26}>26</option>
                                            <option value={27}>27</option>
                                            <option value={28}>28</option>
                                            <option value={29}>29</option>
                                            <option value={30}>30</option>
                                            <option value={31}>31</option>
                                            <option value={32}>32</option>
                                            <option value={33}>33</option>
                                            <option value={34}>34</option>
                                            <option value={35}>35</option>
                                            <option value={36}>36</option>
                                            <option value={37}>37</option>
                                            <option value={38}>38</option>
                                            <option value={39}>39</option>
                                            <option value={40}>40</option>
                                            <option value={41}>41</option>
                                            <option value={42}>42</option>
                                            <option value={43}>43</option>
                                            <option value={44}>44</option>
                                            <option value={45}>45</option>
                                            <option value={46}>46</option>
                                            <option value={47}>47</option>
                                            <option value={48}>48</option>
                                            <option value={49}>49</option>
                                            <option value={50}>50</option>
                                            <option value={51}>51</option>
                                            <option value={52}>52</option>
                                            <option value={53}>53</option>
                                            <option value={54}>54</option>
                                            <option value={55}>55</option>
                                            <option value={56}>56</option>
                                            <option value={57}>57</option>
                                            <option value={58}>58</option>
                                            <option value={59}>59</option>
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="descEn"
                                            label="Description English *"
                                            value={data?.descEn}
                                            className="form-control"
                                            placeholder="Enter description in English"
                                            type="text"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="descHi"
                                            label="Description in Hindi *"
                                            value={data?.descHi}
                                            className="form-control"
                                            placeholder="Enter description in Hindi"
                                            type="text"
                                            required
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2}>
                                    <div className='d-flex align-items-center'>
                                        <AvField
                                            name="isPrimary"
                                            placeholder="Primary"
                                            type="checkbox"
                                            id="isPrimary"
                                            value={data?.isPrimary}
                                            onChange={(e) => handlePrimaryChange(e.target.value)}
                                        />
                                        <Label className='mb-0' for="isPrimary" style={{ cursor: 'pointer', userSelect: 'none' }}>Primary</Label>
                                    </div>
                                    <input type="file" id="file2"
                                        ref={imageInput2}
                                        onChange={(e) => onChangeFile(e, 'file2')}
                                        style={{ display: "none" }} />
                                </Col>
                                <Col md={2}>
                                    <div className='d-flex align-items-center'>
                                        <AvField
                                            name="isSecondary"
                                            placeholder="Secondary"
                                            type="checkbox"
                                            id="isSecondary"
                                            value={data?.isSecondary}
                                        />
                                        <Label className='mb-0' for="isSecondary" style={{ cursor: 'pointer', userSelect: 'none' }}>Secondary</Label>
                                    </div>
                                </Col>
                            </Row>
                            {/* <AvField name="idx" value={user?.company._id} type="hidden" /> */}
                            <div className="text-center mt-4">
                                {data ?
                                    <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                        Update {role}
                                    </Button>
                                    :
                                    <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                        Add {role}
                                    </Button>
                                }
                            </div>
                        </AvForm>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}

AddProperty.propTypes = {
    error: PropTypes.any,
    success: PropTypes.any,
    companyError: PropTypes.func,
    companySuccess: PropTypes.func,
    createCompany: PropTypes.func,
    updateCompany: PropTypes.func
}

const mapStatetoProps = state => {
    const { user } = state.Profile;
    return { user }
}

export default withRouter(
    connect(mapStatetoProps, {})(AddProperty)
)
