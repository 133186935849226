import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Modal,
  ModalBody
} from "reactstrap";
import './style.scss';

// availity-reactstrap-validation

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility'

import Loader from "../../components/Loader";
import { get, put } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import { AvField, AvForm } from 'availity-reactstrap-validation';

const Setting = props => {
  const { user } = props;
  const [loading, setLoading] = useState(false);
  const [isShop, setIsShop] = useState(undefined);
  const [isMaintain, setIsMaintain] = useState(undefined);
  const [isStory, setIsStory] = useState(undefined);
  const [eventNoti, setEventNoti] = useState(undefined);
  const [quoteNoti, setQuoteNoti] = useState(undefined);
  const [rashiNoti, setRashiNoti] = useState(undefined);
  const [luckyNumNoti, setLuckyNumNoti] = useState(undefined);
  const [error, setError] = useState(false);
  const [notiConfigModal, setNotiConfigModal] = useState(false);
  const [raamNotiData, setRaamNotiData] = useState(undefined);
  const [webPay, setWebPay] = useState(undefined);

  useEffect(() => {
    if (user?.token)
      getSettings();
  }, [user]);

  const getSettings = () => {
    get('/setting', { token: user?.token })
      .then(res => {
        if (res?.statusCode == 200) {
          setIsShop(res?.data?.shop);
          setIsMaintain(res?.data?.maintinance);
          setIsStory(res?.data?.story);
          setEventNoti(res?.data?.eventNoti);
          setQuoteNoti(res?.data?.quoteNoti);
          setRashiNoti(res?.data?.rashiNoti);
          setLuckyNumNoti(res?.data?.luckyNumNoti);
          setWebPay(res?.data?.webPay);
        } else {
          toast.error(res?.error);
          setError(true);
        }
      })
      .catch(err => {
        console.log('error while getting setting', err);
        toast.error("" + err);
      });
  }

  const handleChangeToggle = (val, to) => {
    // console.log('changing toggle', val, typeof val)
    if (user?.role != "Admin") {
      if (user?.permissions?.length && user?.permissions[13]?.settings?.update) {

      } else {
        toast.error("You're not authorized for this action");
        return;
      }
    }
    const body = {
      [to]: !val,
      token: user?.token
    }
    put("/setting/update", body)
      .then(res => {
        if (res?.statusCode == 200) {
          toast.success(res?.message);
          setIsShop(res?.data?.shop);
          setIsMaintain(res?.data?.maintinance);
          setIsStory(res?.data?.story);
          setEventNoti(res?.data?.eventNoti);
          setQuoteNoti(res?.data?.quoteNoti);
          setRashiNoti(res?.data?.rashiNoti);
          setLuckyNumNoti(res?.data?.luckyNumNoti);
          setWebPay(res?.data?.webPay);
          if (to == "eventNoti")
            get("/calendar/noti?val=" + val)
          if (to == "quoteNoti")
            get("/quotenoti?val=" + val)
          if (to == "rashiNoti")
            get("/rashiNoti?val=" + val)
        }
        else
          toast.error(res?.error);
      })
      .catch(err => {
        console.log('error whiling setting setting', err);
        toast.error(err + "");
      })
  }

  const handleConfig = (type) => {
    setNotiConfigModal(true);
  }

  const handleValidSubmit = (e, v) => {
    const body = {
      raamNotiData: v,
      token: user?.token
    }
    put("/setting/update", body)
      .then(res => {
        if (res?.statusCode == 200) {
          setRaamNotiData(res?.data?.raamNotiData);
          setNotiConfigModal(false);
        } else {
          toast.error(res?.error);
        }
      })
      .catch(err => {
        console.log('error while updating raam noti data', err);
        toast.error('Something Went Wrong!');
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Settings" />
        <Modal className="modal_form" isOpen={notiConfigModal} centered={true}>
          <ModalBody className="py-3 px-5">
            <h5 className="text-black font-size-20">{'Configure Raam Naam Notification'}</h5>
            <div className="p-2">
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v)
                }}
              >
                <Row>
                  <Col lg={12} md={12}>
                    <div className="mb-3 mt-2">
                      <AvField
                        name="title"
                        label="Notification Title"
                        className="form-control"
                        placeholder="Enter something about your notification"
                        type="text"
                        required
                        value={raamNotiData?.title}
                      // validate={{min:{value:1}}}
                      />
                    </div>
                  </Col>
                  <Col lg={12} md={12}>
                    <div className="mb-3 mt-2">
                      <AvField
                        name="body"
                        label="Notification Description"
                        className="form-control"
                        placeholder="Enter detailed notification"
                        type="textarea"
                        rows={4}
                        required
                        value={raamNotiData?.body}
                      // validate={{min:{value:1}}}
                      />
                    </div>
                  </Col>
                  <Col lg={12} md={12}>
                    <div className="mt-4">
                      <Row>
                        <Col md={6}>
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                            onClick={() => { setNotiConfigModal(false); }}
                            type="reset"
                          >
                            Cancel
                          </button>
                        </Col>
                        <Col md={6}>
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                            style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                          >
                            Update
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </div>
          </ModalBody>
        </Modal>
        {!error &&
          <Row>
            {/* <Col lg={4} md={4}>
              <Card>
                <CardBody>
                  <div className='d-flex align-items-center justify-content-between'>
                    <i className='mdi mdi-cart' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 20 }} />
                    <span style={{ fontWeight: 'bolder' }}>Shop Store</span>
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        className="toggle-switch-checkbox"
                        name="toggleSwitch"
                        id="shop"
                        onChange={(e) => handleChangeToggle(isShop, 'shop')}
                        checked={isShop}
                      />
                      <label className="toggle-switch-label" htmlFor="shop">
                        <span className="toggle-switch-inner" />
                        <span className="toggle-switch-switch" />
                      </label>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
            <Col lg={4} md={4}>
              <Card>
                <CardBody>
                  <div className='d-flex align-items-center justify-content-between'>
                    <i className='mdi mdi-cogs' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 20 }} />
                    <span style={{ fontWeight: 'bolder' }}>Maintinance</span>
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        className="toggle-switch-checkbox"
                        name="toggleSwitch"
                        id="maintinance"
                        onChange={(e) => handleChangeToggle(isMaintain, 'maintinance')}
                        checked={isMaintain}
                      />
                      <label className="toggle-switch-label" htmlFor="maintinance">
                        <span className="toggle-switch-inner" />
                        <span className="toggle-switch-switch" />
                      </label>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={4} md={4}>
              <Card>
                <CardBody>
                  <div className='d-flex align-items-center justify-content-between'>
                    <i className='mdi mdi-music-box' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 20 }} />
                    <span style={{ fontWeight: 'bolder' }}>Stories</span>
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        className="toggle-switch-checkbox"
                        name="toggleSwitch"
                        id="story"
                        onChange={(e) => handleChangeToggle(isStory, 'story')}
                        checked={isStory}
                      />
                      <label className="toggle-switch-label" htmlFor="story">
                        <span className="toggle-switch-inner" />
                        <span className="toggle-switch-switch" />
                      </label>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={4} md={4}>
              <Card>
                <CardBody>
                  <div className='d-flex align-items-center justify-content-between'>
                    <i className='mdi mdi-bell' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 20 }} />
                    <span style={{ fontWeight: 'bolder' }}>Event Notification</span>
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        className="toggle-switch-checkbox"
                        name="toggleSwitch"
                        id="eventNoti"
                        onChange={(e) => handleChangeToggle(eventNoti, 'eventNoti')}
                        checked={eventNoti}
                      />
                      <label className="toggle-switch-label" htmlFor="eventNoti">
                        <span className="toggle-switch-inner" />
                        <span className="toggle-switch-switch" />
                      </label>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={4} md={4}>
              <Card>
                <CardBody>
                  <div className='d-flex align-items-center justify-content-between'>
                    <i className='mdi mdi-bell' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 20 }} />
                    <span style={{ fontWeight: 'bolder' }}>Suvichar Notification</span>
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        className="toggle-switch-checkbox"
                        name="toggleSwitch"
                        id="quoteNoti"
                        onChange={(e) => handleChangeToggle(quoteNoti, 'quoteNoti')}
                        checked={quoteNoti}
                      />
                      <label className="toggle-switch-label" htmlFor="quoteNoti">
                        <span className="toggle-switch-inner" />
                        <span className="toggle-switch-switch" />
                      </label>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={4} md={4}>
              <Card>
                <CardBody>
                  <div className='d-flex align-items-center justify-content-between'>
                    <i className='mdi mdi-bell' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 20 }} />
                    <span style={{ fontWeight: 'bolder' }}>Rashifal Notification</span>
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        className="toggle-switch-checkbox"
                        name="toggleSwitch"
                        id="rashiNoti"
                        onChange={(e) => handleChangeToggle(rashiNoti, 'rashiNoti')}
                        checked={rashiNoti}
                      />
                      <label className="toggle-switch-label" htmlFor="rashiNoti">
                        <span className="toggle-switch-inner" />
                        <span className="toggle-switch-switch" />
                      </label>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={4} md={4}>
              <Card>
                <CardBody>
                  <div className='d-flex align-items-center justify-content-between'>
                    <i className='mdi mdi-bell' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 20 }} />
                    <span style={{ fontWeight: 'bolder' }}>Lucky Days Notification</span>
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        className="toggle-switch-checkbox"
                        name="toggleSwitch"
                        id="luckyNumNoti"
                        onChange={(e) => handleChangeToggle(luckyNumNoti, 'luckyNumNoti')}
                        checked={luckyNumNoti}
                      />
                      <label className="toggle-switch-label" htmlFor="luckyNumNoti">
                        <span className="toggle-switch-inner" />
                        <span className="toggle-switch-switch" />
                      </label>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={4} md={4}>
              <Card>
                <CardBody>
                  <div className='d-flex align-items-center justify-content-between'>
                    <i className='mdi mdi-currency-usd' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 20 }} />
                    <span style={{ fontWeight: 'bolder' }}>Web Payment</span>
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        className="toggle-switch-checkbox"
                        name="toggleSwitch"
                        id="webPay"
                        onChange={(e) => handleChangeToggle(webPay, 'webPay')}
                        checked={webPay}
                      />
                      <label className="toggle-switch-label" htmlFor="webPay">
                        <span className="toggle-switch-inner" />
                        <span className="toggle-switch-switch" />
                      </label>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        }
      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStatetoProps, {})(Setting)
)
