import React, { useEffect, useState } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';

import Utils from '../../Utility';
import { post, get, put, del } from "../../../helpers/api_helper";
import DeleteModal from '../DeleteModal';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { AvField, AvForm } from "availity-reactstrap-validation";
import { saveAs } from 'file-saver';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import moment from "moment";

const Table = (props) => {
  const { user, role, currentData, from, setCurrentData, setLoading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [error, setError] = useState(false);
  const [cat, setCat] = useState([]);
  const [selectedCat, setSelectedCat] = useState(undefined);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (user?.token) {
      getData();
      get('/calendar/cat/drop', { token: user?.token })
        .then(res => {
          setCat(res?.data);
        })
    }
  }, [user]);

  useEffect(() => {
    getData();
  }, [selectedCat, search])

  const getData = () => {
    get(`/calendar/event/list?cat=${selectedCat}&search=${search}`, { token: user?.token })
      .then(json => {
        // console.log('response from get project list', json);
        if (json?.statusCode == 200) {
          setData(json?.data);
        } else {
          toast.error(json?.error);
          setError(true);
        }
      })
      .catch(error => {
        console.log('error while getting project list', error);
      })
  }

  const handleIsPopular = (id, val) => {
    console.log('changing popular', id, val);
    const body = {
      productId: id,
      isPopular: val == "true" ? false : true,
      token: user?.token
    }
    put("/calendar/event", body)
      .then(res => {
        console.log('response from changing popular', res);
        if (res?.statusCode == 200) {
          toast.success(res?.message);
        } else
          toast.error(res?.erorr);
      })
      .catch(error => {
        console.log('error while changing popular', error);
        toast.error(error);
      })
  }

  useEffect(() => {
    setColumns([
      // {
      //   name: "Position",
      //   cell: (row, index) => <>
      //     <Draggable key={row._id} draggableId={row._id} index={index}>
      //       {(provided, snapshot) => (
      //         <div
      //           ref={provided.innerRef}
      //           {...provided.draggableProps}
      //           {...provided.dragHandleProps}
      //         >
      //           <i className="mdi mdi-menu" style={{ fontSize: 20, color: Utils.themeColor }}></i>
      //         </div>
      //       )}
      //     </Draggable>
      //   </>,
      //   maxWidth: '50px'
      // },
      {
        name: 'Month',
        cell: (row) => row?.month?.length ? row?.month[0]?.title : "",
        selector: row => row?.month?.length ? row?.month[0]?.position : "",
        sortable: true,
        maxWidth: "130px"
      },
      {
        name: 'Date',
        selector: row => new Date(row?.date).getDate(),
        sortable: true,
        maxWidth: "100px"
      },
      {
        name: 'Title',
        selector: row => row?.title,
        sortable: true
      },
      {
        name: 'Category',
        selector: row => row?.catId?.length ? row?.catId?.map((item) => (<span>{item?.title}, </span>)) : "",
        sortable: true,
        maxWidth: "180px"
      },
      {
        name: "Created At",
        selector: row => moment(row?.createdAt).format("DD-MMM-YYYY hh:mm a"),
        sortable: true,
        maxWidth: "180px"
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
        maxWidth: "110px"
      },
      {
        cell: (row) => <>
          {user?.role == "Admin" ?
            <>
              <Button onClick={() => handleDuplicate(row)}
                title={"Duplicate"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-content-copy" style={{ fontSize: 20 }}></span></Button>
              <Button onClick={() => handleUpdateProj(row)}
                title={"Edit"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
              <Button onClick={() => handleDeleteProj(row)}
                title={"Delete"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
            </>
            :
            <>
              {user?.permissions?.length && user?.permissions[6]?.calendar?.write &&
                <Button onClick={() => handleDuplicate(row)}
                  title={"Duplicate"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-content-copy" style={{ fontSize: 20 }}></span></Button>
              }
              {user?.permissions?.length && user?.permissions[6]?.calendar?.update &&
                <Button onClick={() => handleUpdateProj(row)}
                  title={"Edit"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
              }
              {user?.permissions?.length && user?.permissions[6]?.calendar?.delete &&
                <Button onClick={() => handleDeleteProj(row)}
                  title={"Delete"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
              }
            </>}
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: "150px"
      },
    ])
  }, [data]);

  const handleDuplicate = (proj) => {
    // console.log('updating proj', proj);
    props?.setCurrentData({ ...proj, _id: undefined });
    props.add(true);
  }

  const handleUpdateProj = (proj) => {
    // console.log('updating proj', proj);
    props?.setCurrentData(proj);
    props.add(true);
  }

  const handleUpdateStatus = (item) => {
    // console.log('updating proj', item);
    setCurrentData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj) => {
    // console.log('updating proj', proj);
    props?.setCurrentData({ ...proj, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      eventId: currentData?._id,
      token: user?.token
    }
    let url = "/calendar/event";
    if (currentData?.actionType == 'Status') {
      body = { ...body, isActive: !currentData?.isActive };
      put(url, body)
        .then(json => {
          console.log('response from on click action', json);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getData();
            setDeleteModal(false);
            props?.setCurrentData(null);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          console.error('error while deleting user', error);
        })
    } else {
      body = { ...body, isDeleted: true };
      del(url, body)
        .then(json => {
          console.log('response from on click action', json);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getData();
            setDeleteModal(false);
            props?.setCurrentData(null);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          console.error('error while deleting user', error);
        })
    }

  }

  const handleCountChange = (id, val) => {
    let body = {
      storyId: id,
      count: val,
      token: user?.token
    }
    put("/story/update", body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          // getData();
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const handleDragEnd = (result) => {
    // console.log('dragging item', result);
    // Handle the drag-and-drop logic here
    if (!result.destination) return; // Item was dropped outside the list
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/calender/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            data={currentData}
          />
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <div>
                    <CardTitle className="h4">{props.role}</CardTitle>
                    <CardSubtitle className="mb-3">
                      Events you have added before,<code> Anybody</code> can <code>check</code>. Only if <code>Status</code> is Active.
                    </CardSubtitle>
                  </div>
                  <div className="text-center" style={{ marginLeft: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <AvForm>
                      <AvField
                        name="filter"
                        type="select"
                        Label="Category"
                        onChange={(e) => setSelectedCat(e.target.value)}
                      >
                        <option value={""}>Select Category</option>
                        {cat?.map((item) => (
                          <option value={item?._id}>{item?.title}</option>
                        ))}
                      </AvField>
                    </AvForm>
                    {user?.role == "Admin" ?
                      <Button style={{ backgroundColor: Utils.themeColor, border: 'none', marginLeft: 40 }} onClick={() => props?.add(true)}>
                        Add
                      </Button>
                      :
                      <>
                        {user?.permissions?.length && user?.permissions[6]?.calendar?.write ?
                          <Button style={{ backgroundColor: Utils.themeColor, border: 'none', marginLeft: 40 }} onClick={() => props?.add(true)}>
                            Add
                          </Button>
                          : null}
                      </>
                    }
                  </div>
                </div>
                <Row className="my-3">
                  <Col md={8}>
                    <AvForm>
                      <div>
                        <AvField
                          name="search"
                          label="Search"
                          placeholder="Search by title, month and category"
                          type="text"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        >
                        </AvField>
                      </div>
                    </AvForm>
                  </Col>
                </Row>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="table">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <DataTable
                          columns={columns}
                          data={data}
                          pagination
                          paginationPerPage={30}
                          paginationRowsPerPageOptions={[30, 60, 90, 120]}
                          customStyles={{
                            headCells: {
                              style: {
                                color: 'black',
                                fontWeight: 'bold',
                                fontSize: 15,
                                width: 0
                              },
                            },
                            cells: {
                              style: {
                                width: 0
                              }
                            }
                          }}
                        />
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default Table;
