import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { AvForm, AvField } from "availity-reactstrap-validation";
import Utils from '../Utility';
import { post, get, put } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import './Card.scss';
import { toast } from "react-toastify";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import moment from "moment/moment";
import { setRef } from "@fullcalendar/react";

const NotificationTable = (props) => {
  const { user, role, setLoading, loading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isAddForm, setIsAddForm] = useState(false);
  const [currentModalData, setCurrentModalData] = useState(null);
  const [error, setError] = useState(false);
  const [search, setSearch] = useState("");
  useEffect(() => {
    if (user)
      getData();
  }, [user, search]);

  const getData = () => {
    if (!loading) {
      setLoading(true);
      get(`/screen?search=${search}`, { token: user?.token })
        .then(json => {
          setLoading(false);
          console.log('response from get user list', json);
          if (json.statusCode == 200) {
            setData(json?.data);
          } else {
            toast.error(json?.error);
            setError(true);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while getting user list', error);
        });
    }
  }

  useEffect(() => {
    setColumns([
      {
        name: "Position",
        cell: (row, index) => <>
          <Draggable key={row._id} draggableId={row._id} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <i className="mdi mdi-menu" style={{ fontSize: 20, color: Utils.themeColor }}></i>
              </div>
            )}
          </Draggable>
        </>,
        maxWidth: '50px'
      },
      {
        name: 'Title',
        selector: row => row?.title,
      },
      {
        name: 'Visit',
        selector: row => row?.visit > 0 ? row?.visit : "-",
        sortable: true,
        maxWidth: "120px"
      },
      {
        name: 'Created At',
        selector: row => moment(row?.createdAt).format("DD-MMM-YYYY hh:mm a"),
        sortable: true,
        maxWidth: "180px"
      },
      user?.role == 'Admin' &&
      {
        cell: (row) => <>
          <Button
            title={"View"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-finance" style={{ fontSize: 20 }}></span></Button>
          <Button onClick={() => handleUpdateProj(row)}
            title={"Edit"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
          <Button onClick={() => handleDeleteProj(row)}
            title={"Delete"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: '150px'
      },
    ])
  }, [data]);

  const handleValidSubmit = async (e, values, type) => {
    // console.log('values', values, file);
    setLoading(true);
    if (currentModalData) {
      const body = {
        ...values,
        screenId: currentModalData?._id,
        token: user?.token
      }
      put("/screen/update", body)
        .then(json => {
          console.log('response from add state', json);
          setLoading(false);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getData();
            setIsAddForm(false);
            setCurrentModalData(null);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while uploading image', error);
          toast.error(error);
        })
        .catch(error => {
          setLoading(false);
          console.log('error while uploading image', error);
          toast.error(error);
        });
    } else {
      const body = {
        ...values,
        token: user?.token
      }
      post("/screen/add", body)
        .then(json => {
          console.log('response from add state', json);
          setLoading(false);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getData();
            setIsAddForm(false);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while uploading image', error);
          toast.error(error);
        })
    }
  }

  const handleUpdateProj = (proj) => {
    console.log('updating proj', proj);
    setCurrentModalData(proj);
    setIsAddForm(true);
  }

  const handleDeleteProj = (proj) => {
    console.log('deleting proj', proj);
    setCurrentModalData(proj);
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentModalData);
    post("/screen/delete", { screenId: currentModalData?._id, token: user?.token })
      .then(json => {
        console.log('response from delete noti', json);
        if (json.statusCode == 200) {
          getData();
          setDeleteModal(false);
          setCurrentModalData(null);
        }
      })
      .catch(error => {
        console.error('error while deleting noti', error);
      })
  }

  const handleDragEnd = (result) => {
    // console.log('dragging item', result);
    // Handle the drag-and-drop logic here
    if (!result.destination) return; // Item was dropped outside the list
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/screen/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
          />
          <Modal isOpen={isAddForm} toggle={() => { setIsAddForm(false); }} centered={true}>
            <ModalBody className="py-3 px-5">
              <h5 className="text-black font-size-20">{currentModalData ? `Update ${role}!` : `Add New ${role}!`}</h5>
              <div className="p-2">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v, 'bank')
                  }}
                >
                  <Row>
                    <Col lg={12} md={12}>
                      <div className="mb-3">
                        <AvField
                          name="title"
                          label="Title"
                          className="form-control"
                          placeholder="Enter screen name"
                          type="text"
                          required
                          value={currentModalData?.title}
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="mb-3">
                        <AvField
                          name="for"
                          label="For"
                          className="form-control"
                          placeholder="Enter internal screen name"
                          type="text"
                          required
                          value={currentModalData?.for}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="mt-4">
                    <Row>
                      <Col md={6}>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                          onClick={() => { setIsAddForm(false); }}
                          type="reset"
                        >
                          Cancel
                        </button>
                      </Col>
                      <Col md={6}>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                          style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                        >
                          {currentModalData ? 'Update' : 'Add'}
                        </button>
                      </Col>
                    </Row>
                  </div>
                </AvForm>
              </div>
            </ModalBody>
          </Modal>
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <div>
                    <CardTitle className="h4">{role}</CardTitle>
                    <CardSubtitle className="mb-3">
                      All {role} you have added before. <code>Analytics Data</code> will work according to these screens.
                    </CardSubtitle>
                  </div>
                  {user?.role == 'Admin' &&
                    <div className="text-center" style={{ marginLeft: 'auto' }}>
                      {!error &&
                        <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                          Add
                        </Button>
                      }
                    </div>
                  }
                </div>
                <Row className="my-3">
                  <Col md={8}>
                    <AvForm>
                      <div>
                        <AvField
                          name="search"
                          label="Search"
                          placeholder="Search by screen"
                          type="text"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        >
                        </AvField>
                      </div>
                    </AvForm>
                  </Col>
                </Row>
                {/* <div className="d-flex">
                  {data?.map((item, index) => {
                    return (
                      <div class="flip-card">
                        <div class="flip-card-inner">
                          <div class="flip-card-front" style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor }}>
                            <p>{item?.name}</p>
                            <span>{item?.intro}</span>
                          </div>
                          <div class="flip-card-back" style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor }}>
                            <p>Leads</p>
                            <span>Open : 10</span>
                            <span>Close : 5</span>
                            <span>Rejected : 2</span>
                            <div className="action">
                              <Button onClick={()=>props?.history.push("/lead/project/"+item?._id)}><i className="mdi mdi-eye"></i></Button>
                              <Button onClick={()=>handleDeleteProj(item)}><i className="mdi mdi-trash-can"></i></Button>
                              <Button onClick={()=>handleUpdateProj(item)}><i className="mdi mdi-pencil"></i></Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div> */}
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="table">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <DataTable
                          columns={columns}
                          data={data}
                          pagination
                          paginationPerPage={50}
                          paginationRowsPerPageOptions={[50, 60, 70, 80]}
                          customStyles={{
                            headCells: {
                              style: {
                                color: 'black',
                                fontWeight: 'bold',
                                fontSize: 15,
                                maxWidth: 80
                              },
                            },
                            cells: {
                              style: {
                                maxWidth: 50
                              }
                            }
                          }}
                        />
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    </React.Fragment>
  )
}

// export default NotificationTable;

const mapStateToProps = state => {
  const { notis } = state.notification;
  return { notis }
}

export default withRouter(connect(mapStateToProps, {})(NotificationTable));
