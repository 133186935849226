import PropTypes from "prop-types"
import React, { useEffect, useState, useRef, useCallback } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom";
import Utils from '../../pages/Utility';
import { get } from "../../helpers/api_helper";
import { MdOutlineTempleHindu } from "react-icons/md";

//i18n
import { withTranslation } from "react-i18next";

// redux
import { connect } from "react-redux";
import { getNotiSuccess } from "../../store/actions";
import { NavLink } from "reactstrap"

const SidebarContent = props => {
  // console.log('props on slidebar content', props);
  const [permission, setPermissions] = useState([]);

  const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;
    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location?.pathname, props?.user?.role]);

  useEffect(() => {
    if (props?.user?.permissions?.length);
    setPermissions(props?.user?.permissions);
  }, [props?.user]);

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    item.style.color = Utils.themeColor;
    item.style.fontWeight = 'bold';
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar ref={ref} className="vertical-simplebar">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>

            {props.user?.role == 'Admin' ?
              <>
                {/* <li>
                  <Link to="/users" className="waves-effect">
                    <i className="mdi mdi-account-multiple" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Users")}</span>
                  </Link>
                </li> */}
                <li>
                  <Link to="/dashboard" className="waves-effect">
                    <i className="mdi mdi-airplay" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={"/#"} className={"has-arrow waves-effect"}>
                    <i className="mdi mdi-account" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Users Management")}</span>
                  </Link>
                  <ul className={"sub-menu"}>
                    <li><Link to="/users">{props.t("All Users")}</Link></li>
                    <li><Link to="/user/unregistered">{props.t("Unregistered Users")}</Link></li>
                    <li><Link to="/user/unregistered-to-registered">{props.t("Unregistered to Registered")}</Link></li>
                    <li><Link to="/user/registered">{props.t("Registered Users")}</Link></li>
                    <li><Link to="/user/registered-to-paid">{props.t("Registered to Paid")}</Link></li>
                    <li><Link to="/user/paid">{props.t("Paid Users")}</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to="/screen" className="waves-effect">
                    <i className="mdi mdi-cellphone-text" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("App Screens")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/popup" className="waves-effect">
                    <i className="mdi mdi-cellphone-screenshot" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Popup")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/banner" className="waves-effect">
                    <i className="mdi mdi-post" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Banner")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/banner-footer" className="waves-effect">
                    <i className="mdi mdi-post" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Banner Footer")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={"/#"} className={"has-arrow waves-effect"}>
                    <i className="mdi mdi-store" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Rashifal")}</span>
                  </Link>
                  <ul className={"sub-menu"}>
                    <li><Link to="/rashifal">{props.t("Rashifal")}</Link></li>
                    <li><Link to="/rashifalname">{props.t("RashifalName")}</Link></li>
                  </ul>
                </li>
                {/* <li>
                  <Link to={"/#"} className={"has-arrow waves-effect"}>
                    <i className="mdi mdi-store" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Shop Store")}</span>
                  </Link>
                  <ul className={"sub-menu"}>
                    <li><Link to="/brand">{props.t("Brand")}</Link></li>
                    <li><Link to="/category">{props.t("Category")}</Link></li>
                    <li><Link to="/product">{props.t("Product")}</Link></li>
                  </ul>
                </li> */}
                <li>
                  <Link to={"/#"} className={"has-arrow waves-effect"}>
                    <i className="mdi mdi-bookmark-music" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Story")}</span>
                  </Link>
                  <ul className={"sub-menu"}>
                    <li><Link to="/story-author">{props.t("Author")}</Link></li>
                    <li><Link to="/story-categories">{props.t("Category")}</Link></li>
                    <li><Link to="/story">{props.t("Stories")}</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to={"/#"} className={"has-arrow waves-effect"}>
                    <i className="mdi mdi-calendar-check-outline" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Calendar")}</span>
                  </Link>
                  <ul className={"sub-menu"}>
                    <li><Link to="/calendar-month">{props.t("Month")}</Link></li>
                    <li><Link to="/calendar-category">{props.t("Category")}</Link></li>
                    <li><Link to="/calendar-event">{props.t("Event")}</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to={"/#"} className={"has-arrow waves-effect"}>
                    <i className="mdi mdi-wallpaper" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Wallpaper")}</span>
                  </Link>
                  <ul className={"sub-menu"}>
                    <li><Link to="/wallpaper-category">{props.t("Category")}</Link></li>
                    <li><Link to="/wallpaper">{props.t("Wallpaper")}</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to={"/#"} className={"has-arrow waves-effect"}>
                    <MdOutlineTempleHindu style={{ color: Utils.themeColor, marginRight: 10 }} size={18} />
                    <span>{props.t("Temple")}</span>
                  </Link>
                  <ul className={"sub-menu"}>
                    <li><Link to="/temple-region">{props.t("Regions")}</Link></li>
                    <li><Link to="/temple">{props.t("Temple")}</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to={"/#"} className={"has-arrow waves-effect"}>
                    <MdOutlineTempleHindu style={{ color: Utils.themeColor, marginRight: 10 }} size={18} />
                    <span>{props.t("Wishes")}</span>
                  </Link>
                  <ul className={"sub-menu"}>
                    <li><Link to="/wishes/category">{props.t("Category")}</Link></li>
                    <li><Link to="/wishes/images">{props.t("Images")}</Link></li>
                    <li><Link to="/wishes">{props.t("Messages")}</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to="/plans" className="waves-effect">
                    <i className="mdi mdi-currency-usd" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Plans")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/coupon" className="waves-effect">
                    <i className="mdi mdi-ticket-percent" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Coupon")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/enquiry" className="waves-effect">
                    <i className="mdi mdi-account-question" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Contacted-us")}</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/tags" className="waves-effect">
                    <i className="mdi mdi-tag" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Tags")}</span>
                  </Link>
                </li> */}
                <li>
                  <Link to="/setting" className="waves-effect">
                    <i className="mdi mdi-cogs" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Settings")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/notification" className="waves-effect">
                    <i className="mdi mdi-bell" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Notifications")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/anayltic" className="waves-effect">
                    <i className="mdi mdi-finance" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Analytics")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={"/#"} className={"has-arrow waves-effect"}>
                    <i className="mdi mdi-post" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Blog")}</span>
                  </Link>
                  <ul className={"sub-menu"}>
                    <li><Link to="/tags">{props.t("Tags")}</Link></li>
                    <li><Link to="/blog/category">{props.t("Category")}</Link></li>
                    <li><Link to="/blog">{props.t("Blog")}</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to="/whatsapp" className="waves-effect">
                    <i className="mdi mdi-whatsapp" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Whatsapp")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/sub-admin" className="waves-effect">
                    <i className="mdi mdi-key" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Sub-Admin")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/social-media" className="waves-effect">
                    <i className="mdi mdi-play-network" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Social-Media")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/traffic" className="waves-effect">
                    <i className="mdi mdi-source-branch" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Traffic Manager")}</span>
                  </Link>
                </li>
              </>
              : null}
            {props.user?.role == 'Sales' ?
              <>
                <li>
                  <Link to="/user-sales" className="waves-effect">
                    <i className="mdi mdi-account-multiple" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Users")}</span>
                  </Link>
                </li>
              </>
              :
              <>
                {permission?.length && permission[0]?.user?.read ?
                  <li>
                    <Link to={"/#"} className={"has-arrow waves-effect"}>
                      <i className="mdi mdi-account" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Users Management")}</span>
                    </Link>
                    <ul className={"sub-menu"}>
                      <li><Link to="/users">{props.t("All Users")}</Link></li>
                      <li><Link to="/user/unregistered">{props.t("Unregistered Users")}</Link></li>
                      <li><Link to="/user/unregistered-to-registered">{props.t("Unregistered to Registered")}</Link></li>
                      <li><Link to="/user/registered">{props.t("Registered Users")}</Link></li>
                      <li><Link to="/user/registered-to-paid">{props.t("Registered to Paid")}</Link></li>
                      <li><Link to="/user/paid">{props.t("Paid Users")}</Link></li>
                    </ul>
                  </li>
                  : null}
                {permission?.length && permission[1]?.popup?.read ?
                  <li>
                    <Link to="/popup" className="waves-effect">
                      <i className="mdi mdi-cellphone-screenshot" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Popup")}</span>
                    </Link>
                  </li>
                  : null}
                {permission?.length && permission[2]?.banner?.read ?
                  <li>
                    <Link to="/banner" className="waves-effect">
                      <i className="mdi mdi-post" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Banner")}</span>
                    </Link>
                  </li>
                  : null}
                {permission?.length && permission[3]?.banner_footer?.read ?
                  <li>
                    <Link to="/banner-footer" className="waves-effect">
                      <i className="mdi mdi-post" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Banner Footer")}</span>
                    </Link>
                  </li>
                  : null}
                {permission?.length && permission[4]?.rashifal?.read ?
                  <li>
                    <Link to={"/#"} className={"has-arrow waves-effect"}>
                      <i className="mdi mdi-store" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Rashifal")}</span>
                    </Link>
                    <ul className={"sub-menu"}>
                      <li><Link to="/rashifal">{props.t("Rashifal")}</Link></li>
                      <li><Link to="/rashifalname">{props.t("RashifalName")}</Link></li>
                    </ul>
                  </li>
                  : null}
                {/* {permission?.length && permission[5]?.shop?.read ?
                  <li>
                    <Link to={"/#"} className={"has-arrow waves-effect"}>
                      <i className="mdi mdi-store" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Shop Store")}</span>
                    </Link>
                    <ul className={"sub-menu"}>
                      <li><Link to="/brand">{props.t("Brand")}</Link></li>
                      <li><Link to="/category">{props.t("Category")}</Link></li>
                      <li><Link to="/product">{props.t("Product")}</Link></li>
                    </ul>
                  </li>
                  : null} */}
                {permission?.length && permission[5]?.story?.read ?
                  <li>
                    <Link to={"/#"} className={"has-arrow waves-effect"}>
                      <i className="mdi mdi-bookmark-music" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Story")}</span>
                    </Link>
                    <ul className={"sub-menu"}>
                      <li><Link to="/story-author">{props.t("Author")}</Link></li>
                      <li><Link to="/story-categories">{props.t("Category")}</Link></li>
                      <li><Link to="/story">{props.t("Stories")}</Link></li>
                    </ul>
                  </li>
                  : null}
                {permission?.length && permission[6]?.calendar?.read ?
                  <li>
                    <Link to={"/#"} className={"has-arrow waves-effect"}>
                      <i className="mdi mdi-calendar-check-outline" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Calendar")}</span>
                    </Link>
                    <ul className={"sub-menu"}>
                      <li><Link to="/calendar-month">{props.t("Month")}</Link></li>
                      <li><Link to="/calendar-category">{props.t("Category")}</Link></li>
                      <li><Link to="/calendar-event">{props.t("Event")}</Link></li>
                    </ul>
                  </li>
                  : null}
                {permission?.length && permission[7]?.wallpaper?.read ?
                  <li>
                    <Link to={"/#"} className={"has-arrow waves-effect"}>
                      <i className="mdi mdi-wallpaper" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Wallpaper")}</span>
                    </Link>
                    <ul className={"sub-menu"}>
                      <li><Link to="/wallpaper-category">{props.t("Category")}</Link></li>
                      <li><Link to="/wallpaper">{props.t("Wallpaper")}</Link></li>
                    </ul>
                  </li>
                  : null}
                {permission?.length && permission[8]?.temple?.read ?
                  <li>
                    <Link to={"/#"} className={"has-arrow waves-effect"}>
                      <MdOutlineTempleHindu style={{ color: Utils.themeColor, marginRight: 10 }} size={18} />
                      <span>{props.t("Temple")}</span>
                    </Link>
                    <ul className={"sub-menu"}>
                      <li><Link to="/temple-region">{props.t("Regions")}</Link></li>
                      <li><Link to="/temple">{props.t("Temple")}</Link></li>
                    </ul>
                  </li>
                  : null}
                {permission?.length && permission[9]?.wishes?.read ?
                  <li>
                    <Link to={"/#"} className={"has-arrow waves-effect"}>
                      <MdOutlineTempleHindu style={{ color: Utils.themeColor, marginRight: 10 }} size={18} />
                      <span>{props.t("Wishes")}</span>
                    </Link>
                    <ul className={"sub-menu"}>
                      <li><Link to="/wishes/category">{props.t("Category")}</Link></li>
                      <li><Link to="/wishes/images">{props.t("Images")}</Link></li>
                      <li><Link to="/wishes">{props.t("Messages")}</Link></li>
                    </ul>
                  </li>
                  : null}
                {permission?.length && permission[10]?.contact_us?.read ?
                  <li>
                    <Link to="/enquiry" className="waves-effect">
                      <i className="mdi mdi-account-question" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Contacted-us")}</span>
                    </Link>
                  </li>
                  : null}
                {permission?.length && permission[11]?.tags?.read ?
                  <li>
                    <Link to="/tags" className="waves-effect">
                      <i className="mdi mdi-tag" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Tags")}</span>
                    </Link>
                  </li>
                  : null}
                {permission?.length && permission[13]?.settings?.read ?
                  <li>
                    <Link to="/setting" className="waves-effect">
                      <i className="mdi mdi-cogs" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Settings")}</span>
                    </Link>
                  </li>
                  : null}
                {permission?.length && permission[14]?.notification?.read ?
                  <li>
                    <Link to="/notification" className="waves-effect">
                      <i className="mdi mdi-bell" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Notifications")}</span>
                    </Link>
                  </li>
                  : null}
                {permission?.length && permission[15]?.image_store?.read ?
                  <li>
                    <Link to="/store" className="waves-effect">
                      <i className="mdi mdi-image-multiple" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Image Store")}</span>
                    </Link>
                  </li>
                  : null}
                {permission?.length && permission[16]?.blog?.read ?
                  <li>
                    <Link to={"/#"} className={"has-arrow waves-effect"}>
                      <i className="mdi mdi-post" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Blog")}</span>
                    </Link>
                    <ul className={"sub-menu"}>
                      <li><Link to="/blog/category">{props.t("Category")}</Link></li>
                      <li><Link to="/blog">{props.t("Blog")}</Link></li>
                    </ul>
                  </li>
                  : null}
              </>
            }
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment >
  )
}

const mapStateToProps = state => {
  const { notis } = state.notification;
  return { notis }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  getNotiSuccess: PropTypes.func,
}

export default withRouter(withTranslation()(connect(mapStateToProps, { getNotiSuccess })(SidebarContent)))