import React, { useEffect, useState, useRef } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody, Label } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';

import Utils from '../Utility';
import { post, get, put, upload } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { AvForm, AvField } from "availity-reactstrap-validation";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import './style.css';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import moment from "moment";

const Table = (props) => {
  const { user, role, currentData, setCurrentData, path, loading, setLoading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [file, setFile] = useState(null);
  const [img, setImg] = useState(null);
  const cropper = useRef(null);
  const [isAddForm, setIsAddForm] = useState(false);
  const [error, setError] = useState(false);
  const [selectedLang, setSelectedLang] = useState([]);
  const [type, setType] = useState("");


  useEffect(() => {
    // console.log('props on user table', props)
    if (user?.token) {
      getData();
    }
  }, [user, path]);

  useEffect(() => {
    if (!isAddForm) {
      setCurrentData(null);
      setType("");
    }
  }, [isAddForm]);

  const getData = () => {
    if (!loading) {
      setLoading(true);
      let url = "/tags/list";
      get(url, { token: user?.token })
        .then(json => {
          console.log('response from get banner list', json);
          setLoading(false);
          if (json?.statusCode == 200) {
            setData(json?.data);
          } else {
            toast.error(json?.error);
            setError(true);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while getting project list', error);
        });
    }
  }

  useEffect(() => {
    setColumns([
      {
        name: 'Title',
        selector: row => row?.title,
      },
      {
        name: 'Created At',
        selector: row => row?.createdAt ? moment(row?.createdAt).format("DD MMM YYYY, hh:mm a") : "-",
        cell: (row) => <>
          <span>{row?.createdAt ? moment(row?.createdAt).format("DD MMM YYYY, hh:mm A") : "-"}</span>
        </>,
        sortable: true,
        maxWidth: '180px'
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row, "Status")}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
        maxWidth: '110px'
      },
      {
        cell: (row) => <>
          {user?.role == "Admin" ?
            <>
              <Button onClick={() => handleUpdateProj(row)}
                title={"Edit"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
              <Button onClick={() => handleDeleteProj(row)}
                title={"Delete"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
            </>
            :
            <>
              {user?.permissions?.length && user?.permissions[11]?.tags?.update &&
                <Button onClick={() => handleUpdateProj(row)}
                  title={"Edit"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
              }
              {user?.permissions?.length && user?.permissions[11]?.tags?.delete &&
                <Button onClick={() => handleDeleteProj(row)}
                  title={"Delete"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
              }
            </>}
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: '100px'
      },
    ])
  }, [data]);

  const handleUpdateProj = (proj) => {
    // console.log('updating proj', proj);
    setCurrentData(proj);
    setIsAddForm(true);
  }

  const handleUpdateStatus = (item, type) => {
    console.log('updating proj', item);
    setCurrentData({ ...item, actionType: type });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData({ ...proj, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      tagId: currentData?._id,
      token: user?.token
    }
    let url = "/tags";
    if (currentData?.actionType == 'Status') {
      body = { ...body, isActive: !currentData?.isActive };
    } else if (currentData?.actionType == "Recomm") {
      body = { ...body, recomm: !currentData?.recomm };
    } else {
      body = { ...body, isDeleted: true };
    }
    put(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const handleValidSubmit = async (e, v) => {
    let body = {
      ...v,
      token: user?.token,
    }
    if (currentData?._id) {
      body = { ...body, tagId: currentData?._id }
      put("/tags", body)
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            getData();
            toast.success(res?.message);
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while updating banner', error);
        })
    } else {
      post("/tags", body)
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            toast.success(res?.messgae);
            getData();
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          toast.error('Something Went Wrong!');
          console.log('error while adding banner', error);
        })
    }
  }

  const formatDateTime = (str) => {
    console.log("formatting date", str);
    return moment(str).format('YYYY-MM-DD');
    // return "2023-11-29T07:50";
  }

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            data={currentData}
          />
          <Modal isOpen={isAddForm} centered={true} style={{ maxWidth: 800 }}>
            <ModalBody>
              <h5 className="text-center mt-3 mb-3"><b>{currentData?._id ? "Update" : "Add new"} Tag</b></h5>
              <div className="me-3 position-relative px-4">
                <AvForm
                  className="form-horizontal mt-4"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                  }}
                >
                  <Row>
                    <Col md={12}>
                      <div className="form-group mb-4">
                        <AvField
                          name="title"
                          label={"Title"}
                          value={currentData?.title}
                          className="form-control"
                          placeholder="Enter tag title"
                          type="text"
                          required
                        />
                      </div>
                    </Col>
                    <div className="text-center mt-4">
                      <Button type="reset" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none', minWidth: '40%' }}
                        onClick={() => { setCurrentData(null); setIsAddForm(false) }}
                      >
                        Cancel
                      </Button>
                      {currentData ?
                        <Button type="submit" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none', minWidth: '40%', marginLeft: 30 }}>
                          Update
                        </Button>
                        :
                        <Button type="submit" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none', minWidth: '40%', marginLeft: 30 }}>
                          Add
                        </Button>
                      }
                    </div>
                  </Row>
                </AvForm>
              </div>
            </ModalBody>
          </Modal>
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <div>
                    <CardTitle className="h4">{props.role}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {role} you have added before, All the <code>{role} </code>can be used while adding any content like<code> Blog, Story.</code>
                    </CardSubtitle>
                  </div>
                  {user?.role == "Admin" ?
                    <div className="text-center" style={{ marginLeft: 'auto' }}>
                      <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                        Add
                      </Button>
                    </div>
                    :
                    <>
                      {user?.permissions?.length && user?.permissions[11]?.tags?.write ?
                        <div className="text-center" style={{ marginLeft: 'auto' }}>
                          <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                            Add
                          </Button>
                        </div>
                        : null}
                    </>
                  }
                </div>
                <Row>
                  <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    customStyles={{
                      headCells: {
                        style: {
                          color: 'black',
                          fontWeight: 'bold',
                          fontSize: 15,
                          maxWidth: 80
                        },
                      },
                      cells: {
                        style: {
                          maxWidth: 50
                        }
                      }
                    }}
                    conditionalRowStyles={[{
                      when: row => row?.seen?.length < 1,
                      style: { backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, color: 'white', cursor: 'pointer' },
                    }]}
                  />
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment >
  )
}
export default Table;
