import Utils from "../pages/Utility";
const API_URL = Utils.API_URL;
import AWS from "aws-sdk";

export async function get(url, data) {
  return fetch(API_URL + url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      "Authorization": "Bareer " + data?.token
    },
  }).then(res => res.json())

}

export async function download(url, data) {
  return fetch(API_URL + url, {
    method: 'POST',
    headers: {
      Accept: 'text/csv',
      'Content-Type': 'application/json',
      "Authorization": "Bareer " + data?.token
    },
    body: JSON.stringify(data)
  }).then(res => res.blob())
}

export async function post(url, data) {
  // console.log('post method req', url, data)
  return fetch(API_URL + url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      "Authorization": "Bareer " + data?.token
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export async function put(url, data) {
  console.log('put method req', url, data)
  return fetch(API_URL + url, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      "Authorization": "Bareer " + data?.token
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export async function upload(url, data, token) {
  console.log('upload method req', url, data)
  return fetch(API_URL + url, {
    method: 'POST',
    body: data
  }).then(res => res.json())
}

export async function del(url, data) {
  return fetch(API_URL + url, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      "Authorization": "Bareer " + data?.token
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}

export async function uploadS3(folder, data, type, onProgress) {
  // console.log("process.env ", process.env.REACT_APP_AWS_ACCESS_KEY_ID);
  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });
  const params = {
    Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
    Body: data,
    Key: process.env.REACT_APP_AWS_S3_FOLDER_NAME + folder + "/" + Date.now() + "." + type,
    ACL: 'public-read'
  };
  console.log("bucket params", params);
  const options = { partSize: 10 * 1024 * 1024, queueSize: 1 };
  const managedUpload = s3.upload(params, options);
  managedUpload.on('httpUploadProgress', (progressData) => {
    const percentUploaded = Math.round(
      (progressData.loaded / progressData.total) * 100
    );
    onProgress(percentUploaded);
  });
  const resp = await managedUpload.promise();
  console.log('response from s3', resp);
  if (resp)
    return {
      statusCode: 200,
      data: resp.Location,
      message: "SUCCESS"
    }
  else throw "error with img";
}