import PropTypes from 'prop-types'
import React from "react"
import Utils from '../../pages/Utility';
import { Col, Modal, ModalBody, Row } from "reactstrap"

const DeleteModal = ({ show, onConfirm, onCloseClick, data }) => {
  const { actionType } = data || { actionType: null };
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              {actionType == 'Status' ?
                <i className="mdi mdi-alert-circle-outline" style={{ fontSize: "9em", color: Utils.themeColor }} />
                : null}
              {actionType == 'Delete' ?
                <i className="mdi mdi-alert-circle-outline" style={{ fontSize: "9em", color: Utils.themeColor }} />
                : null}
              {actionType == 'SMS' ?
                <i className="mdi mdi-message-alert" style={{ fontSize: "9em", color: Utils.themeColor }} />
                : null}
              {actionType == 'Comm' ?
                <i className="mdi mdi-account-multiple-plus" style={{ fontSize: "9em", color: Utils.themeColor }} />
                : null}
              <h2>Are you sure?</h2>
              {actionType == 'Status' ?
                <h4>You want to change the status to {data?.isActive ? 'Inactive' : 'Active'}!</h4>
                : null}
              {actionType == 'Delete' ?
                <h4>{"You won't be able to revert this!"}</h4>
                : null}
              {actionType == 'Comm' ?
                <h4>{`You have ${data?.isAddedToComm ? "Removed" : "Added"} this user to community!`}</h4>
                : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-success btn-lg me-2"
                onClick={onConfirm}
              >
                {actionType == 'Status' ? data?.isActive ? 'Inactive it!' : 'Active it!' : ''}
                {actionType == "Delete" ? "Yes! Delete it" : ""}
                {actionType == "SMS" ? "Yes! Send it" : ""}
                {actionType == "Comm" ? data?.isAddedToComm ? "Yes! removed" : "Yes! Added" : ""}
              </button>
              <button
                type="button"
                className="btn btn-danger btn-lg me-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

DeleteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any
}

export default DeleteModal
