import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
} from "reactstrap"

// availity-reactstrap-validation

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../../components/Common/Breadcrumb";
import Utils from '../../Utility'

// actions
import AddCard from '../../Forms/AddTemple';
import Table from './Table';
import Loader from "../../../components/Loader";
import CompSingle from './compSingle';


const Property = props => {
  const { user } = props;
  const [isAddForm, setIsAddForm] = useState(false);
  const [isTable, setIsTable] = useState(true);
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [singleId, setSingleId] = useState(null);
  // console.log('props on company', props);

  useEffect(() => {
    if (props.location) {
      // console.log('path on opportunities', props.location.pathname.split("/"));
      const path = props.location.pathname;
      if (path.split("/").length > 1) {
        const id = path.split("/")[2];
        if (id) {
          setSingleId(id);
          setIsAddForm(false);
          setIsTable(false);
        }
      } else {
        setIsTable(true);
      }
    }
  }, [props]);

  useEffect(() => {
    if (isAddForm)
      setIsTable(false);
    else {
      setCurrentData(null);
      setIsTable(true);
    }
  }, [isAddForm]);

  return (
    <React.Fragment>
      {/* <MetaTags>
        <meta name="description" content="Helmet application" />
      </MetaTags> */}
      <div className="page-content">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Temples" />
        {isAddForm &&
          <AddCard
            role={"Temple"}
            user={user}
            data={currentData}
            close={setIsAddForm}
            loading={loading}
            setLoading={setLoading}
            setCurrentData={setCurrentData}
            getProfile={props?.getProfile}
          />
        }
        {isTable &&
          <Card>
            <CardBody>
              <Table
                role={"Temple"}
                user={props?.user}
                add={setIsAddForm}
                currentData={currentData}
                setCurrentData={setCurrentData}
                history={props.history}
                setLoading={setLoading}
              />
            </CardBody>
          </Card>
        }
        {!isAddForm && !isTable &&
          <CompSingle
            singleId={singleId}
            user={user}
            setLoading={setLoading}
            setIsAddForm={setIsAddForm}
            history={props.history}
            setCurrentData={setCurrentData}
          />
        }

      </div>
    </React.Fragment>
  )
}

Property.propTypes = {
  getProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  companyError: PropTypes.func,
  companySuccess: PropTypes.func,
  createCompany: PropTypes.func,
  updateCompany: PropTypes.func
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  const { company } = state.Company;
  return { user, company }
}

export default withRouter(
  connect(mapStatetoProps, {})(Property)
)
