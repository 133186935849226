import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button,
    Modal, ModalBody, Label
} from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import Utils from '../Utility'

import avatar from "../../assets/images/small/sample.png"
// actions
import { post, upload, put, get } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import Multiselect from 'multiselect-react-dropdown';


const AddMantra = props => {
    const { user, data, setLoading, role } = props;
    const [file, setFile] = useState(null);
    const [listImage, setListImage] = useState(null);
    const [staticImage, setStaticImage] = useState(null);
    const [animImage, setAnimImage] = useState(null);
    const listImageInput = useRef();
    const staticImageInput = useRef();
    const animImageInput = useRef();
    const cropper = useRef();
    const [cropperModal, setCropperModal] = useState(false);
    const [audioFile, setAudioFile] = useState(undefined);
    const [introAudioFile, setIntroAudioFile] = useState(undefined);
    const [currentFileType, setCurrentFileType] = useState(undefined);
    const [selectedCounts, setSelectedCounts] = useState([]);
    console.log('selected counts', selectedCounts);

    useEffect(() => {
        if (data) {
            if (data?.listImage)
                setListImage(data?.listImage);
            if (data?.staticImage)
                setStaticImage(data?.staticImage);
            if (data?.animImage)
                setAnimImage(data?.animImage);
            if (data?.counts?.length > 0) {
                let temp = [];
                data?.counts?.map((item) => {
                    temp.push({ value: item?.value, name: item?.value });
                });
                setSelectedCounts(temp);
            }
        }
    }, [data]);

    const onChangeFile = async (e, type) => {
        // console.log('on change file', e.target);
        if (type == 'animImage') {
            setAnimImage(URL.createObjectURL(e.target.files[0]))
            return;
        }
        setFile(URL.createObjectURL(e.target.files[0]));
        setCurrentFileType(type);
        setCropperModal(true);
    }

    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1800,
                600,
                "webp",
                60,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    const cropImage = (e, v) => {
        setLoading(true);
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        if (currentFileType == 'listImage')
            setListImage(fileData);
        if (currentFileType == 'staticImage')
            setStaticImage(fileData);
        if (currentFileType == 'animImage')
            setAnimImage(fileData);
        setCropperModal(false);
        setLoading(false);
    }

    const getSize = () => {
        if (currentFileType == 'listImage')
            return 300 / 500;
        if (currentFileType == 'staticImage')
            return 600 / 1067;
        if (currentFileType == 'animImage')
            return 600 / 1067;
        else
            return undefined;
    }

    const handleValidSubmit = async (e, v) => {
        // console.log('image', image, typeof image);
        // console.log('image', animImage);
        // return;
        try {
            let tempListImage = data?.listImage;
            let tempStaticImage = data?.staticImage;
            let tempAnimImage = data?.animImage;
            let audioUrl = data?.audio;
            let introUrl = data?.intro;
            if (!listImage) {
                toast.error("Select List Image for this Mantra!");
                return;
            }
            if (!staticImage) {
                toast.error("Select Static Image for this Mantra!");
                return;
            }
            if (!animImage) {
                toast.error("Select Animated Image for this Mantra!");
                return;
            }
            if (!data?.audio && !audioFile) {
                toast.error("Select audio for this story!");
                return;
            }
            setLoading(true);
            if (listImage?.length > 90) {
                const obj = await urltoFile(listImage, new Date().getTime() + '.png', 'image/png');
                const tempObj = await resizeFile(obj);
                const form = new FormData();
                form.append("image", tempObj);
                const uploadRes = await upload("/mantra/image_upload", form);
                if (uploadRes?.statusCode == 200)
                    tempListImage = uploadRes?.data;
            }
            if (staticImage?.length > 90) {
                const obj = await urltoFile(staticImage, new Date().getTime() + '.png', 'image/png');
                // const tempObj = await resizeFile(obj);
                const form = new FormData();
                form.append("image", obj);
                const uploadRes = await upload("/mantra/image_upload", form);
                if (uploadRes?.statusCode == 200)
                    tempStaticImage = uploadRes?.data;
            }
            if (animImage?.slice(0, 4) == 'blob') {
                const obj = await urltoFile(animImage, new Date().getTime() + '.gif', 'image/gif');
                const form = new FormData();
                form.append("image", obj);
                const uploadRes = await upload("/mantra/image_upload", form);
                if (uploadRes?.statusCode == 200)
                    tempAnimImage = uploadRes?.data;
            }
            if (audioFile) {
                console.log("audio file", audioFile);
                const audioForm = new FormData();
                audioForm.append("audio", audioFile);
                const uploadRes = await upload("/mantra/audio_upload", audioForm);
                if (uploadRes?.statusCode == 200)
                    audioUrl = uploadRes?.data;
            }
            if (introAudioFile) {
                console.log("audio file", introAudioFile);
                const audioForm = new FormData();
                audioForm.append("audio", introAudioFile);
                const uploadRes = await upload("/mantra/audio_upload", audioForm);
                if (uploadRes?.statusCode == 200)
                    introUrl = uploadRes?.data;
            }
            setLoading(true);
            let body = {
                ...v,
                listImage: tempListImage,
                staticImage: tempStaticImage,
                animImage: tempAnimImage,
                audio: audioUrl,
                intro: introUrl,
                counts: selectedCounts,
                token: user?.token
            }
            console.log('body on mantra', body);
            if (data) {
                body = { ...body, mantraId: data?._id }
                put("/mantra/update", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            toast.success(res?.message);
                            setListImage(null);
                            setStaticImage(null);
                            setAnimImage(null);
                            setFile(null);
                            setAudioFile(undefined);
                            props?.close(false);
                        } else {
                            toast.error(res?.error);
                        }
                    })
                    .catch(err => {
                        setLoading(false);
                        console.log('err while adding story', err);
                        toast.error('Something Went Wrong!')
                    })
            } else {
                post("/mantra/add", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            toast.success(res?.message);
                            setListImage(null);
                            setStaticImage(null);
                            setAnimImage(null);
                            setFile(null);
                            setAudioFile(undefined);
                            props?.close(false);
                        } else {
                            toast.success(res?.error);
                        }
                    })
                    .catch(err => {
                        setLoading(false);
                        console.log('err while adding story', err);
                        toast.error('Something Went Wrong!')
                    })
            }
        } catch (err) {
            setLoading(false);
            console.log('error while submitting mantra form', err);
        }
    }

    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg={11} md={11}>
                                        <Row>
                                            <Col lg={3}>
                                                <Label>List Image</Label>
                                                {listImage ?
                                                    <Row>
                                                        <Col lg={12} md={2} style={{ marginRight: '2rem' }}>
                                                            <div className="me-3 position-relative" style={{ width: 150, height: 180 }}>
                                                                <img
                                                                    src={listImage ? listImage : avatar}
                                                                    alt=""
                                                                    style={{ width: '71%', height: '100%' }}
                                                                />
                                                                <div style={{ position: 'absolute', height: 40, width: 40, right: 35, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                                    onClick={(e) => { setListImage(null) }}
                                                                >
                                                                    <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    :
                                                    <Row>
                                                        <Col lg={12} md={2}>
                                                            <div className="me-3 position-relative" style={{ width: 180, height: 180 }}>
                                                                <div className='image_placeholder' style={{ width: '71%', height: '100%' }}
                                                                    onClick={(e) => listImageInput.current.click(e)}
                                                                >
                                                                    <button style={{ backgroundColor: Utils.themeColor, borderRadius: 5, color: '#fff', padding: '5px 10px', border: 'none' }}> + Add</button>
                                                                    <div style={{ position: 'absolute', height: 40, width: 40, right: 35, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                                    >
                                                                        <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                                                                    </div>
                                                                    <input type="file" id="listImage"
                                                                        ref={listImageInput}
                                                                        onChange={(e) => onChangeFile(e, 'listImage')}
                                                                        style={{ display: "none" }} />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                }
                                            </Col>
                                            <Col lg={3}>
                                                <Label>Static Image</Label>
                                                {staticImage ?
                                                    <Row>
                                                        <Col lg={12} md={2} style={{ marginRight: '2rem' }}>
                                                            <div className="me-3 position-relative" style={{ width: 150, height: 180 }}>
                                                                <img
                                                                    src={staticImage ? staticImage : avatar}
                                                                    alt=""
                                                                    style={{ width: '71%', height: '100%' }}
                                                                />
                                                                <div style={{ position: 'absolute', height: 40, width: 40, right: 35, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                                    onClick={(e) => { setStaticImage(null) }}
                                                                >
                                                                    <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    :
                                                    <Row>
                                                        <Col lg={12} md={2}>
                                                            <div className="me-3 position-relative" style={{ width: 180, height: 180 }}>
                                                                <div className='image_placeholder' style={{ width: '71%', height: '100%' }}
                                                                    onClick={(e) => staticImageInput.current.click(e)}
                                                                >
                                                                    <button style={{ backgroundColor: Utils.themeColor, borderRadius: 5, color: '#fff', padding: '5px 10px', border: 'none' }}> + Add</button>
                                                                    <div style={{ position: 'absolute', height: 40, width: 40, right: 35, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                                    >
                                                                        <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                                                                    </div>
                                                                    <input type="file" id="staticImage"
                                                                        ref={staticImageInput}
                                                                        onChange={(e) => onChangeFile(e, 'staticImage')}
                                                                        style={{ display: "none" }} />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                }
                                            </Col>
                                            <Col lg={3}>
                                                <Label>Animated Image</Label>
                                                {animImage ?
                                                    <Row>
                                                        <Col lg={12} md={2} style={{ marginRight: '2rem' }}>
                                                            <div className="me-3 position-relative" style={{ width: 150, height: 180 }}>
                                                                <img
                                                                    src={animImage ? animImage : avatar}
                                                                    alt=""
                                                                    style={{ width: '71%', height: '100%' }}
                                                                />
                                                                <div style={{ position: 'absolute', height: 40, width: 40, right: 35, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                                    onClick={(e) => { setAnimImage(null) }}
                                                                >
                                                                    <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    :
                                                    <Row>
                                                        <Col lg={12} md={2}>
                                                            <div className="me-3 position-relative" style={{ width: 180, height: 180 }}>
                                                                <div className='image_placeholder' style={{ width: '71%', height: '100%' }}
                                                                    onClick={(e) => animImageInput.current.click(e)}
                                                                >
                                                                    <button style={{ backgroundColor: Utils.themeColor, borderRadius: 5, color: '#fff', padding: '5px 10px', border: 'none' }}> + Add</button>
                                                                    <div style={{ position: 'absolute', height: 40, width: 40, right: 35, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                                    >
                                                                        <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                                                                    </div>
                                                                    <input type="file" id="animImage"
                                                                        ref={animImageInput}
                                                                        accept='image/gif'
                                                                        onChange={(e) => onChangeFile(e, 'animImage')}
                                                                        style={{ display: "none" }} />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={1} md={1}>
                                        <div className="text-center" style={{ marginLeft: 'auto' }}>
                                            <Button color="primary" onClick={() => { props.setCurrentData(null); props?.close(false) }} style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                                                Close
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* Image cropper modal */}
                <Modal isOpen={cropperModal} toggle={() => { setCropperModal(false); setFile(null) }} centered={true}>
                    <ModalBody className="py-3 px-5">
                        <h5 className="text-black font-size-20">Crop Image</h5>
                        <div className="p-2">
                            <AvForm
                                className="form-horizontal"
                                onValidSubmit={(e, v) => {
                                    cropImage()
                                }}
                            >
                                <div className="mb-3 mt-2">
                                    <Cropper
                                        style={{ height: 'auto', width: 400 }}
                                        aspectRatio={getSize()}
                                        preview=".img-preview"
                                        guides={true}
                                        src={file}
                                        ref={cropper}
                                    />
                                </div>
                                <div className="mt-4">
                                    <Row>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                style={{ border: 'none', backgroundColor: Utils.themeColor }}
                                                onClick={() => { setCropperModal(false); setFile(null) }}
                                                type="reset"
                                            >
                                                Cancel
                                            </button>
                                        </Col>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                type="submit"
                                                style={{ border: 'none', backgroundColor: Utils.themeColor }}
                                            >
                                                Submit
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </AvForm>
                        </div>
                    </ModalBody>
                </Modal>

                <h4 className="card-title mb-4">{data ? 'Update ' + role : 'Add a ' + role}</h4>

                <Card>
                    <CardBody>
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                handleValidSubmit(e, v)
                            }}
                        >
                            <Row>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="titleEn"
                                            label="Title English *"
                                            value={data?.titleEn}
                                            className="form-control"
                                            placeholder="Enter Title in English"
                                            type="text"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="titleHi"
                                            label="Title Hindi *"
                                            value={data?.titleHi}
                                            className="form-control"
                                            placeholder="Enter Title in Hindi"
                                            type="text"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="color"
                                            label="Color Code *"
                                            value={data?.color}
                                            className="form-control"
                                            placeholder="Enter banner related color code (i.e) 00ff00"
                                            type="text"
                                            // validate={{length:{value:6}}}
                                            minLength={6}
                                            maxLength={6}
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="audio"
                                            label="Audio"
                                            // value={data?.audio}
                                            className="form-control"
                                            placeholder="Enter banner related color code (i.e) 00ff00"
                                            type="file"
                                            accept="audio/mp3"
                                            multiple={false}
                                            onChange={(e) => setAudioFile(e.target.files[0])}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <Label>Counts</Label>
                                    <div className="form-group mb-4">
                                        <Multiselect
                                            options={[
                                                { value: 1, name: "1" },
                                                { value: 5, name: "5" },
                                                { value: 11, name: "11" },
                                                { value: 21, name: "21" },
                                                { value: 51, name: "51" },
                                                { value: 101, name: "101" },
                                                { value: 108, name: "108" },
                                                { value: 121, name: "121" },
                                                { value: 151, name: "151" }
                                            ]} // Options to display in the dropdown
                                            selectionLimit={4}
                                            selectedValues={selectedCounts} // Preselected value to persist in dropdown
                                            onSelect={setSelectedCounts} // Function will trigger on select event
                                            // onRemove={this.onRemove} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="intro"
                                            label="Intro Audio"
                                            // value={data?.audio}
                                            className="form-control"
                                            placeholder="Enter banner related color code (i.e) 00ff00"
                                            type="file"
                                            accept="audio/mp3"
                                            multiple={false}
                                            onChange={(e) => setIntroAudioFile(e.target.files[0])}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            {/* <AvField name="idx" value={user?.company._id} type="hidden" /> */}
                            <div className="text-center mt-4">
                                {data ?
                                    <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                        Update {role}
                                    </Button>
                                    :
                                    <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                        Add {role}
                                    </Button>
                                }
                            </div>
                        </AvForm>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}

const mapStatetoProps = state => {
    const { user } = state.Profile;
    return { user }
}

export default withRouter(
    connect(mapStatetoProps, {})(AddMantra)
)
