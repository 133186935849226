import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Modal,
  ModalBody,
  CardTitle
} from "reactstrap";
import './style.scss';

// availity-reactstrap-validation

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility'

import Loader from "../../components/Loader";
import { get, put, upload } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import DeleteModal from './DeleteModal';
import UserProfile from '../../components/UserProfile';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";

const Setting = props => {
  const { user } = props;
  const [loading, setLoading] = useState(false);
  const [raamWinner, setRaamWinner] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [profileModal, setProfileModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [isAddForm, setIsAddForm] = useState(false);
  const [file, setFile] = useState(null);
  const [img, setImg] = useState(null);
  const imageInput = useRef();
  const cropper = useRef(null);
  const [formType, setFormType] = useState("");
  const [termsModal, setTermsModal] = useState(false);
  const [winnerTerms, setWinnerTerms] = useState("");


  useEffect(() => {
    if (user)
      getSettings();
  }, [user]);

  const getSettings = () => {
    if (!loading) {
      setLoading(true);
      get('/setting', { token: user?.token })
        .then(res => {
          if (res?.statusCode == 200) {
            setRaamWinner(res?.data?.raamWinner);
            setWinnerTerms(res?.data?.winnerTerms);
            get("/ram/winner", { token: user?.token })
              .then(res => {
                setLoading(false);
                setData(res?.data);
              })
          } else {
            setLoading(false);
            toast.error(res?.error);
            setError(true);
          }
        })
        .catch(err => {
          setLoading(false);
          console.log('error while getting setting', err);
          toast.error("" + err);
        });
    }
  }

  const handleChangeToggle = (val, to) => {
    console.log('changing toggle', val, typeof val)
    const body = {
      [to]: !val,
      token: user?.token
    }
    put("/setting/update", body)
      .then(res => {
        if (res?.statusCode == 200) {
          toast.success(res?.message);
          setRaamWinner(res?.data?.raamWinner);
        }
        else
          toast.error(res?.error);
      })
      .catch(err => {
        console.log('error whiling setting setting', err);
        toast.error(err + "");
      })
  }

  useEffect(() => {
    setColumns([
      {
        name: 'User',
        selector: row => row.user[0]?.name,
        maxWidth:'195px'
      },
      {
        name: 'City',
        cell: (row) => <>
          <span>{row.user[0]?.city} {row.user[0]?.state ? `(${row.user[0]?.state})` : ''}</span>
        </>,
        selector: row => row.user[0]?.city,
        maxWidth:'120px'
      },
      {
        name: 'Email',
        selector: row => row.user[0]?.email,
      },
      {
        name: 'Phone',
        selector: row => row.user[0]?.mobile,
        maxWidth:'110px'
      },
      {
        name: 'Dated',
        cell: (row) => <>
          <span>{moment(new Date(row?.createdAt).setDate(new Date(row?.createdAt).getDate() - 7)).format("DD-MM-YYYY")} to {moment(row?.createdAt).format("DD-MM-YYYY")}</span>
        </>,
        selector: row => moment(row?.createdAt).format("DD-MM-YYYY"),
        maxWidth:'120px'
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
        maxWidth:'110px'
      },
      user?.role == 'Admin' &&
      {
        cell: (row) => <>
          <Button
            title={"View"}
            onClick={() => { setCurrentData(row); setProfileModal(true) }}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-eye" style={{ fontSize: 20 }}></span></Button>
          <Button
            title={"Edit"}
            onClick={() => handleUpdateData(row)}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-bell" style={{ fontSize: 20 }}></span></Button>
          <Button onClick={() => handleDeleteProj(row)}
            title={"Delete"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth:'150px'
      },
    ])
  }, [data]);

  const declearWinner = () => {
    get("/ram/declear_winner", { token: user?.token })
      .then(res => {
        if (res.statusCode == 200) {
          toast.success(res?.message);
          getSettings();
        } else {
          toast.error(res?.message);
        }
      })
  }

  const onStatus = () => {
    console.log('deleted', currentData);
    let body = {
      winnerId: currentData?._id,
      token: user?.token
    }
    if (currentData.actionType == 'Status')
      body.isActive = !currentData?.isActive
    else
      body.isDeleted = true;
    let url = "/ram/winner";
    put(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getSettings();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const handleUpdateStatus = (row) => {
    setCurrentData({ ...row, actionType: "Status" });
    setDeleteModal(true);
  }

  const handleDeleteProj = (row) => {
    setCurrentData(row);
    setDeleteModal(true);
  }

  const handleUpdateData = (row) => {
    let temp = row;
    if (!row?.notiTitle)
      temp.notiTitle = "बधाई हो, आप हो इस सप्ताह के राम नाम मेधावी भक्त |";
    if (!row?.notiBody)
      temp.notiBody = "उपहार विवरण के लिए अभी देखें |";
    setCurrentData(temp);
    setFile(row?.gifImage);
    setImg(row?.gifImage);
    setIsAddForm(true);
  }

  const onChangeFile = async (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        512,
        256,
        "webp",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handleValidSubmit = async (e, v, type) => {
    console.log('submitting winning details', v, file);
    if (formType) {
      let body = {
        ...v,
        formType,
        winnerId: currentData?._id,
        token: user?.token
      }
      if (!file) {
        toast.error("Select Gift Image");
        return;
      }
      if (file.substring(0, 4) == "blob") {
        setLoading(true);
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        const obj = await urltoFile(fileData, new Date().getTime() + '.png', 'image/png');
        const temp = await resizeFile(obj);
        const form = new FormData();
        form.append("image", temp);
        const imageRes = await upload("/ram/image_upload", form);
        body.gifImage = imageRes?.data;
      }
      let url = "/ram/winner";
      put(url, body)
        .then(json => {
          console.log('response from on click action', json);
          setLoading(false);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getSettings();
            setIsAddForm(false);
            setCurrentData(null);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.error('error while deleting user', error);
        })
    }
  }

  const checkDeclear = () => {
    if (data?.length > 0) {
      if (!data[0]?.isActive)
        return true;
      else {
        let date = new Date().getTime();
        let lastDate = new Date(data[0].createdAt).getTime() + 518400000;
        console.log('dates to match', date, lastDate);
        if (date > lastDate)
          return true;
        else
          return false;
      }
    } else {
      return true;
    }
  }

  const handleValidTermsSubmit = async (e, v) => {
    console.log('submitting terms', v);
    const body = {
      winnerTerms: v?.winnerTerms,
      token: user?.token
    }
    put("/setting/update", body)
      .then(res => {
        if (res?.statusCode == 200) {
          toast.success(res?.message);
          setRaamWinner(res?.data?.raamWinner);
        }
        else
          toast.error(res?.error);
      })
      .catch(err => {
        console.log('error whiling setting setting', err);
        toast.error(err + "");
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Raam Naam Winners" />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => onStatus()}
          onCloseClick={() => setDeleteModal(false)}
          data={currentData}
        />
        {profileModal &&
          <UserProfile
            show={profileModal}
            onCloseClick={() => setProfileModal(false)}
            userId={currentData?.userId}
          />
        }
        {!error &&
          <Row>
            <Col md={4}>
              <Card>
                <CardBody>
                  <div className='d-flex align-items-center justify-content-between'>
                    <i className='mdi mdi-store' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 20 }} />
                    <span style={{ fontWeight: 'bolder' }}>Raam Naam Winner</span>
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        className="toggle-switch-checkbox"
                        name="toggleSwitch"
                        id="raamWinner"
                        onChange={(e) => handleChangeToggle(raamWinner, 'raamWinner')}
                        checked={raamWinner}
                      />
                      <label className="toggle-switch-label" htmlFor="raamWinner">
                        <span className="toggle-switch-inner" />
                        <span className="toggle-switch-switch" />
                      </label>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={7} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Button style={{ backgroundColor: Utils.themeColor, border: 'none', height: 40, marginRight: 20 }}
                onClick={() => setTermsModal(true)}
              >
                T & C
              </Button>
              {checkDeclear() &&
                <Button style={{ backgroundColor: Utils.themeColor, border: 'none', height: 40 }}
                  onClick={declearWinner}
                >
                  Declear Winner
                </Button>
              }
            </Col>
          </Row>
        }
        <Card>
          <CardBody>
            <DataTable
              columns={columns}
              data={data}
              pagination
              paginationPerPage={100}
              paginationRowsPerPageOptions={[100, 200, 300, 400]}
              paginationComponentOptions={{ selectAllRowsItem: true }}
              customStyles={{
                headCells: {
                  style: {
                    color: 'black',
                    fontWeight: 'bold',
                    fontSize: 15,
                    maxWidth: 80
                  },
                },
                cells: {
                  style: {
                    maxWidth: 50
                  }
                }
              }}
            />
          </CardBody>
        </Card>
        <Modal isOpen={isAddForm} centered style={{ maxWidth: 800 }}>
          <ModalBody className="py-3 px-5">
            <h5 className="text-black font-size-20">Winner Details</h5>
            <AvForm onValidSubmit={handleValidSubmit} className="form-horizontal">
              <Row>
                <Col lg={12} md={12}>
                  <div className="mb-3 mt-3">
                    <AvField
                      name="shipped"
                      label="Shipping Date"
                      className="form-control"
                      placeholder="Enter screen name"
                      type="date"
                      required
                      value={currentData?.shipped}
                    />
                  </div>
                </Col>
                <Col md={12} className='mb-4'>
                  {file ?
                    <>
                      {img ?
                        <div style={{ width: 360, height: 'auto', position: 'relative' }}>
                          <img
                            src={img}
                            alt=""
                            style={{ width: '100%', height: 'auto' }}
                          // onClick={(e) => imageInput.current.click(e)}
                          />
                          <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                            onClick={() => { setFile(null); setImg("") }}
                          >
                            <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                          </div>
                        </div>
                        :
                        <Cropper
                          style={{ height: 'auto', width: 500 }}
                          aspectRatio={512 / 256}
                          preview=".img-preview"
                          guides={true}
                          src={file}
                          ref={cropper}
                        />
                      }
                    </>
                    :
                    <div style={{
                      position: 'relative',
                      width: 512,
                      height: 256,
                      border: '1px dashed ' + Utils.themeColor,
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: Utils.themeColor,
                      fontSize: 18
                    }} onClick={(e) => imageInput.current.click(e)}>
                      <span
                        style={{
                          padding: 5,
                          paddingLeft: 15, paddingRight: 15,
                          border: '1px solid ' + Utils.themeColor,
                          borderRadius: 5
                        }}
                      >Choose Image</span>
                      <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                      >
                        <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                      </div>
                    </div>
                  }
                  <input type="file" id="file"
                    ref={imageInput}
                    onChange={onChangeFile}
                    style={{ display: "none" }} />
                </Col>
                <Col lg={12} md={12}>
                  <div className="mb-3 mt-3">
                    <AvField
                      name="notiTitle"
                      label="Notification Title"
                      className="form-control"
                      placeholder="Enter notification Title"
                      type="text"
                      required
                      value={currentData?.notiTitle}
                    />
                  </div>
                </Col>
                <Col lg={12} md={12}>
                  <div className="mb-3 mt-3">
                    <AvField
                      name="notiBody"
                      label="Notification Body"
                      className="form-control"
                      placeholder="Enter notification body"
                      type="textarea"
                      row={3}
                      required
                      value={currentData?.notiBody}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <button
                    className="btn btn-primary w-100 waves-effect waves-light"
                    style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                    onClick={() => { setIsAddForm(false); }}
                    type="reset"
                  >
                    Cancel
                  </button>
                </Col>
                <Col md={4}>
                  <button
                    className="btn btn-primary w-100 waves-effect waves-light"
                    type="submit"
                    style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                    onClick={() => setFormType("update")}
                  >
                    Update
                  </button>
                </Col>
                <Col md={4}>
                  <button
                    className="btn btn-primary w-100 waves-effect waves-light"
                    type="submit"
                    style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                    onClick={() => setFormType("notify")}
                  >
                    Update & Notify
                  </button>
                </Col>
              </Row>
            </AvForm>
          </ModalBody>
        </Modal>
        <Modal isOpen={termsModal} centered style={{ maxWidth: 800 }}>
          <ModalBody className="py-3 px-5">
            <h5 className="text-black font-size-20">Winner Terms & Conditions</h5>
            <AvForm onValidSubmit={handleValidTermsSubmit} className="form-horizontal">
              <Row>
                <Col lg={12} md={12}>
                  <div className="mb-3 mt-3">
                    <AvField
                      name="winnerTerms"
                      className="form-control"
                      placeholder="Enter winner terms and conditions"
                      type="textarea"
                      rows={30}
                      required
                      value={winnerTerms}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <button
                    className="btn btn-primary w-100 waves-effect waves-light"
                    style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                    onClick={() => { setTermsModal(false); }}
                    type="reset"
                  >
                    Cancel
                  </button>
                </Col>
                <Col md={4}>
                  <button
                    className="btn btn-primary w-100 waves-effect waves-light"
                    type="submit"
                    style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                  >
                    Update
                  </button>
                </Col>
              </Row>
            </AvForm>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStatetoProps, {})(Setting)
)
