import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody, Label } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation";
import { toast } from 'react-toastify';
import Utils from '../../Utility';
import { post, get, put } from "../../../helpers/api_helper";
import DeleteModal from '../DeleteModal';
import DataTable from 'react-data-table-component';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import moment from "moment";

const Table = (props) => {
  const { user, role, currentUser, path, setLoading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentModalData, setCurrentModalData] = useState(null);
  const [isAddForm, setIsAddForm] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (user?.token) {
      getMetas();
    }
  }, [user]);

  const getMetas = () => {
    get("/wallpaper/category/list", { token: user?.token })
      .then(json => {
        console.log('response from get project list', path);
        if (json?.statusCode == 200) {
          setData(json?.data);
        } else {
          toast.error(json?.error);
          setError(true);
        }
      })
      .catch(error => {
        console.log('error while getting project list', error);
      })
  }

  useEffect(() => {
    setColumns([
      {
        name: "Position",
        cell: (row, index) => <>
          <Draggable key={row._id} draggableId={row._id} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <i className="mdi mdi-menu" style={{ fontSize: 20, color: Utils.themeColor }}></i>
              </div>
            )}
          </Draggable>
        </>,
        maxWidth: '50px'
      },
      {
        name: 'Title',
        selector: row => row?.title,
        sortable: true,
      },
      {
        name: 'Wallpaper',
        selector: row => row?.wallpaper?.length,
        sortable: true,
        maxWidth: "150px"
      },
      {
        name: 'Created At',
        selector: row => moment(row?.createdAt).format("DD-MMM-YYYY hh:mm a"),
        sortable: true,
        maxWidth: "180px"
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
        maxWidth: "120px"
      },
      {
        cell: (row) => <>
          {user?.role == "Admin" ?
            <>
              <Button onClick={() => handleUpdateEdit(row)}
                title={"Edit"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
              <Button onClick={() => handleDelete(row)}
                title={"Delete"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
            </>
            :
            <>
              {user?.permissions?.length && user?.permissions[7]?.wallpaper?.update &&
                <Button onClick={() => handleUpdateEdit(row)}
                  title={"Edit"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
              }
              {user?.permissions?.length && user?.permissions[7]?.wallpaper?.delete &&
                <Button onClick={() => handleDelete(row)}
                  title={"Delete"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
              }
            </>}
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: "120px"
      },
    ])
  }, [data]);

  useEffect(() => {
    if (!isAddForm) {
      setCurrentModalData(null);
      // imageInput.current.value = "";
    }
  }, [isAddForm])

  const handleUpdateEdit = (proj) => {
    console.log('updating proj', proj);
    setCurrentModalData(proj);
    setIsAddForm(true);
  }

  const handleDelete = (proj, metaType) => {
    console.log('updating proj', proj);
    setCurrentModalData({ ...proj, metaType, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const handleUpdateStatus = (item) => {
    console.log('updating proj', item);
    setCurrentModalData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentModalData);
    let body = {
      catId: currentModalData?._id,
      isDeleted: true,
      token: user?.token
    }
    put(`/wallpaper/category`, body)
      .then(json => {
        console.log('response from delete user', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getMetas();
          setDeleteModal(false);
          setCurrentModalData(null);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const onStatus = () => {
    console.log('status', currentModalData);
    setLoading(true);
    let body = {
      catId: currentModalData?._id,
      isActive: !currentModalData?.isActive,
      token: user?.token
    }
    let url = `/wallpaper/category`;
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getMetas();
          setDeleteModal(false);
          setCurrentModalData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const handleValidSubmit = async (e, values, type) => {
    // console.log('values', values, file);
    setLoading(true);
    if (currentModalData) {
      const body = {
        ...values,
        catId: currentModalData?._id,
        token: user?.token
      }
      put("/wallpaper/category", body)
        .then(json => {
          console.log('response from add state', json);
          setLoading(false);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getMetas();
            setIsAddForm(false);
            setCurrentModalData(null);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while uploading image', error);
          toast.error(error);
        })
        .catch(error => {
          setLoading(false);
          console.log('error while uploading image', error);
          toast.error(error);
        });
    } else {
      const body = {
        ...values,
        token: user?.token
      }
      post("/wallpaper/category", body)
        .then(json => {
          console.log('response from add state', json);
          setLoading(false);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getMetas();
            setIsAddForm(false);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while uploading image', error);
          toast.error(error);
        })
    }
  }

  const handleDragEnd = (result) => {
    // console.log('dragging item', result);
    // Handle the drag-and-drop logic here
    if (!result.destination) return; // Item was dropped outside the list
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/wallpaper/category/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => currentModalData?.actionType == 'Status' ? onStatus() : onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            actionType={currentModalData?.actionType}
            data={currentModalData}
          />
          {/* Add or Update depart form */}
          <Modal isOpen={isAddForm} toggle={() => { setIsAddForm(false); }} centered={true}>
            <ModalBody className="py-3 px-5">
              <h5 className="text-black font-size-20">{currentModalData ? `Update ${role}!` : `Add New ${role}!`}</h5>
              <div className="p-2">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v, 'bank')
                  }}
                >
                  <Row>
                    <Col lg={12} md={12}>
                      <div className="mb-3">
                        <AvField
                          name="title"
                          label="Title"
                          className="form-control"
                          placeholder="Enter title"
                          type="text"
                          required
                          value={currentModalData?.title}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="mt-4">
                    <Row>
                      <Col md={6}>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                          onClick={() => { setIsAddForm(false); }}
                          type="reset"
                        >
                          Cancel
                        </button>
                      </Col>
                      <Col md={6}>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                          style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                        >
                          {currentModalData ? 'Update' : 'Add'}
                        </button>
                      </Col>
                    </Row>
                  </div>
                </AvForm>
              </div>
            </ModalBody>
          </Modal>

          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <div>
                    <CardTitle className="h4">{props.role}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {role} you have added before, All the <code>Wallpaper </code> will be added and shown according to {role}.
                    </CardSubtitle>
                  </div>
                </div>

                <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <h5>Categories</h5>
                  {user?.role == "Admin" ?
                    <div className="text-center" style={{ marginLeft: 'auto' }}>
                      <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                        Add
                      </Button>
                    </div>
                    :
                    <>
                      {user?.permissions?.length && user?.permissions[7]?.wallpaper?.write ?
                        <div className="text-center" style={{ marginLeft: 'auto' }}>
                          <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                            Add
                          </Button>
                        </div>
                        : null}
                    </>
                  }
                </div>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="table">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <DataTable
                          columns={columns}
                          data={data}
                          pagination
                          customStyles={{
                            headCells: {
                              style: {
                                color: 'black',
                                fontWeight: 'bold',
                                fontSize: 15,
                                maxWidth: 80
                              },
                            },
                            cells: {
                              style: {
                                maxWidth: 50
                              }
                            }
                          }}
                        />
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    </React.Fragment >
  )
}

export default Table;
