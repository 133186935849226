import React, { useEffect, useState, useRef } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody, Label } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';

import Utils from '../Utility';
import { post, get, put, upload } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { AvForm, AvField } from "availity-reactstrap-validation";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import './style.css';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import moment from "moment";

const Table = (props) => {
  const { user, role, currentData, setCurrentData, path, loading, setLoading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [file, setFile] = useState(null);
  const [img, setImg] = useState(null);
  const cropper = useRef(null);
  const [isAddForm, setIsAddForm] = useState(false);
  const [error, setError] = useState(false);
  const [selectedLang, setSelectedLang] = useState([]);
  const [type, setType] = useState("");


  useEffect(() => {
    // console.log('props on user table', props)
    if (user) {
      getData();
    }
  }, [user, path]);

  useEffect(() => {
    if (!isAddForm) {
      setCurrentData(null);
      setType("");
    }
  }, [isAddForm]);

  const getData = () => {
    if (!loading) {
      setLoading(true);
      let url = "/coupon/list";
      get(url, { token: user?.token })
        .then(json => {
          console.log('response from get banner list', json);
          setLoading(false);
          if (json?.statusCode == 200) {
            setData(json?.data);
          } else {
            toast.error(json?.error);
            setError(true);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while getting project list', error);
        });
    }
  }

  useEffect(() => {
    setColumns([
      {
        name: 'Code',
        selector: row => row?.code,
      },
      {
        name: 'Expiry',
        selector: row => moment(row?.expire).format("DD-MMM-YYYY"),
        maxWidth: '130px'
      },
      {
        name: '% Off',
        selector: row => row?.value,
        maxWidth: '100px'
      },
      {
        name: 'Type',
        selector: row => row?.type,
        maxWidth: '100px'
      },
      {
        name: 'Is Used',
        selector: row => row?.isUsed ? "Yes" : "No",
        maxWidth: '100px'
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row, "Status")}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
        maxWidth: '110px'
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row, "Recomm")}
            title={row?.recomm ? "Yes" : "No"}
            style={{ backgroundColor: !row.recomm ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {row?.recomm ? 'Yes' : 'No'}
            </span>
          </Button>
        </>,
        name: 'Recommand',
        ignoreRowClick: true,
        maxWidth: '130px'
      },
      {
        cell: (row) => <>
          {/* <Button onClick={() => props?.history.push(`/user/detail/` + row?._id, { state: { type: path } })}
            title={"View in details"}
            style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-eye" style={{ fontSize: 20 }}></span></Button> */}
          <Button onClick={() => handleUpdateProj(row)}
            title={"Edit"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
          <Button onClick={() => handleDeleteProj(row)}
            title={"Delete"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: '100px'
      },
    ])
  }, [data]);

  const handleUpdateProj = (proj) => {
    // console.log('updating proj', proj);
    setCurrentData(proj);
    setFile(proj?.image);
    setImg(proj?.image);
    setIsAddForm(true);
  }

  const handleUpdateStatus = (item, type) => {
    console.log('updating proj', item);
    setCurrentData({ ...item, actionType: type });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData({ ...proj, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      couponId: currentData?._id,
      token: user?.token
    }
    let url = "/coupon";
    if (currentData?.actionType == 'Status') {
      body = { ...body, isActive: !currentData?.isActive };
    } else if (currentData?.actionType == "Recomm") {
      body = { ...body, recomm: !currentData?.recomm };
    } else {
      body = { ...body, isDeleted: true };
    }
    put(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const onChangeFile = async (e) => {
    // console.log(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1800,
        600,
        "webp",
        60,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handleValidSubmit = async (e, v) => {
    let body = {
      ...v,
      token: user?.token,
    }
    if (currentData?._id) {
      body = { ...body, couponId: currentData?._id }
      put("/coupon", body)
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            getData();
            toast.success(res?.message);
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while updating banner', error);
        })
    } else {
      post("/coupon", body)
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            toast.success(res?.messgae);
            getData();
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          toast.error('Something Went Wrong!');
          console.log('error while adding banner', error);
        })
    }
  }

  const formatDateTime = (str) => {
    console.log("formatting date", str);
    return moment(str).format('YYYY-MM-DD');
    // return "2023-11-29T07:50";
  }

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            data={currentData}
          />
          <Modal isOpen={isAddForm} centered={true} style={{ maxWidth: 800 }}>
            <ModalBody>
              <div className="me-3 position-relative">
                <AvForm
                  className="form-horizontal mt-4"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                  }}
                >
                  <Row>
                    <Col lg={6} md={6}>
                      <div className="form-group mb-4">
                        <AvField
                          name="code"
                          label={"Coupon Code"}
                          value={currentData?.code}
                          className="form-control"
                          placeholder="Enter coupon code"
                          type="text"
                          required
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group mb-4">
                        <AvField
                          name="type"
                          label={"Coupon Type"}
                          value={currentData?.type}
                          className="form-control"
                          type="select"
                          onChange={(e) => setType(e.target.value)}
                          required
                        >
                          <option value="">Select Coupon Type</option>
                          <option value="Single">Single Person</option>
                          <option value="Multiple">Multiple Person</option>
                        </AvField>
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group mb-4">
                        <AvField
                          name="value"
                          label={"Off Value (Percent)"}
                          value={currentData?.value}
                          className="form-control"
                          placeholder="Enter the percent off"
                          type="number"
                          required
                          validate={{ min: { value: 0 }, max: { value: 100 } }}
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="form-group mb-4">
                        <AvField
                          name="expire"
                          label={"Expire date"}
                          value={formatDateTime(currentData?.expire)}
                          className="form-control"
                          // placeholder="Enter the off price"
                          type="date"
                          required
                        />
                      </div>
                    </Col>
                    {type == "Single" &&
                      <Col md={12}>
                        <div className="form-group mb-4">
                          <AvField
                            name="num"
                            label={"Number Of Coupons"}
                            value={currentData?.num}
                            className="form-control"
                            placeholder="Number of coupon you want to generate by this same series."
                            type="number"
                            required
                            validate={{ min: { value: 1 }, max: { value: 50 } }}
                          />
                        </div>
                      </Col>
                    }
                    <div className="text-center mt-4 d-flex justify-content-around">
                      <Button type="reset" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                        onClick={() => { setCurrentData(null); setIsAddForm(false) }}
                      >
                        Cancel
                      </Button>
                      {currentData ?
                        <Button type="submit" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                          Update Coupon
                        </Button>
                        :
                        <Button type="submit" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                          Add Coupon
                        </Button>
                      }
                    </div>
                  </Row>
                </AvForm>
              </div>
            </ModalBody>
          </Modal>
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <div>
                    <CardTitle className="h4">{props.role}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {role} you have added before, All the <code>{role} </code>can be used while purchasing any<code> plan.</code>
                    </CardSubtitle>
                  </div>
                  {!error &&
                    <>
                      {/* <div style={{ marginLeft: 'auto' }}>
                        <AvForm style={{ border: 'none' }}
                        >
                          <AvField
                            name="bannerHeight"
                            placeholder={"Banner Height"}
                            style={{ width: 150, border: "1px solid " + Utils.themeColor }}
                            validate={{ min: { value: 256 }, max: { value: 700 } }}
                            type="number"
                            value={bannerHeight}
                            onChange={(e) => handleBannerHeight(e.target.value)}
                          />
                        </AvForm>
                      </div> */}
                      <div className="text-center" style={{ marginLeft: 'auto' }}>
                        <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                          Add
                        </Button>
                      </div>
                    </>
                  }
                </div>
                <Row>
                  <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    customStyles={{
                      headCells: {
                        style: {
                          color: 'black',
                          fontWeight: 'bold',
                          fontSize: 15,
                          maxWidth: 80
                        },
                      },
                      cells: {
                        style: {
                          maxWidth: 50
                        }
                      }
                    }}
                    conditionalRowStyles={[{
                      when: row => row?.seen?.length < 1,
                      style: { backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, color: 'white', cursor: 'pointer' },
                    }]}
                  />
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment >
  )
}
export default Table;
