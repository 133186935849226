import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  CardSubtitle,
  Modal,
  ModalBody,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter, useParams, useLocation, Link } from "react-router-dom";
import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility';
import Loader from "../../components/Loader";
import { get, post, put, uploadS3 } from '../../helpers/api_helper';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import DataTable from 'react-data-table-component';
import moment from "moment";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ProgressBar from "../../components/ProgressBar";
import { toast } from "react-toastify";
import DeleteModal from "./DeleteModal";

const Agent = props => {
  const { user } = props;
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isAdd, setIsAdd] = useState(false);
  const [file, setFile] = useState(null);
  const [img, setImg] = useState(null);
  const imageInput = useRef(null);
  const cropper = useRef(null);
  const [progress, setProgress] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);


  useEffect(() => {
    if (user?.token)
      getData();
  }, [user]);

  useEffect(() => {
    setColumns([
      {
        name: "Position",
        cell: (row, index) => <>
          <Draggable key={row._id} draggableId={row._id} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <i className="mdi mdi-menu" style={{ fontSize: 20, color: Utils.themeColor }}></i>
              </div>
            )}
          </Draggable>
        </>,
        maxWidth: '50px'
      },
      {
        name: 'Title',
        selector: row => row?.title,
        cell: (row) => <div>
          <span style={{ textTransform: 'capitalize', marginLeft: 5 }}>{row?.title}</span>
        </div>,
        sortable: true,
        maxWidth: '200px'
      },
      // {
      //   name: 'Image',
      //   selector: row => <img title={'image'} src={row?.image} style={{ width: 100, height: 'auto', objectFit: "cover", marginTop: 5, marginBottom: 5 }} />,
      //   maxWidth: '110px'
      // },
      {
        name: 'Message',
        selector: row => row?.message,
        cell: (row) => <>
          <p style={{ whiteSpace: 'pre-line', marginTop: 10 }} title={row?.message}>{row?.message}</p>
        </>,
        sortable: true
      },
      {
        name: 'Updated At',
        selector: row => row?.updatedAt,
        cell: (row) => <>
          <span style={{ textTransform: 'capitalize' }}>{moment(row?.updatedAt).format("DD MMM YYYY, hh:mm A")}</span>
        </>,
        sortable: true,
        maxWidth: '180px'
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
        maxWidth: "130px"
      },
      {
        cell: (row) => <>
          <>
            <Button onClick={() => handleEdit(row)}
              title={"Edit"}
              style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={"mdi mdi-pencil"} style={{ fontSize: 20, color: 'white' }}></span></Button>
            <Button onClick={() => handleDelete(row)}
              title={"Delete"}
              style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={"mdi mdi-delete"} style={{ fontSize: 20, color: 'white' }}></span></Button>
          </>
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: '190px'
      }
    ]);
  }, [data]);

  const getData = () => {
    if (!loading) {
      setLoading(true);
      let url = `/whatsapp`;
      get(url, { token: user?.token })
        .then(json => {
          setLoading(false);
          if (json?.statusCode == 200) {
            setData(json?.data);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while getting project list', error);
        });
    }
  }

  const onChangeFile = async (e) => {
    // console.log(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1800,
        600,
        "webp",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const onProgress = (p) => {
    setProgress(p);
  }

  const handleValidSubmit = async (e, v) => {
    let body = {
      ...v,
      token: user?.token
    }
    // if (file.substring(0, 4) == "blob") {
    //   const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
    //   const obj = await urltoFile(fileData, new Date().getTime() + '.png', 'image/png');
    //   const temp = await resizeFile(obj);
    //   const imageRes = await uploadS3("whatsapp", temp, "webp", onProgress);
    //   body.image = imageRes?.data;
    // }
    setProgress(0);
    setLoading(true);
    console.log("body on add whatsapp messgae", body);
    if (currentData?._id) {
      body.msgId = currentData?._id;
      put("/whatsapp", body)
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            getData();
            setIsAdd(false);
            toast.success(res?.message);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          console.log("Error while updateing whatsapp message", err);
          setLoading(false);
          toast.error("Something Went Wrong!");
        })
    } else {
      post("/whatsapp", body)
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            setIsAdd(false);
            toast.success(res?.message);
            getData();
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          setLoading(false);
          console.log("Error while updateing whatsapp message", err);
          toast.error("Something Went Wrong!");
        })
    }
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return; // Item was dropped outside the list
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/whatsapp/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };

  const handleEdit = (row) => {
    setCurrentData(row);
    setFile(row?.image);
    setImg(row?.image);
    setIsAdd(true);
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      msgId: currentData?._id,
      token: user?.token
    }
    let url = "/whatsapp";
    if (currentData?.actionType == 'Status') {
      body = { ...body, isActive: !currentData?.isActive };
    } else {
      body = { ...body, isDeleted: true };
    }
    put(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const handleUpdateStatus = (item) => {
    // console.log('updating proj', item);
    setCurrentData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDelete = (item) => {
    // console.log('updating proj', item);
    setCurrentData({ ...item, actionType: 'Delete' });
    setDeleteModal(true);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Users" />
        <ProgressBar
          visible={progress < 1 ? false : true}
          progress={progress}
        />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => onDelete()}
          onCloseClick={() => setDeleteModal(false)}
          data={currentData}
        />
        <Modal className="modal_form" isOpen={isAdd} toggle={() => { setIsAdd(false); setCurrentData(null) }} centered={true} style={{ maxWidth: 800 }}>
          <ModalBody className="py-3 px-5">
            <h5 className="text-black font-size-20 mb-4">{`${currentData?._id ? 'Update ' : 'Add new '}message`}</h5>
            <div className="p-2">
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v)
                }}
              >
                <Row>
                  {/* <Col md={12}>
                    {file ?
                      <>
                        {img ?
                          <>
                            <img
                              src={img}
                              alt=""
                              style={{ width: 360, height: 'auto' }}
                            // onClick={(e) => imageInput.current.click(e)}
                            />
                            <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                              onClick={() => { setFile(null); setImg("") }}
                            >
                              <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                            </div>
                          </>
                          :
                          <Cropper
                            style={{ height: 'auto', width: 500 }}
                            // aspectRatio={712 / 1011}
                            preview=".img-preview"
                            guides={true}
                            src={file}
                            ref={cropper}
                          />
                        }
                      </>
                      :
                      <div style={{
                        position: 'relative',
                        width: 585,
                        height: 320,
                        border: '1px dashed ' + Utils.themeColor,
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: Utils.themeColor,
                        fontSize: 22
                      }} onClick={(e) => imageInput.current.click(e)}>
                        <span
                          style={{
                            padding: 5,
                            paddingLeft: 15, paddingRight: 15,
                            border: '1px solid ' + Utils.themeColor,
                            borderRadius: 5
                          }}
                        >Choose Image *</span>
                        <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                        >
                          <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                        </div>
                      </div>
                    }
                    <input type="file" id="file"
                      ref={imageInput}
                      onChange={onChangeFile}
                      style={{ display: "none" }} />
                  </Col> */}
                  <Col md={12}>
                    <div className="form-group mb-4 mt-4">
                      <AvField
                        name="title"
                        label={"Message Title *"}
                        className="form-control"
                        type="text"
                        placeholder={"Enter the message title"}
                        required
                        value={currentData?.title}
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="form-group mb-4">
                      <AvField
                        name="message"
                        label={"Message *"}
                        className="form-control"
                        placeholder={"Enter the message"}
                        type="textarea"
                        value={currentData?.message}
                        required
                        rows={5}
                      />
                    </div>
                  </Col>
                  <Col lg={12} md={12}>
                    <div className="mt-4">
                      <Row>
                        <Col md={6}>
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                            onClick={() => { setIsAdd(false); setCurrentData(null) }}
                            type="reset"
                          >
                            Cancel
                          </button>
                        </Col>
                        <Col md={6}>
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                            style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                          >
                            Send
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </div>
          </ModalBody>
        </Modal>
        <Card>
          <CardBody>
            <Row className="mb-5">
              <Col md={11}>
                <CardTitle className="h4">{props.role}</CardTitle>
                <CardSubtitle className="mb-3">
                  Whatsapp messages you have added before, All the <code>Sales person</code> will send the whatsapp messages to<code> users </code> if their status is <code>Active.</code>
                </CardSubtitle>
              </Col>
              <Col md={1}>
                <button
                  className="btn btn-primary w-100 waves-effect waves-light"
                  type="button"
                  onClick={() => setIsAdd(true)}
                  style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                >
                  Add
                </button>
              </Col>
            </Row>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="table">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <DataTable
                      columns={columns}
                      data={data}
                      pagination
                      customStyles={{
                        headCells: {
                          style: {
                            color: 'black',
                            fontWeight: 'bold',
                            fontSize: 15,
                            maxWidth: 80
                          },
                        },
                        cells: {
                          style: {
                            maxWidth: 50
                          }
                        }
                      }}
                    />
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStatetoProps, {})(Agent)
)
