import React, { useEffect, useState, useRef } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody, Label } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';
import { AvField, AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import { toast } from 'react-toastify';
import Utils from '../Utility';
import { post, get, put, upload } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import UiDropdown from "../Ui/UiDropdown";
import SearchableDropdown from "../Ui/UiSearchableDropdown";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "./style.css";
import Multiselect from 'multiselect-react-dropdown';
import moment from "moment";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// import './Card.scss';

const Table = (props) => {
  const { user, role, currentData, setIsTable, setSingleId, path, setLoading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [proData, setProData] = useState([]);
  const [tags, setTags] = useState([]);
  const [cats, setCats] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentModalData, setCurrentModalData] = useState(null);
  const [isAddForm, setIsAddForm] = useState(false);
  const [file, setFile] = useState(null);
  const [thumb, setThumb] = useState(null);
  const [imageChanged, setImageChanged] = useState(false);
  const imageInput = useRef();
  const [selectedPros, setSelectedPros] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState([]);
  const cropper = useRef();
  const cropperThumb = useRef();
  const logoCropper = useRef();
  const [include, setInclude] = useState("");
  const [tag, setTag] = useState("");
  const [descContent, setDescContent] = useState(null);
  const [desc, setDesc] = useState("");


  useEffect(() => {
    if (user?.token) {
      getMetas();
      getTags();
    }
  }, [user, path]);

  const getMetas = () => {
    get("/blog/")
      .then(json => {
        if (json?.statusCode == 200) {
          // console.log('response from get blog list', json?.data);
          setData(json?.data);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.log('error while getting project list', error);
      })
  }

  const getTags = () => {
    get("/tags")
      .then(json => {
        if (json?.statusCode == 200) {
          console.log('response from get blog list', json?.data);
          setTags(json?.data);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.log('error while getting project list', error);
      })
    get("/blog/category/drop")
      .then(json => {
        if (json?.statusCode == 200) {
          console.log('response from get blog list', json?.data);
          setCats(json?.data);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.log('error while getting project list', error);
      })
  }

  useEffect(() => {
    setColumns([
      {
        name: "Position",
        cell: (row, index) => <>
          <Draggable key={row._id} draggableId={row._id} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <i className="mdi mdi-menu" style={{ fontSize: 20, color: Utils.themeColor }}></i>
              </div>
            )}
          </Draggable>
        </>,
        maxWidth: '50px'
      },
      {
        name: 'Image',
        cell: (row) => <>
          <img title={'logo'} src={row?.image} style={{ width: 50, height: 'auto', backgroundColor: 'white' }} />
        </>,
        maxWidth: "130px"
      },
      {
        name: 'Title',
        selector: row => row?.title,
      },

      {
        name: 'Updated At',
        selector: row => moment(row.updatedAt).format("DD-MMM-YYYY hh:mm a"),
        maxWidth: "180px"
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
        maxWidth: "130px"
      },
      {
        cell: (row) => <>
          <Button onClick={() => { setSingleId(row?._id); setIsTable(false) }}
            title={"View"}
            style={{ backgroundColor: Utils.themeColor, border: 'none', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-eye" style={{ fontSize: 20 }}></span></Button>
          {user?.role == 'Admin' ?
            <>
              <Button onClick={() => handleUpdateEdit(row)}
                title={"Edit"}
                style={{ backgroundColor: Utils.themeColor, border: 'none', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
              <Button onClick={() => handleDelete(row)}
                title={"Delete"}
                style={{ backgroundColor: Utils.themeColor, border: 'none', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
            </>
            :
            <>
              {user?.permissions?.length && user?.permissions[16]?.blog?.update &&
                <Button onClick={() => handleUpdateEdit(row)}
                  title={"Edit"}
                  style={{ backgroundColor: Utils.themeColor, border: 'none', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
              }
              {user?.permissions?.length && user?.permissions[16]?.blog?.delete &&
                <Button onClick={() => handleDelete(row)}
                  title={"Delete"}
                  style={{ backgroundColor: Utils.themeColor, border: 'none', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
              }
            </>
          }
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: "150px"
        // allowOverflow: true,
        // button: true,
      },
    ])
  }, [data]);

  useEffect(() => {
    if (!isAddForm) {
      setCurrentModalData(null);
      setTag([]);
      setFile(null);
      setThumb(null);
      setDescContent(null);
      setDesc("");
      // imageInput.current.value = "";
    }
  }, [isAddForm])

  const handleUpdateEdit = (proj) => {
    // console.log('updating proj', proj);
    setCurrentModalData(proj);
    setFile(proj?.image);
    setThumb(proj?.thumb);
    setImageChanged(false);
    if (proj?.desc) {
      const contentBlock = htmlToDraft(proj?.desc);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock?.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        console.log("content block", editorState);
        setDescContent(editorState);
        setDesc(proj?.desc);
      }
    }
    if (proj?.tags?.length) {
      let temp = [];
      proj?.tags?.forEach(element => {
        let found = tags?.find(x => x?._id == element);
        // console.log("tags, selected tags", tags, element, found);
        if (found)
          temp.push(found);
      });
      setTag(temp);
      // console.log('updating proj', temp);
    }
    setIsAddForm(true);
  }

  const handleDelete = (proj, metaType) => {
    console.log('updating proj', proj);
    setCurrentModalData({ ...proj, metaType, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const handleUpdateStatus = (item) => {
    if (user?.role == "Admin") {
      setCurrentData({ ...data, actionType: 'Status' });
      setDeleteModal(true);
    } else
      toast.error("You're not authorized for this action!");
  }

  const onDelete = () => {
    console.log('deleted', currentModalData);
    let body = {
      blogId: currentModalData?._id,
      token: user?.token
    }
    post(`/blog/delete`, body)
      .then(json => {
        console.log('response from delete user', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getMetas();
          setDeleteModal(false);
          setCurrentModalData(null);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const onStatus = () => {
    console.log('status', currentModalData);
    setLoading(true);
    let body = {
      blogId: currentModalData?._id,
      isActive: !currentModalData?.isActive,
      token: user?.token
    }
    let url = `/blog/update`;
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getMetas();
          setDeleteModal(false);
          setCurrentModalData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1800,
        600,
        "webp",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handleValidSubmit = async (e, values, type) => {
    console.log('values', values, file);
    // setLoading(true);
    if (tag?.length < 1) {
      toast.error('Select atleast one Tag');
      return;
    }
    let temp = [];
    tag.map((item) => {
      temp.push(item?._id);
    });
    if (currentModalData) {
      let body = {
        ...values,
        tags: temp,
        blogId: currentModalData?._id,
        desc: desc,
        token: user?.token
      }
      if (thumb && thumb?.substring(0, 4) == "blob") {
        const fileData = cropperThumb?.current?.cropper?.getCroppedCanvas().toDataURL();
        const fileObj = await urltoFile(fileData, (new Date().getTime() + 300) + '.png', 'image/png')
        const image2 = await resizeFile(fileObj);
        const form2 = new FormData();
        form2.append("image", image2);
        const uploadedBanner = await upload("/blog/image_upload", form2)
        if (uploadedBanner?.statusCode == 200)
          body.thumb = uploadedBanner?.data;
      }
      if (file && file?.substring(0, 4) == "blob") {
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        const fileObj = await urltoFile(fileData, (new Date().getTime() + 300) + '.png', 'image/png')
        const image2 = await resizeFile(fileObj);
        const form2 = new FormData();
        form2.append("image", image2);
        const uploadedBanner = await upload("/blog/image_upload", form2)
        if (uploadedBanner?.statusCode == 200)
          body.image = uploadedBanner?.data;
      }
      put("/blog/update", body)
        .then(json => {
          console.log('response from add month', json);
          setLoading(false);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getMetas();
            setFile(null);
            setImageChanged(false);
            setIsAddForm(false);
            setCurrentModalData(null);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while uploading image', error);
          toast.error(error);
        });
    } else {
      let body = {
        ...values,
        desc: desc,
        tags: temp,
        token: user?.token
      }
      const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
      const fileObj = await urltoFile(fileData, (new Date().getTime() + 300) + '.png', 'image/png')
      console.log('values 2', fileObj);
      const image2 = await resizeFile(fileObj);
      console.log('values 2 after resize', image2);
      const form2 = new FormData();
      form2.append("image", image2);
      const uploadedBanner = await upload("/blog/image_upload", form2)
      console.log('response from image upload', uploadedBanner);
      if (uploadedBanner?.statusCode == 200)
        body.image = uploadedBanner?.data;

      const thumbData = cropperThumb?.current?.cropper?.getCroppedCanvas().toDataURL();
      const thumbObj = await urltoFile(thumbData, (new Date().getTime() + 300) + '.png', 'image/png')
      const thumb2 = await resizeFile(thumbObj);
      const form3 = new FormData();
      form3.append("image", thumb2);
      const uploadedThumb = await upload("/blog/image_upload", form3)
      if (uploadedThumb?.statusCode == 200)
        body.thumb = uploadedThumb?.data;

      console.log('onchange body', body);
      // return
      post("/blog/add", body)
        .then(json => {
          console.log('response from add blog', json);
          setLoading(false);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getMetas();
            setFile(null);
            setImageChanged(false);
            setIsAddForm(false);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while uploading image', error);
          toast.error(error);
        })
    }
  }

  const onChangeThumb = (e) => {
    console.log('getting event on input img', e.target.files[0]);
    setThumb(URL.createObjectURL(e.target.files[0]));
    // setImageChanged(true);
  }

  const onChangeFile = (e) => {
    console.log('getting event on input img', e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
    setImageChanged(true);
  }

  const handleTag = (event) => {
    const { value } = event.target;
    setTag(value);
  };

  const handleTagChange = () => {
    if (tag) {
      let temp = Object.assign([], tags);
      console.log('found property', temp);
      temp.push(tag);
      setTags(temp);
      setTag('')
    }
  }


  const handleRemoveTags = (index) => {
    let selected = Object.assign([], tags);
    selected.splice(index, 1);
    setTags(selected);
  }

  const onEditorStateChange = (editorState) => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setDesc(html);
    setDescContent(editorState);
  }

  const handleButton = () => {
    if (isAddForm) {
      setIsAddForm(false);
      setFile(null)
    }
    else setIsAddForm(true)
  }

  const handleDragEnd = (result) => {
    // console.log('dragging item', result);
    // Handle the drag-and-drop logic here
    if (!result.destination) return; // Item was dropped outside the list
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/blog/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };

  // console.log('updating proj', tag);


  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => currentModalData?.actionType == 'Status' ? onStatus() : onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            actionType={currentModalData?.actionType}
            data={currentModalData}
          />
          {/* Add or Update depart form */}
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <div>
                    <CardTitle className="h4">{props.role}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {role} you have added before, All the <code>blogs </code> will be Shown on user end if they are active.
                    </CardSubtitle>
                  </div>
                </div>
                <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <h5>{role}</h5>
                  <div className="text-center" style={{ marginLeft: 'auto' }}>
                    <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => { handleButton() }}>
                      {isAddForm ? "Close" : "Add"}
                    </Button>
                  </div>
                </div>
                {isAddForm ?
                  <div className="p-2">
                    <h5 className="text-black font-size-20">{currentModalData ? 'Update Blog!' : 'Add New Blog!'}</h5>
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v, 'bank')
                      }}
                    >
                      <Row>
                        <Col lg={6} md={6}>
                          <div className="mb-4 mt-2" style={{ display: 'block', position: 'relative', justifyContent: 'flex-start' }}>
                            {thumb ?
                              <>
                                <CardTitle>Thumb *</CardTitle>
                                <div style={{ flexDirection: 'row', position: 'relative', cursor: 'pointer', marginTop: 20, width: 400 }}>
                                  {currentModalData?.thumb ?
                                    <img src={thumb} style={{ width: 400, height: 'auto' }} />
                                    :
                                    <Cropper
                                      style={{ height: 'auto', width: 400 }}
                                      aspectRatio={1 / 1}
                                      preview=".img-preview"
                                      guides={true}
                                      src={thumb}
                                      ref={cropperThumb}
                                    />
                                  }
                                  <i className="mdi mdi-close" style={{
                                    color: 'white', backgroundColor: Utils.themeColor, fontSize: 20,
                                    position: 'absolute', top: -16, right: -28, borderRadius: 15, width: 30, height: 30, textAlign: 'center'
                                  }}
                                    onClick={() => { setThumb(null); if (currentModalData) setCurrentModalData({ ...currentModalData, thumb: undefined }) }}
                                  />
                                </div>
                              </>
                              :
                              <AvField
                                name="thumb"
                                label="Thumb"
                                className="form-control"
                                placeholder="upload bank logo"
                                type="file"
                                onChange={e => onChangeThumb(e)}
                                required
                                multiple={false}
                                // ref={imageInput}
                                accept="image/*"
                              // value={currentModalData?.logo}
                              />
                            }
                          </div>
                        </Col>
                        <Col lg={6} md={6}>
                          <div className="mb-4 mt-2" style={{ display: 'block', position: 'relative', justifyContent: 'flex-start' }}>
                            {file ?
                              <>
                                <CardTitle >Image *</CardTitle>
                                <div style={{ flexDirection: 'row', position: 'relative', cursor: 'pointer', marginTop: 20, width: 400 }}>
                                  {currentModalData?.image ?
                                    <img src={file} style={{ width: 400, height: 'auto' }} />
                                    :
                                    <Cropper
                                      style={{ height: 'auto', width: 400 }}
                                      // aspectRatio={16 / 5}
                                      preview=".img-preview"
                                      guides={true}
                                      src={file}
                                      ref={cropper}
                                    />
                                  }
                                  <i className="mdi mdi-close" style={{
                                    color: 'white', backgroundColor: Utils.themeColor, fontSize: 20,
                                    position: 'absolute', top: -16, right: -28, borderRadius: 15, width: 30, height: 30, textAlign: 'center'
                                  }}
                                    onClick={() => { setFile(null); if (currentModalData) setCurrentModalData({ ...currentModalData, image: undefined }) }}
                                  />
                                </div>
                              </>
                              :
                              <AvField
                                name="fileInput"
                                label="Image"
                                className="form-control"
                                placeholder="upload bank logo"
                                type="file"
                                onChange={e => onChangeFile(e)}
                                required
                                multiple={false}
                                ref={imageInput}
                                accept="image/*"
                              // value={currentModalData?.logo}
                              />
                            }
                          </div>
                        </Col>
                        <Col lg={6} md={6}>
                          <div className="mb-3">
                            <AvField
                              name="title"
                              label="Title *"
                              className="form-control"
                              placeholder="Enter title"
                              type="text"
                              required
                              value={currentModalData?.title}
                            />
                          </div>
                        </Col>
                        <Col lg={6} md={6}>
                          <div className="mb-4 align-c">
                            <Label >Tags *</Label>
                            <Multiselect
                              options={tags}
                              onSelect={(selectedList, selectedItem) => setTag(selectedList)}
                              onRemove={(selectedList, removedItem) => setTag(selectedList)}
                              displayValue="title"
                              selectedValues={tag}
                              placeholder={"Select Tags"}
                              // showCheckbox
                              name="Tags"
                            />
                          </div>
                        </Col>
                        <Col lg={6} md={6}>
                          <div className="mb-3">
                            <AvField
                              name="catId"
                              label="Category *"
                              className="form-control"
                              // placeholder="Enter the url that will bind the (https://praarthana.app/blog/...)"
                              type="select"
                              required
                              value={currentModalData?.catId}
                            >
                              <option value="">Select Category</option>
                              {cats?.map((item) => (
                                <option value={item?._id}>{item?.title}</option>
                              ))}
                            </AvField>
                          </div>
                        </Col>
                        <Col lg={6} md={6}>
                          <div className="mb-3">
                            <AvField
                              name="link"
                              label="Blog URL *"
                              className="form-control"
                              placeholder="Enter the url that will bind the (https://praarthana.app/blog/...)"
                              type="text"
                              required
                              value={currentModalData?.link}
                            />
                          </div>
                        </Col>
                        <Col lg={12} md={12}>
                          <div className="mb-3">
                            <Editor
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              onEditorStateChange={(e) => onEditorStateChange(e)}
                              editorState={descContent ? descContent : null}
                            />
                          </div>
                        </Col>
                        <Col md={12} className="mt-4">
                          <Label><b>Notification</b></Label>
                        </Col>
                        <Col md={4}>
                          <div className="mb-3">
                            <AvField
                              name="notiTitle"
                              label="Title *"
                              className="form-control"
                              placeholder="Enter notification title"
                              type="text"
                              required
                              value={currentModalData?.notiTitle}
                            />
                          </div>
                        </Col>
                        <Col md={8}>
                          <div className="mb-3">
                            <AvField
                              name="notiDesc"
                              label="Body *"
                              className="form-control"
                              placeholder="Enter notification body"
                              type="text"
                              required
                              value={currentModalData?.notiDesc}
                            />
                          </div>
                        </Col>
                        <Col md={12} className="mt-4">
                          <Label><b>SEO</b></Label>
                        </Col>
                        <Col md={4}>
                          <div className="mb-3">
                            <AvField
                              name="metaTitle"
                              label="Meta Title"
                              className="form-control"
                              placeholder="Enter meta title"
                              type="text"
                              value={currentModalData?.metaTitle}
                            />
                          </div>
                        </Col>
                        <Col md={8}>
                          <div className="mb-3">
                            <AvField
                              name="metaDesc"
                              label="Meta Desc"
                              className="form-control"
                              placeholder="Enter meta description"
                              type="text"
                              value={currentModalData?.metaDesc}
                            />
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="mb-3">
                            <AvField
                              name="robotURL"
                              label="Robots URL"
                              className="form-control"
                              placeholder="Enter robots URL"
                              type="text"
                              value={currentModalData?.robotURL}
                            />
                          </div>
                        </Col>
                        <Col md={8}>
                          <div className="mb-3">
                            <AvField
                              name="metaKey"
                              label="Meta Keywords"
                              className="form-control"
                              placeholder="Enter meta keywords"
                              type="text"
                              value={currentModalData?.metaKey}
                            />
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="mb-3">
                            <AvField
                              name="ogTitle"
                              label="OG Title"
                              className="form-control"
                              placeholder="Enter OG Title"
                              type="text"
                              value={currentModalData?.ogTitle}
                            />
                          </div>
                        </Col>
                        <Col md={8}>
                          <div className="mb-3">
                            <AvField
                              name="ogURL"
                              label="OG URL"
                              className="form-control"
                              placeholder="Enter OG URL"
                              type="url"
                              value={currentModalData?.ogURL}
                            />
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="mb-3">
                            <AvField
                              name="ogType"
                              label="OG Type"
                              className="form-control"
                              placeholder="Enter OG Type"
                              type="text"
                              value={currentModalData?.ogType}
                            />
                          </div>
                        </Col>
                        <Col md={8}>
                          <div className="mb-3">
                            <AvField
                              name="ogLocation"
                              label="OG Location"
                              className="form-control"
                              placeholder="Enter OG Location"
                              type="text"
                              value={currentModalData?.ogLocation}
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <AvField
                              name="ogDesc"
                              label="OG Description"
                              className="form-control"
                              placeholder="Enter OG Description"
                              type="textarea"
                              value={currentModalData?.ogDesc}
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <AvField
                              name="canoURL"
                              label="Canonical URL"
                              className="form-control"
                              placeholder="Enter Canonical URL"
                              type="url"
                              value={currentModalData?.canoURL}
                            />
                          </div>
                        </Col>
                        <Col lg={12} md={12}>
                          <div className="mt-4">
                            <Row>
                              <Col md={6}>
                                <button
                                  className="btn btn-primary w-100 waves-effect waves-light"
                                  style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                                  onClick={() => { setIsAddForm(false); setFile(null) }}
                                  type="reset"
                                >
                                  Cancel
                                </button>
                              </Col>
                              <Col md={6}>
                                <button
                                  className="btn btn-primary w-100 waves-effect waves-light"
                                  type="submit"
                                  style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                                >
                                  {currentModalData ? 'Update' : 'Add'}
                                </button>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                  :
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="table">
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          <DataTable
                            columns={columns}
                            data={data}
                            pagination
                            customStyles={{
                              headCells: {
                                style: {
                                  color: 'black',
                                  fontWeight: 'bold',
                                  fontSize: 15,
                                  maxWidth: 80
                                },
                              },
                              cells: {
                                style: {
                                  maxWidth: 50
                                }
                              }
                            }}
                          />
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment >
  )
}

export default Table;
