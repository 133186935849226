import React, { useEffect, useState, useRef } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';

import Utils from '../Utility';
import { post, get, put, upload } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { AvForm, AvField } from "availity-reactstrap-validation";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import './style.css';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import moment from "moment";

const Table = (props) => {
  const { user, role, currentData, from, setCurrentData, path, setLoading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [file, setFile] = useState(null);
  const [img, setImg] = useState(null);
  const imageInput = useRef(null);
  const imageToCrop = useRef(null);
  const cropper = useRef(null);
  const [isAddForm, setIsAddForm] = useState(false);
  const [isNavigate, setIsNavigate] = useState("");
  const [navigationType, setNavigationType] = useState("");
  const [screens, setScreens] = useState([]);
  const [bannerHeight, setBannerHeight] = useState(256);
  const [error, setError] = useState(false);
  const [search, setSearch] = useState("");


  useEffect(() => {
    // console.log('props on user table', props)
    if (user?.token) {
      getData();
    }
  }, [user, search]);

  useEffect(() => {
    if (!isAddForm) {
      setCurrentData(null);
      setFile(null);
      setImg(null);
      setIsNavigate("");
      setNavigationType("")
    }
  }, [isAddForm]);

  const getData = () => {
    let url = "/rashifal/list/admin?search=" + search;
    get(url, { token: user?.token })
      .then(json => {
        console.log('response from get rashifal list ', json);
        if (json?.statusCode == 200) {
          setData(json?.data);
        } else {
          toast.error(json?.error);
          setError(true);
        }
      })
      .catch(error => {
        console.log('error while getting project list', error);
      });
  }

  useEffect(() => {
    setColumns([
      {
        name: "Position",
        cell: (row, index) => <>
          <Draggable key={row._id} draggableId={row._id} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <i className="mdi mdi-menu" style={{ fontSize: 20, color: Utils.themeColor }}></i>
              </div>
            )}
          </Draggable>
        </>,
        maxWidth: '50px'
      },
      {
        name: 'Title',
        selector: row => row?.title,
        cell: row => <p title={row?.title}>{row?.title}</p>
      },
      {
        name: 'Title Hindi',
        selector: row => row?.titleHi,
        cell: row => <p title={row?.titleHi}>{row?.titleHi}</p>
      },
      {
        name: 'Created At',
        selector: row => moment(row?.createdAt).format("DD-MMM-YYYY hh:mm a"),
        sortable: true,
        maxWidth: "180px"
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
        maxWidth: '110px'
      },
      {
        cell: (row) => <>
          {/* <Button onClick={() => props?.history.push(`/user/detail/` + row?._id, { state: { type: path } })}
            title={"View in details"}
            style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-eye" style={{ fontSize: 20 }}></span></Button> */}
          {user?.role == "Admin" ?
            <>
              <Button onClick={() => handleUpdateProj(row)}
                title={"Edit"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
              <Button onClick={() => handleDeleteProj(row)}
                title={"Delete"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
            </>
            :
            <>
              {user?.permissions?.length && user?.permissions[4]?.rashifal?.update &&
                <Button onClick={() => handleUpdateProj(row)}
                  title={"Edit"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
              }
              {user?.permissions?.length && user?.permissions[4]?.rashifal?.delete &&
                <Button onClick={() => handleDeleteProj(row)}
                  title={"Delete"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
              }
            </>
          }
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: '100px'
      },
    ])
  }, [data]);

  const handleUpdateProj = (proj) => {
    // console.log('updating proj', proj);
    setCurrentData(proj);
    setIsAddForm(true);
  }

  const handleUpdateStatus = (item) => {
    console.log('updating status proj', item);
    setCurrentData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData({ ...proj, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const onDelete = () => {

    let body = {
      RashifalId: currentData?._id,
      token: user?.token
    }
    let url = "/rashifal/update";
    if (currentData?.actionType == 'Status') {
      body = { ...body, isActive: !currentData?.isActive };
    } else {
      body = { ...body, isDeleted: true };
    }
    console.log('deleted', body);
    // return
    put(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const handleValidSubmit = async (e, v) => {
    if (currentData) {
      const body = {
        ...v,
        token: user?.token,
        RashifalId: currentData?._id
      }
      put("/rashifal/update", body)
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            getData();
            toast.success(res?.message);
            setCurrentData(null);
            setFile(null);
            setIsNavigate("");
            setImg("");
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while updating banner', error);
        })
    } else {
      // if (file) {
      // let image = "";
      // setLoading(true);
      // const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
      // const obj = await urltoFile(fileData, new Date().getTime() + '.png', 'image/png');
      // const temp = await resizeFile(obj);
      // const form = new FormData();
      // form.append("image", temp);
      // const imageRes = await upload("/bannerf/image_upload", form);
      // image = imageRes?.data;
      const body = {
        ...v,
        token: user?.token
      }
      post("/rashifal/add", body)
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            toast.success(res?.messgae);
            setIsAddForm(false);
            getData();
          } else {
            toast.error(res?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          toast.error('Something Went Wrong!');
          console.log('error while adding banner', error);
        })
      // } else
      //   toast.error('Please choose a banner image!')
    }
  }

  const handleDragEnd = (result) => {
    // console.log('dragging item', result);
    // Handle the drag-and-drop logic here
    if (!result.destination) return; // Item was dropped outside the list
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/bannerf/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            data={currentData}
          />
          <Modal isOpen={isAddForm} centered={true} style={{ maxWidth: 540 }}>
            <ModalBody>
              <div className="me-3 position-relative">
                <AvForm
                  className="form-horizontal mt-4"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                  }}
                >
                  <Row>
                    <Col lg={6} md={6}>
                      <div className="form-group mb-4">
                        <AvField
                          name="title"
                          label={"Title"}
                          value={currentData?.title}
                          className="form-control"
                          placeholder={`Enter Title`}
                          type="text"
                          required
                        />
                      </div>
                    </Col>

                    <Col lg={6} md={6}>
                      <div className="form-group mb-4">
                        <AvField
                          name="titleHi"
                          label={"Web URL"}
                          value={currentData?.titleHi}
                          className="form-control"
                          placeholder={`Enter Title in hindi`}
                          type="text"
                          required
                        />
                      </div>
                    </Col>
                    <div className="text-center mt-4 d-flex justify-content-around">
                      <Button type="reset" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                        onClick={() => { setCurrentData(null); setIsAddForm(false) }}
                      >
                        Cancel
                      </Button>
                      {currentData ?
                        <Button type="submit" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                          Update Banner
                        </Button>
                        :
                        <Button type="submit" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                          Add Banner
                        </Button>
                      }
                    </div>
                  </Row>
                </AvForm>
              </div>
            </ModalBody>
          </Modal>
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <div>
                    <CardTitle className="h4">{props.role}</CardTitle>
                    <CardSubtitle className="mb-3">
                      Rashifal you have added before, All the <code>{role} </code>will reflect on your <code>Website and App on the footer.</code>
                    </CardSubtitle>
                  </div>
                  {user?.role == "Admin" ?
                    <div className="text-center" style={{ marginLeft: 'auto' }}>
                      <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                        Add
                      </Button>
                    </div>
                    :
                    <>
                      {user?.permissions?.length && user?.permissions[4]?.rashifal?.write ?
                        <div className="text-center" style={{ marginLeft: 'auto' }}>
                          <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                            Add
                          </Button>
                        </div>
                        : null}
                    </>
                  }
                </div>
                <Row className="my-3">
                  <Col md={8}>
                    <AvForm>
                      <div>
                        <AvField
                          name="search"
                          label="Search"
                          placeholder="Search by title"
                          type="text"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        >
                        </AvField>
                      </div>
                    </AvForm>
                  </Col>
                </Row>
                <Row>
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="table">
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          <DataTable
                            columns={columns}
                            data={data}
                            pagination
                            paginationPerPage={100}
                            paginationRowsPerPageOptions={[50, 70, 80, 100]}
                            customStyles={{
                              headCells: {
                                style: {
                                  color: 'black',
                                  fontWeight: 'bold',
                                  fontSize: 15,
                                  maxWidth: 80
                                },
                              },
                              cells: {
                                style: {
                                  maxWidth: 50
                                }
                              }
                            }}
                          />
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}
export default Table;
