import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  CardSubtitle,
  Modal,
  ModalBody,
  Table
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility';
import { AvField, AvForm } from "availity-reactstrap-validation";
import Loader from "../../components/Loader";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { get, post, put, upload } from '../../helpers/api_helper'
import { toast } from 'react-toastify';
import LoaderSmall from '../../components/LoaderSmall';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import moment from 'moment/moment'

const UTM = props => {
  const { user } = props;
  const [isAddForm, setIsAddForm] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [healthModal, setHealthModal] = useState(false);
  const [image, setImage] = useState(null);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [selectedLang, setSelectedLang] = useState([]);
  const [userListModal, setUserListModal] = useState(false);
  const [userList, setUserList] = useState([]);
  const [userLoading, setUserLoading] = useState(false);
  const [userListType, setUserListType] = useState("Registered");
  const utmMerger = "&referrer=";

  useEffect(() => {
    if (user?.token) {
      getData();
    }
  }, [user]);

  useEffect(() => {
    if (!isAddForm) {
      setCurrentData(null);
      setSelectedLang([]);
    }
  }, [isAddForm]);

  const getData = () => {
    if (!loading) {
      setLoading(true);
      get("/utm/list", { token: user?.token })
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            res?.data?.map((item) => {
              item.isPlaying = false;
            })
            setData(res?.data);
          } else
            toast.error(res?.error);
        })
        .catch(err => {
          toast.error('Something Went Wrong!');
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    setColumns([
      {
        name: 'Title',
        selector: row => row.title,
      },
      {
        name: 'Installed',
        selector: row => row?.installed + row?.registered,
        sortable: true,
        maxWidth: '120px'
      },
      {
        name: 'Registered',
        selector: row => row?.registered,
        cell: row => (
          <>
            <Button className="table_action_btn" onClick={() => row?.registered > 0 ? handleOpenUserList(row, "Registered") : {}}
              title={"Open"}
              style={{ backgroundColor: Utils.themeColor, marginRight: 5, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>{row?.registered}</Button>
          </>
        ),
        sortable: true,
        maxWidth: '130px'
      },
      {
        name: 'Paid',
        selector: row => row?.paid,
        cell: row => (
          <>
            <Button className="table_action_btn" onClick={() => row?.paid > 0 ? handleOpenUserList(row, "Paid") : {}}
              title={"Open"}
              style={{ backgroundColor: Utils.themeColor, marginRight: 5, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>{row?.paid}</Button>
          </>
        ),
        sortable: true,
        maxWidth: '100px'
      },
      {
        name: 'Commitment',
        selector: row => row?.health,
        cell: row => <>
          <Button className="table_action_btn" onClick={() => handleHealth(row)}
            title={"Health"}
            style={{ backgroundColor: "transparent", marginLeft: 5, padding: 5, paddingLeft: 8, paddingRight: 8, }}><span style={{ color: "#000" }}>{row?.health}%</span></Button>
        </>,
        sortable: true,
        maxWidth: '150px'
      },
      {
        cell: (row) => <>
          <Button className="table_action_btn" onClick={() => handleCopy(Utils.APP_ANDROID_URL + utmMerger + row?.link)}
            title={"Copy"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 5, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-content-copy" style={{ color: Utils.textColor }}></span></Button>
          <Button className="table_action_btn" onClick={() => window.open(Utils.APP_ANDROID_URL + utmMerger + row?.link)}
            title={"Open"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 5, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-share" style={{ color: Utils.textColor }}></span></Button>
          <Button className="table_action_btn" onClick={() => handleUpdateProj(row)}
            title={"Edit"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 5, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ color: Utils.textColor }}></span></Button>
          <Button className="table_action_btn" onClick={() => handleDeleteProj(row)}
            title={"Delete"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 5, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ color: Utils.textColor }}></span></Button>
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: '160px'
      },
    ])
  }, [data]);

  const handleValidSubmit = async (e, v) => {
    console.log('values', v);
    let link = `utm_source%3D${(v?.source).split(" ").join("%20")}%26utm_medium%3D${(v?.medium).split(" ").join("%20")}%26utm_term%3D${(v?.compId).split(" ").join("%20")}%26utm_campaign%3D${(v?.medium).split(" ").join("%20")}%26user%3D${(v?.user).split(" ").join("%20")}`;
    let body = {
      ...v,
      link,
      token: user?.token
    }
    if (currentData?._id) {
      setLoading(true);
      body = { ...body, utmId: currentData?._id };
      put("/utm", body)
        .then((res) => {
          setLoading(false);
          if (res?.statusCode == 200) {
            toast.success(res?.message);
            getData();
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          toast.error(err);
          setLoading(false);
        })
    } else {
      try {
        setLoading(true);
        post("/utm", body)
          .then((res) => {
            setLoading(false);
            if (res?.statusCode == 200) {
              toast.success(res?.message);
              getData();
              setIsAddForm(false);
            } else {
              toast.error(res?.error);
            }
          })
          .catch(err => {
            toast.error(err);
            setLoading(false);
          })
      } catch (error) {
        setLoading(false);
        console.log('error while adding social');
        toast.error("Something Went Wrong!");
      }
    }
  }

  const handleUpdateProj = (row) => {
    setImage(row?.image);
    setCurrentData(row);
    if (row?.alterLang?.length)
      setSelectedLang(row?.alterLang);
    setIsAddForm(true);
  }

  const handleDeleteProj = (proj) => {
    setCurrentData(proj);
    setDeleteModal(true);
  }

  const onStatus = () => {
    // console.log('status', currentModalData);
    setLoading(true);
    let body = {
      utmId: currentData?._id,
      isActive: !currentData?.isActive,
      token: user?.token
    }
    let url = `/utm`;
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const onDelete = () => {
    setLoading(true);
    let body = {
      utmId: currentData?._id,
      token: user?.token
    }
    let url = `/utm`;
    if (currentData?.actionType == 'Paid') {
      body = { ...body, isPaid: !currentData?.isPaid };
    } else {
      body = { ...body, isDeleted: true };
    }
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const handleCopy = (link) => {
    navigator.clipboard.writeText(link);
    toast.success("Copied!");
  }

  const handleHealth = (row) => {
    setCurrentData(row);
    setHealthModal(true);
  }

  const handleValidHealth = (e, v) => {
    let body = {
      ...v,
      utmId: currentData?._id,
      token: user?.token
    }
    setLoading(true);
    put("/utm", body)
      .then((res) => {
        setLoading(false);
        if (res?.statusCode == 200) {
          toast.success(res?.message);
          getData();
          setHealthModal(false);
        } else {
          toast.error(res?.error);
        }
      })
      .catch(err => {
        toast.error(err);
        setLoading(false);
      })
  }

  const handleOpenUserList = (row, type) => {
    setCurrentData(row);
    setUserListModal(true);
    getUserList(row?._id, type);
    setUserListType(type);
  }

  const getUserList = (id, type) => {
    setUserLoading(true);
    get("/user/utm?utmId=" + id + "&type=" + type, { token: user?.token })
      .then(res => {
        setUserLoading(false);
        if (res?.statusCode == 200)
          setUserList(res?.data);
        else
          toast.error(res?.message);
      })
      .catch(err => {
        setUserLoading(false);
        console.log('error while getting user list for utm', err);
      })
  }

  // console.log('image changed', image);

  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Content social" />
        <Modal isOpen={isAddForm} centered={true} style={{ maxWidth: 800 }}>
          <ModalBody className="py-3 px-5">
            <h5 className="text-black font-size-20">{currentData?._id ? `Update ` : `Generate New `} UTM</h5>
            <div className="p-2">
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v, 'bank')
                }}
              >
                <Row>
                  <Col lg={12} md={12}>
                    <div className="mb-3">
                      <AvField
                        name="title"
                        label="Title *"
                        className="form-control"
                        placeholder="Enter UTM title"
                        type="text"
                        required
                        value={currentData?.title}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <AvField
                        name="source"
                        label="Source *"
                        className="form-control"
                        placeholder="Enter UTM source"
                        type="text"
                        required
                        value={currentData?.source}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <AvField
                        name="medium"
                        label="Medium *"
                        className="form-control"
                        placeholder="Enter UTM medium"
                        type="text"
                        required
                        value={currentData?.medium}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <AvField
                        name="store"
                        label="Store *"
                        className="form-control"
                        placeholder="Enter store"
                        type="text"
                        required
                        value={currentData?.store}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <AvField
                        name="compId"
                        label="Campaign Id *"
                        className="form-control"
                        placeholder="Enter campaign Id"
                        type="text"
                        required
                        value={currentData?.compId}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <AvField
                        name="user"
                        label="Referrel User *"
                        className="form-control"
                        placeholder="Enter referrel User"
                        type="text"
                        required
                        value={currentData?.user}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <AvField
                        name="health"
                        label="Paid Health"
                        className="form-control"
                        placeholder="Enter paid health percent"
                        type="number"
                        value={currentData?.health}
                      />
                    </div>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Row>
                    <Col md={6}>
                      <button
                        className="btn btn-primary w-100 waves-effect waves-light"
                        style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                        onClick={() => { setIsAddForm(false); }}
                        type="reset"
                      >
                        Cancel
                      </button>
                    </Col>
                    <Col md={6}>
                      <button
                        className="btn btn-primary w-100 waves-effect waves-light"
                        type="submit"
                        style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                      >
                        {currentData ? 'Update' : 'Add'}
                      </button>
                    </Col>
                  </Row>
                </div>
              </AvForm>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={healthModal} centered={true}>
          <ModalBody className="py-3 px-5">
            <h5 className="text-black font-size-20">{currentData?._id ? `Update ` : `Generate New `} UTM Health</h5>
            <div className="p-2">
              <AvForm
                className="form-horizontal"
                onValidSubmit={handleValidHealth}>
                <Row>
                  <Col lg={12} md={12}>
                    <Col md={12}>
                      <div className="mb-3">
                        <AvField
                          name="health"
                          label="Paid Health"
                          className="form-control"
                          placeholder="Enter paid health percent"
                          type="number"
                          value={currentData?.health}
                        />
                      </div>
                    </Col>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Row>
                    <Col md={6}>
                      <button
                        className="btn btn-primary w-100 waves-effect waves-light"
                        style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                        onClick={() => { setHealthModal(false); }}
                        type="reset"
                      >
                        Cancel
                      </button>
                    </Col>
                    <Col md={6}>
                      <button
                        className="btn btn-primary w-100 waves-effect waves-light"
                        type="submit"
                        style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                      >
                        {currentData ? 'Update' : 'Add'}
                      </button>
                    </Col>
                  </Row>
                </div>
              </AvForm>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={userListModal} centered={true} toggle={() => setUserListModal(false)} style={{ maxWidth: 800 }}>
          <ModalBody className="py-3 px-5">
            <h5 className="text-black font-size-20">User list for UTM {currentData?.title}</h5>
            <div className="p-2" style={{ width: "100%" }}>
              {userLoading ?
                <LoaderSmall visible={userLoading} />
                :
                <Row>
                  <Col md={12}>
                    <Table striped>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Mobile</th>
                          <th>Registered On</th>
                          {userListType == "Paid" ?
                            <>
                              <th>Subscribed On</th>
                              <th>Plan</th>
                            </>
                            : null}
                        </tr>
                      </thead>
                      <tbody>
                        {userList.map((row, index) => (
                          <tr key={index}>
                            <td>{row.name}</td>
                            <td>{row?.mobile}</td>
                            <td>{moment(row?.createdAt).format("DD MMM, YYYY hh:mm a")}</td>
                            {userListType == "Paid" ?
                              <>
                                <td>{row?.trans?.length ? moment(row?.trans[0]?.createdAt).format("DD MMM, YYYY hh:mm a") : "-"}</td>
                                <td>{row?.trans?.length ? row?.trans[0]?.title : "-"}</td>
                              </>
                              : null}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              }
            </div>
          </ModalBody>
        </Modal>
        <Card>
          <CardBody>
            <Row>
              <DeleteModal
                show={deleteModal}
                onDeleteClick={() => currentData?.actionType == 'Status' ? onStatus() : onDelete()}
                onCloseClick={() => setDeleteModal(false)}
                actionType={currentData?.actionType}
                data={currentData}
              />
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                      <div>
                        <CardTitle className="h4">Traffic Manager</CardTitle>
                        <CardSubtitle className="mb-3">
                          All UTM Links you have generated before. You can track the installed, Registered and Paid users.
                        </CardSubtitle>
                      </div>
                      {user?.role == 'Admin' ?
                        <div className="text-center" style={{ marginLeft: 'auto' }}>
                          <Button className='btn-text-btn' style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                            Add
                          </Button>
                        </div>
                        :
                        <div className="text-center" style={{ marginLeft: 'auto' }}>
                          {user?.permissions?.length && user?.permissions[18]?.social_media?.write &&
                            <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                              Add
                            </Button>
                          }
                        </div>
                      }
                    </div>
                    <DataTable
                      columns={columns}
                      data={data}
                      pagination
                      customStyles={{
                        headCells: {
                          style: {
                            color: 'black',
                            fontWeight: 'bold',
                            fontSize: 15,
                            maxWidth: 80
                          },
                        },
                        cells: {
                          style: {
                            maxWidth: 50
                          }
                        }
                      }}
                      conditionalRowStyles={[
                        {
                          when: row => {
                            const total = row?.installed + row?.registered;
                            const paidPercentage = (row?.paid * 100) / total;
                            return paidPercentage >= (row?.health * 7) / 10;
                          },
                          style: { backgroundColor: "#00ff0044" } // Green
                        },
                        {
                          when: row => {
                            const total = row?.installed + row?.registered;
                            const paidPercentage = (row?.paid * 100) / total;
                            // console.log("paid percentage", paidPercentage, "return", paidPercentage >= (row?.health * 5) && paidPercentage < (row?.health * 7));
                            return paidPercentage >= (row?.health * 5) && paidPercentage < (row?.health * 7);
                          },
                          style: { backgroundColor: "#ffff0044" } // Yellow
                        },
                        {
                          when: row => {
                            const total = row?.installed + row?.registered;
                            const paidPercentage = (row?.paid * 100) / total;
                            return paidPercentage < (row?.health * 5);
                          },
                          style: { backgroundColor: "#ff000044" } // Red
                        }
                      ]}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <audio id="audio" src={currentAudio} />
      </div>
    </React.Fragment>
  )
}


const mapStatetoProps = state => {
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStatetoProps, {})(UTM)
)