import React, { useEffect, useState, useRef } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody, Label } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';
import { AvField, AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import { toast } from 'react-toastify';
import Utils from '../../Utility';
import { post, get, put, upload, download } from "../../../helpers/api_helper";
import DeleteModal from '../DeleteModal';
import DataTable from 'react-data-table-component';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import { saveAs } from 'file-saver';
import moment from "moment";
// import './Card.scss';

const Table = (props) => {
  const { user, role, currentUser, path, setLoading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [isAddForm, setIsAddForm] = useState(false);
  const [file, setFile] = useState(null);
  const [imageChanged, setImageChanged] = useState(false);
  const [error, setError] = useState(false);
  const imageInput = useRef();
  const cropper = useRef();

  useEffect(() => {
    if (user?.token) {
      getMetas();
    }
  }, [user, path]);

  const getMetas = () => {
    get("/temple/region", { token: user?.token })
      .then(json => {
        console.log('response from get project list', path);
        if (json?.statusCode == 200) {
          setData(json?.data);
        } else {
          toast.error(json?.error);
          setError(true);
        }
      })
      .catch(error => {
        console.log('error while getting project list', error);
      })
  }

  useEffect(() => {
    setColumns([
      {
        name: 'Image',
        cell: (row) => <>
          <img title={'logo'} src={row?.image} style={{ width: 50, height: 'auto' }} />
        </>,
      },
      {
        name: 'Title',
        selector: row => row?.title,
      },
      {
        name: 'Title Hi',
        selector: row => row?.titleHi,
      },
      {
        name: 'Temples',
        selector: row => row.temples?.length,
        sortable: true,
        maxWidth: '120px'
      },
      {
        name: 'Created At',
        selector: row => moment(row.createdAt).format("DD-MMM-YYYY hh:mm a"),
        sortable: true,
        maxWidth: '180px'
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        selector: row => row?.isActive,
        ignoreRowClick: true,
        maxWidth: "120px",
        sortable: true,
      },
      {
        cell: (row) => <>
          {user?.role == "Admin" ?
            <>
              <Button onClick={() => handleUpdateEdit(row)}
                title={"Edit"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
              <Button onClick={() => handleDelete(row)}
                title={"Delete"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
            </>
            :
            <>
              {user?.permissions?.length && user?.permissions[8]?.temple?.update &&
                <Button onClick={() => handleUpdateEdit(row)}
                  title={"Edit"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
              }
              {user?.permissions?.length && user?.permissions[8]?.temple?.delete &&
                <Button onClick={() => handleDelete(row)}
                  title={"Delete"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
              }
            </>}
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: "120px"
      },
    ])
  }, [data]);

  useEffect(() => {
    if (!isAddForm) {
      setCurrentData(null);
      // imageInput.current.value = "";
    }
  }, [isAddForm])

  const handleUpdateEdit = (proj) => {
    console.log('updating proj', proj);
    setCurrentData(proj);
    setFile(proj?.image);
    setImageChanged(false);
    setIsAddForm(true);
  }

  const handleDelete = (proj, metaType) => {
    console.log('updating proj', proj);
    setCurrentData({ ...proj, metaType, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const handleUpdateStatus = (item) => {
    console.log('updating proj', item);
    setCurrentData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      regionId: currentData?._id,
      isDeleted: true,
      token: user?.token
    }
    put(`/temple/region`, body)
      .then(json => {
        console.log('response from delete user', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getMetas();
          setDeleteModal(false);
          setCurrentData(null);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const onStatus = () => {
    console.log('status', currentData);
    setLoading(true);
    let body = {
      regionId: currentData?._id,
      isActive: !currentData?.isActive,
      token: user?.token
    }
    let url = `/temple/region`;
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getMetas();
          setDeleteModal(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1800,
        600,
        "webp",
        60,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handleValidSubmit = async (e, values) => {
    // console.log('values', values, file);
    setLoading(true);
    let body = {
      ...values,
      token: user?.token
    }
    if (currentData) {
      body = { ...body, regionId: currentData?._id }
      if (imageChanged) {
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        let obj = await urltoFile(fileData, new Date().getTime() + '.png', 'image/png')
        const image = await resizeFile(obj);
        const form = new FormData();
        form.append("image", image);
        let res = await upload("/temple/image_upload", form)
        if (res?.statusCode == 200) {
          body = {
            ...body,
            image: res?.data,
          }
        }
        put("/temple/region", body)
          .then(json => {
            console.log('response from add state', json);
            setLoading(false);
            if (json.statusCode == 200) {
              toast.success(json?.message);
              getMetas();
              setFile(null);
              setIsAddForm(false);
              setCurrentData(null);
            } else {
              toast.error(json?.error);
            }
          })
          .catch(error => {
            setLoading(false);
            console.log('error while uploading image', error);
            toast.error(error);
          })
      } else {
        const body = {
          ...values,
          brandId: currentData?._id,
          token: user?.token
        }
        put("/temple/region", body)
          .then(json => {
            console.log('response from add state', json);
            setLoading(false);
            if (json.statusCode == 200) {
              toast.success(json?.message);
              getMetas();
              setFile(null);
              setIsAddForm(false);
              setCurrentData(null);
            } else {
              toast.error(json?.error);
            }
          })
          .catch(error => {
            setLoading(false);
            console.log('error while uploading image', error);
            toast.error(error);
          });
      }
    } else {
      const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
      let obj = await urltoFile(fileData, new Date().getTime() + '.png', 'image/png')
      const image = await resizeFile(obj);
      const form = new FormData();
      form.append("image", image);
      let res = await upload("/temple/image_upload", form)
      if (res?.statusCode == 200) {
        body = {
          ...body,
          image: res?.data,
        }
      }
      post("/temple/region", body)
        .then(json => {
          console.log('response from add state', json);
          setLoading(false);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getMetas();
            setFile(null);
            setIsAddForm(false);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while uploading image', error);
          toast.error(error);
        })
    }
  }

  const onChangeFile = (e) => {
    console.log('getting event on input img', e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
    setImageChanged(true);
  }

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => currentData?.actionType == 'Status' ? onStatus() : onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            actionType={currentData?.actionType}
            data={currentData}
          />
          {/* Add or Update depart form */}
          <Modal isOpen={isAddForm} toggle={() => { setIsAddForm(false); setFile(null) }} centered={true}>
            <ModalBody className="py-3 px-5">
              <h5 className="text-black font-size-20">{currentData ? `Update ${role}!` : `Add New ${role}!`}</h5>
              <div className="p-2">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v, 'bank')
                  }}
                >
                  <Row>
                    <Col lg={12} md={12}>
                      <div className="mb-3">
                        <AvField
                          name="title"
                          label="Title *"
                          className="form-control"
                          placeholder="Enter title"
                          type="text"
                          required
                          value={currentData?.title}
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="mb-3">
                        <AvField
                          name="titleHi"
                          label="Title Hindi *"
                          className="form-control"
                          placeholder="Enter title"
                          type="text"
                          required
                          value={currentData?.titleHi}
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="mb-3 mt-2">
                        {file ?
                          <>
                            <CardTitle >Image</CardTitle>
                            <div style={{ flexDirection: 'row', position: 'relative', cursor: 'pointer', marginTop: 20 }}>
                              {currentData?.image ?
                                <img src={file} style={{ width: 100, height: 'auto' }} />
                                :
                                <Cropper
                                  style={{ height: 'auto', width: 400 }}
                                  aspectRatio={1 / 1}
                                  preview=".img-preview"
                                  guides={true}
                                  src={file}
                                  ref={cropper}
                                />
                              }
                              <i className="mdi mdi-close" style={{
                                color: 'white', backgroundColor: Utils.themeColor, fontSize: 20,
                                position: 'absolute', top: -16, right: -28, borderRadius: 15, width: 30, height: 30, textAlign: 'center'
                              }}
                                onClick={() => { setFile(null); if (currentData) setCurrentData({ ...currentData, image: undefined }) }}
                              />
                            </div>
                          </>
                          :
                          <AvField
                            name="fileInput"
                            label="Image"
                            className="form-control"
                            placeholder="upload bank logo"
                            type="file"
                            onChange={e => onChangeFile(e)}
                            required
                            multiple={false}
                            ref={imageInput}
                            accept="image/png, image/jpeg"
                          />
                        }
                      </div>
                    </Col>
                  </Row>
                  <div className="mt-4">
                    <Row>
                      <Col md={6}>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                          onClick={() => { setIsAddForm(false); setFile(null) }}
                          type="reset"
                        >
                          Cancel
                        </button>
                      </Col>
                      <Col md={6}>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                          style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                        >
                          {currentData ? 'Update' : 'Add'}
                        </button>
                      </Col>
                    </Row>
                  </div>
                </AvForm>
              </div>
            </ModalBody>
          </Modal>

          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <div>
                    <CardTitle className="h4">{props.role}s</CardTitle>
                    <CardSubtitle className="mb-3">
                      {role} you have added before, All the <code>Products </code> will be added according to {role}.
                    </CardSubtitle>
                  </div>
                  <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                    {user?.role == "Admin" ?
                      <div className="text-center" style={{ marginLeft: 'auto' }}>
                        <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                          Add
                        </Button>
                      </div>
                      :
                      <>
                        {user?.permissions?.length && user?.permissions[8]?.temple?.write ?
                          <div className="text-center" style={{ marginLeft: 'auto' }}>
                            <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                              Add
                            </Button>
                          </div>
                          : null}
                      </>
                    }
                  </div>
                </div>
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  customStyles={{
                    headCells: {
                      style: {
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: 15,
                        maxWidth: 80
                      },
                    },
                    cells: {
                      style: {
                        maxWidth: 50
                      }
                    }
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    </React.Fragment >
  )
}

export default Table;
