import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button,
    Modal, ModalBody
} from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import Utils from '../Utility'

import avatar from "../../assets/images/small/sample.png"
// actions
import { post, upload, put, get } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";


const AddProperty = props => {
    const { user, data, setLoading, role } = props;
    const [file, setFile] = useState(null);
    const [files, setFiles] = useState([]);
    const [imageChanged, setImageChanged] = useState(false);
    const imageInput = useRef();
    const imageInputMore = useRef();
    const cropper = useRef();
    const [brand, setBrand] = useState([]);
    const [cat, setCat] = useState([]);
    const [descContent, setDescContent] = useState(null);
    const [desc, setDesc] = useState("");
    const [cropperModal, setCropperModal] = useState(false);
    const [selectedMonths, setSelectedMonths] = useState([]);
    const [selectedExp, setSelectedExp] = useState([]);
    // const [selectedInsp, setSelectedInsp] = useState([]);
    const [selectedWho, setSelectedWho] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [moreFile, setMoreFiles] = useState([]);
    const [currentFile, setCurrentFile] = useState(null);
    const [currentFileType, setCurrentFileType] = useState('file');
    const [currentData, setCurrentData] = useState(null);
    // console.log('props on company', props?.data);

    async function handleValidSubmit(event, values) {
        try {
            console.log('values from form', Boolean(values?.isVeg));
            if (files?.length > 0) {
                setLoading(true);
                if (data) {
                    if (files?.length > 0) {
                        let urls = [];
                        const promise = await Promise.all(
                            files?.map(async (item) => {
                                if (item?.length > 85) {
                                    const obj = await urltoFile(item, new Date().getTime() + '.png', 'image/png');
                                    const temp = await resizeFile(obj);
                                    const form = new FormData();
                                    form.append("image", temp);
                                    const image = await upload("/store/image_upload", form);
                                    urls.push(image?.data);
                                } else
                                    urls.push(item);
                            })
                        );
                        console.log(promise);
                        console.log('url after upload images', urls);
                        let body = {
                            productId: data?._id,
                            ...values,
                            desc: desc,
                            images: urls,
                            token: user?.token,
                        }
                        let url = "/store/product/update";
                        console.log('body after upload images', body);
                        put(url, body)
                            .then((json) => {
                                console.log('response from adding comp', json);
                                setLoading(false);
                                if (json.statusCode == 200) {
                                    console.log('property added');
                                    toast.success(json?.message);
                                    props.close(false);
                                } else {
                                    toast.error(json?.error);
                                }
                            })
                            .catch(error => {
                                console.error('error while adding oppor', error);
                                toast.error(JSON.stringify(error));
                                setLoading(false);
                            });
                    }
                } else {
                    let urls = [];
                    const promise = await Promise.all(
                        files?.map(async (item) => {
                            const obj = await urltoFile(item, new Date().getTime() + '.png', 'image/png');
                            const temp = await resizeFile(obj);
                            const form = new FormData();
                            form.append("image", temp);
                            const image = await upload("/store/image_upload", form);
                            urls.push(image?.data);
                        })
                    );
                    console.log(promise);
                    console.log('url after upload images', urls);
                    let body = {
                        ...values,
                        desc: desc,
                        token: user?.token,
                        images: urls
                    }
                    let url = "/store/product/add";
                    console.log('body after upload images', body);
                    post(url, body)
                        .then((json) => {
                            console.log('response from adding product', json);
                            setLoading(false);
                            if (json.statusCode == 200) {
                                console.log('product added');
                                toast.success(json?.message);
                                props.close(false);
                            } else {
                                toast.error(json?.error);
                            }
                        })
                        .catch(error => {
                            console.error('error while adding oppor', error);
                            toast.error(JSON.stringify(error));
                            setLoading(false);
                        })

                }
            } else {
                toast.error('Add atleast one image of the product!');
            }
        } catch (error) {
            setLoading(false);
            console.log('error while submitting API', error);
        }
    }

    useEffect(() => {
        if (data) {
            if (data?.desc) {
                const contentBlock = htmlToDraft(data?.desc);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const editorState = EditorState.createWithContent(contentState);
                    setDescContent(editorState);
                    setDesc(data?.terms);
                }
            }
            if (data?.images)
                setFiles(data?.images);
        }
    }, [data]);

    useEffect(() => {
        if (user) {
            get('/store/brand/list', { token: user?.token })
                .then(res => {
                    setBrand(res?.data);
                })
            get('/store/category/list', { token: user?.token })
                .then(res => {
                    setCat(res?.data);
                })
        }
    }, [user]);

    const onChangeFile = async (e, type) => {
        console.log('on change file', e.target);
        setCurrentFile(URL.createObjectURL(e.target.files[0]));
        setCropperModal(true);
    }

    const removeImage = (i) => {
        let data = Object.assign([], files);
        data.splice(i, 1);
        setFiles(data);
    }

    const onEditorStateChange = (editorState) => {
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setDesc(html);
        setDescContent(editorState);
    }

    const cropImage = (e, v) => {
        setLoading(true);
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        let data = Object.assign([], files);
        data.push(fileData);
        setFiles(data);
        setCropperModal(false);
        setLoading(false);
    }

    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1800,
                600,
                "webp",
                60,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    const handleMultiSelect = (val, type) => {
        if (val == "")
            return;
        if (type == 'month') {
            let data = Object.assign([], selectedMonths);
            const found = data.findIndex(x => x == val);
            if (found == -1) {
                data.push(val);
                setSelectedMonths(data);
            }
        }
        else if (type == 'exp') {
            let data = Object.assign([], selectedExp);
            const found = data.findIndex(x => x == val);
            if (found == -1) {
                data.push(val);
                setSelectedExp(data);
            }
        }
        else if (type == 'who') {
            let data = Object.assign([], selectedWho);
            const found = data.findIndex(x => x == val);
            if (found == -1) {
                data.push(val);
                setSelectedWho(data);
            }
        }
        else if (type == 'location') {
            let data = Object.assign([], selectedLocation);
            const found = data.findIndex(x => x == val);
            if (found == -1) {
                data.push(val);
                setSelectedLocation(data);
            }
        }
    }

    const handleRemoveMulti = (index, type) => {
        if (type == 'month') {
            let data = Object.assign([], selectedMonths);
            data.splice(index, 1);
            setSelectedMonths(data);
        }
        else if (type == 'exp') {
            let data = Object.assign([], selectedExp);
            data.splice(index, 1);
            setSelectedExp(data);
        }
        else if (type == 'who') {
            let data = Object.assign([], selectedWho);
            data.splice(index, 1);
            setSelectedWho(data);
        }
        else if (type == 'location') {
            let data = Object.assign([], selectedLocation);
            data.splice(index, 1);
            setSelectedLocation(data);
        }
    }

    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg={11} md={11}>
                                        <Row>
                                            {files?.map((item, index) => (
                                                <Col lg={2} md={2} style={{ marginRight: '2rem' }}>
                                                    <div className="me-3 position-relative" style={{ width: 180, height: 180 }}>
                                                        <img
                                                            src={item ? item : avatar}
                                                            alt=""
                                                            style={{ width: '100%', height: '100%' }}
                                                        />
                                                        <div style={{ position: 'absolute', height: 40, width: 40, right: -20, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                            onClick={(e) => removeImage(index)}
                                                        >
                                                            <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))}

                                            {files?.length < 4 &&
                                                <Col lg={2} md={2}>
                                                    <div className="me-3 position-relative" style={{ width: 180, height: 180 }}>
                                                        <div className='image_placeholder' style={{ width: '100%', height: '100%' }}
                                                            onClick={(e) => imageInput.current.click(e)}
                                                        >
                                                            <button style={{ backgroundColor: Utils.themeColor, borderRadius: 5, color: '#fff', padding: '5px 10px', border: 'none' }}> + Add</button>
                                                            <div style={{ position: 'absolute', height: 40, width: 40, right: -20, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                            >
                                                                <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                                                            </div>
                                                            <input type="file" id="file"
                                                                ref={imageInput}
                                                                onChange={(e) => onChangeFile(e, 'file')}
                                                                style={{ display: "none" }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                            }
                                        </Row>
                                    </Col>
                                    <Col lg={1} md={1}>
                                        <div className="text-center" style={{ marginLeft: 'auto' }}>
                                            <Button color="primary" onClick={() => { props.setCurrentData(null); props?.close(false) }} style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                                                Close
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* Image cropper modal */}
                <Modal isOpen={cropperModal} toggle={() => { setCropperModal(false); setFile(null) }} centered={true}>
                    <ModalBody className="py-3 px-5">
                        <h5 className="text-black font-size-20">Crop Image</h5>
                        <div className="p-2">
                            <AvForm
                                className="form-horizontal"
                                onValidSubmit={(e, v) => {
                                    cropImage()
                                }}
                            >
                                <div className="mb-3 mt-2">
                                    <Cropper
                                        style={{ height: 'auto', width: 400 }}
                                        aspectRatio={1 / 1}
                                        preview=".img-preview"
                                        guides={true}
                                        src={currentFile}
                                        ref={cropper}
                                    />
                                </div>
                                <div className="mt-4">
                                    <Row>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                style={{ backgroundColor: Utils.themeColor }}
                                                onClick={() => { setCropperModal(false); setFile(null) }}
                                                type="reset"
                                            >
                                                Cancel
                                            </button>
                                        </Col>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                type="submit"
                                                style={{ backgroundColor: Utils.themeColor }}
                                            >
                                                Submit
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </AvForm>
                        </div>
                    </ModalBody>
                </Modal>

                <h4 className="card-title mb-4">{data ? 'Update ' + role : 'Add a ' + role}</h4>

                <Card>
                    <CardBody>
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                handleValidSubmit(e, v)
                            }}
                        >
                            <Row>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="brandId"
                                            label="Brand*"
                                            className="form-control"
                                            type="select"
                                            value={data?.brandId}
                                            required
                                        >
                                            <option value={""} >Select Brand</option>
                                            {brand?.map((item) => (
                                                <option value={item?._id} >{item?.name}</option>
                                            ))}
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="catId"
                                            label="Category*"
                                            className="form-control"
                                            type="select"
                                            value={data?.catId}
                                            required
                                        >
                                            <option value={""} >Select Category</option>
                                            {cat?.map((item) => (
                                                <option value={item?._id} >{item?.name}</option>
                                            ))}
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="name"
                                            label="Title*"
                                            value={data?.name}
                                            className="form-control"
                                            placeholder="Enter Title"
                                            type="text"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="utm"
                                            label="UTM Source*"
                                            className="form-control"
                                            type="url"
                                            value={data?.utm}
                                            required
                                        >
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="price"
                                            label="Price*"
                                            value={data?.price}
                                            className="form-control"
                                            type="number"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="offPrice"
                                            label="off Price*"
                                            className="form-control"
                                            value={data?.offPrice}
                                            type="number"
                                            required
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {/* terms */}
                                <div className="form-group mb-4">
                                    {/* How it works */}
                                    <label>Description*</label>
                                    <Editor
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={(e) => onEditorStateChange(e)}
                                        editorState={descContent}
                                    />
                                </div>
                            </Row>
                            {/* <AvField name="idx" value={user?.company._id} type="hidden" /> */}
                            <div className="text-center mt-4">
                                {data ?
                                    <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                        Update {role}
                                    </Button>
                                    :
                                    <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                        Add {role}
                                    </Button>
                                }
                            </div>
                        </AvForm>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}

AddProperty.propTypes = {
    error: PropTypes.any,
    success: PropTypes.any,
    companyError: PropTypes.func,
    companySuccess: PropTypes.func,
    createCompany: PropTypes.func,
    updateCompany: PropTypes.func
}

const mapStatetoProps = state => {
    const { user } = state.Profile;
    return { user }
}

export default withRouter(
    connect(mapStatetoProps, {})(AddProperty)
)
