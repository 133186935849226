import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button,
    Modal, ModalBody, Label
} from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import Utils from '../Utility'

import avatar from "../../assets/images/small/sample.png"
// actions
import { post, upload, put, get } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";


const AddProperty = props => {
    const { user, data, setLoading, role } = props;
    const [file, setFile] = useState(null);
    const [image, setImage] = useState(null);
    const [image2, setImage2] = useState(null);
    const [currentImageType, setCurrentImageType] = useState(false);
    const imageInput = useRef();
    const cropper = useRef();
    const [currentData, setCurrentData] = useState(null);
    const [cropperModal, setCropperModal] = useState(false);
    const [regions, setRegion] = useState([]);
    const [authors, setAuthors] = useState([]);
    const [audioFile, setAudioFile] = useState(undefined);
    // console.log('props on company', props?.data);

    useEffect(() => {
        if (data) {
            if (data?.image)
                setImage(data?.image);
            if (data?.godImage)
                setImage2(data?.godImage);
        }
    }, [data]);

    useEffect(() => {
        if (user) {
            get('/temple/region', { token: user?.token })
                .then(res => {
                    setRegion(res?.data);
                })
        }
    }, [user]);

    const onChangeFile = async (e, type) => {
        console.log('on change file', e.target);
        setFile(URL.createObjectURL(e.target.files[0]));
        setCropperModal(true);
    }

    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1800,
                600,
                "webp",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    const cropImage = (e, v) => {
        setLoading(true);
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        if (currentImageType == 'image')
            setImage(fileData);
        else
            setImage2(fileData);
        setCropperModal(false);
        setLoading(false);
    }

    const handleValidSubmit = async (e, v) => {
        console.log('values', v);
        if (!image) {
            toast.error("select Temple image!");
            return;
        }
        if (!image2) {
            toast.error("select Temple God's image!");
            return;
        }
        setLoading(true);
        let body = {
            ...v,
            token: user?.token
        }
        if (image?.length > 90) {
            const obj = await urltoFile(image, new Date().getTime() + '.png', 'image/png');
            const tempObj = await resizeFile(obj);
            const form = new FormData();
            form.append("image", tempObj);
            const uploadRes = await upload("/temple/image_upload", form);
            if (uploadRes?.statusCode == 200)
                body = { ...body, image: uploadRes?.data };
        }
        if (image2?.length > 90) {
            const obj = await urltoFile(image2, new Date().getTime() + '.png', 'image/png');
            const tempObj = await resizeFile(obj);
            const form = new FormData();
            form.append("image", tempObj);
            const uploadRes = await upload("/temple/image_upload", form);
            if (uploadRes?.statusCode == 200)
                body = { ...body, godImage: uploadRes?.data };
        }
        if (data) {
            body = { ...body, templeId: data?._id }
            put("/temple", body)
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        toast.success(res?.message);
                        props?.close(false);
                    } else {
                        toast.error(res?.error);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    console.log('err while adding story', err);
                    toast.error('Something Went Wrong!')
                })
        } else {
            post("/temple", body)
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        toast.success(res?.message);
                        props?.close(false);
                    } else {
                        toast.error(res?.error);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    console.log('err while adding story', err);
                    toast.error('Something Went Wrong!')
                })
        }
    }

    return (
        <React.Fragment>
            <div>
                <Row>
                    <input type="file" id="file"
                        ref={imageInput}
                        onChange={(e) => onChangeFile(e, 'file')}
                        style={{ display: "none" }} />
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg={11} md={11}>
                                        <Row>
                                            {image ?
                                                <Col md={4} style={{ marginRight: '2rem' }}>
                                                    <div className="me-3 position-relative" style={{ width: 280, height: 233 }}>
                                                        <img
                                                            src={image ? image : avatar}
                                                            alt=""
                                                            style={{ width: '100%', height: '100%' }}
                                                        />
                                                        <div style={{ position: 'absolute', height: 40, width: 40, right: -20, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                            onClick={(e) => { setFile(null); setImage(null) }}
                                                        >
                                                            <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                                :
                                                <Col md={4}>
                                                    <div className="me-3 position-relative" style={{ width: 280, height: 233 }}>
                                                        <div className='image_placeholder' style={{ width: '100%', height: '100%' }}
                                                            onClick={(e) => { setCurrentImageType("image"); imageInput.current.click(e) }}
                                                        >
                                                            <button style={{ backgroundColor: Utils.themeColor, borderRadius: 5, color: '#fff', padding: '5px 10px', border: 'none' }}> + Add</button>
                                                            <div style={{ position: 'absolute', height: 40, width: 40, right: -20, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                            >
                                                                <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                                                            </div>

                                                        </div>
                                                    </div>
                                                </Col>
                                            }
                                            {image2 ?
                                                <Col lg={2} md={2} style={{ marginRight: '2rem' }}>
                                                    <div className="me-3 position-relative" style={{ width: 200, height: 200 }}>
                                                        <img
                                                            src={image2 ? image2 : avatar}
                                                            alt=""
                                                            style={{ width: '100%', height: '100%' }}
                                                        />
                                                        <div style={{ position: 'absolute', height: 40, width: 40, right: -20, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                            onClick={(e) => { setFile(null); setImage2(null) }}
                                                        >
                                                            <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                                :
                                                <Col lg={2} md={2}>
                                                    <div className="me-3 position-relative" style={{ width: 200, height: 200 }}>
                                                        <div className='image_placeholder' style={{ width: '100%', height: '100%' }}
                                                            onClick={(e) => { setCurrentImageType("god"); imageInput.current.click(e) }}
                                                        >
                                                            <button style={{ backgroundColor: Utils.themeColor, borderRadius: 5, color: '#fff', padding: '5px 10px', border: 'none' }}> + Add</button>
                                                            <div style={{ position: 'absolute', height: 40, width: 40, right: -20, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                            >
                                                                <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            }
                                        </Row>
                                    </Col>
                                    <Col lg={1} md={1}>
                                        <div className="text-center" style={{ marginLeft: 'auto' }}>
                                            <Button color="primary" onClick={() => { props.setCurrentData(null); props?.close(false) }} style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                                                Close
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* Image cropper modal */}
                <Modal isOpen={cropperModal} toggle={() => { setCropperModal(false); setFile(null) }} centered={true}>
                    <ModalBody className="py-3 px-5">
                        <h5 className="text-black font-size-20">Crop Image</h5>
                        <div className="p-2">
                            <AvForm
                                className="form-horizontal"
                                onValidSubmit={(e, v) => {
                                    cropImage()
                                }}
                            >
                                <div className="mb-3 mt-2">
                                    <Cropper
                                        style={{ height: 'auto', width: 400 }}
                                        aspectRatio={currentImageType == 'image' ? 480 / 400 : 1 / 1}
                                        preview=".img-preview"
                                        guides={true}
                                        src={file}
                                        ref={cropper}
                                    />
                                </div>
                                <div className="mt-4">
                                    <Row>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                style={{ backgroundColor: Utils.themeColor }}
                                                onClick={() => { setCropperModal(false); setFile(null) }}
                                                type="reset"
                                            >
                                                Cancel
                                            </button>
                                        </Col>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                type="submit"
                                                style={{ backgroundColor: Utils.themeColor }}
                                            >
                                                Submit
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </AvForm>
                        </div>
                    </ModalBody>
                </Modal>

                <h4 className="card-title mb-4">{data ? 'Update ' + role : 'Add a ' + role}</h4>

                <Card>
                    <CardBody>
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                handleValidSubmit(e, v)
                            }}
                        >
                            <Row>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="title"
                                            label="Title *"
                                            value={data?.title}
                                            className="form-control"
                                            placeholder="Enter Title in English"
                                            type="text"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="regionId"
                                            label="Region *"
                                            className="form-control"
                                            type="select"
                                            value={data?.regionId}
                                            required
                                        >
                                            <option value={""} >Select Region</option>
                                            {regions?.map((item) => (
                                                <option value={item?._id} >{item?.titleHi} ({item?.title})</option>
                                            ))}
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="godName"
                                            label="God *"
                                            placeholder="Dedicated God's Name"
                                            className="form-control"
                                            type="text"
                                            value={data?.godName}
                                            required
                                        >
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="open"
                                            label="Opening time *"
                                            className="form-control"
                                            placeholder="Enter temple open schedule"
                                            type="text"
                                            value={data?.open}
                                            required
                                        >
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="lat"
                                            label="Latitude *"
                                            className="form-control"
                                            placeholder="Enter temple location latitude"
                                            type="number"
                                            value={data?.lat}
                                            required
                                        >
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="long"
                                            label="Longitude *"
                                            className="form-control"
                                            placeholder="Enter temple location longitude"
                                            type="number"
                                            value={data?.long}
                                            required
                                        >
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="address"
                                            label="Address *"
                                            className="form-control"
                                            placeholder="Enter temple address"
                                            type="text"
                                            value={data?.address}
                                            required
                                        >
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="desc"
                                            label="Description *"
                                            className="form-control"
                                            placeholder="Enter temple description"
                                            type="textarea"
                                            value={data?.desc}
                                            rows={6}
                                            required
                                        >
                                        </AvField>
                                    </div>
                                </Col>
                            </Row>
                            {/* <AvField name="idx" value={user?.company._id} type="hidden" /> */}
                            <div className="text-center mt-4">
                                {data ?
                                    <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                        Update {role}
                                    </Button>
                                    :
                                    <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                        Add {role}
                                    </Button>
                                }
                            </div>
                        </AvForm>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}

AddProperty.propTypes = {
    error: PropTypes.any,
    success: PropTypes.any,
    companyError: PropTypes.func,
    companySuccess: PropTypes.func,
    createCompany: PropTypes.func,
    updateCompany: PropTypes.func
}

const mapStatetoProps = state => {
    const { user } = state.Profile;
    return { user }
}

export default withRouter(
    connect(mapStatetoProps, {})(AddProperty)
)
