import React, { useEffect, useState, useRef } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody, Label } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';
import { AvField, AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import { toast } from 'react-toastify';
import Utils from '../Utility';
import { post, get, put, upload } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import UiDropdown from "../Ui/UiDropdown";
import SearchableDropdown from "../Ui/UiSearchableDropdown";

// import './Card.scss';

const Table = (props) => {
  const { user, role, currentData, setIsTable, singleId, deviceId, setSingleId, path, setLoading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [proData, setProData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentModalData, setCurrentModalData] = useState(null);
  const [isAddForm, setIsAddForm] = useState(false);
  const [file, setFile] = useState(null);
  const [imageChanged, setImageChanged] = useState(false);
  const imageInput = useRef();
  const [logo, setLogo] = useState(null);
  const [logoChanged, setLogoChanged] = useState(false);
  const [selectedPros, setSelectedPros] = useState([]);
  const logoInput = useRef();
  const cropper = useRef();
  const logoCropper = useRef();

  useEffect(() => {
    if (user) {
      getMetas();
    }
  }, [user, path]);

  const getMetas = () => {
    post("/screen/analytic", { token: user?.token, screenId: singleId, deviceId: deviceId })
      .then(res => {
        // console.log('response from get properties', res);
        if (res?.statusCode == 200) {
          if (deviceId) {
            setData(res?.data?.screens[0]?.events);
            setCurrentUser(res?.data?.user);
          } else {
            setData(res?.data[0]?.events);
            setCurrentUser(res?.data[0]);
          }
        }
      })
      .catch(error => {
        console.log('error while getting properties on hotels', error);
      });
  }

  console.log('props on screen table', props);

  return (
    <React.Fragment>
      <div className="">
        <Row>
          {/* <DeleteModal
            show={deleteModal}
            onDeleteClick={() => currentModalData?.actionType == 'Status' ? onStatus() : onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            actionType={currentModalData?.actionType}
            data={currentModalData}
          /> */}
          {/* Add or Update depart form */}
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardSubtitle className="">
                  {deviceId ?
                    <Row lg={12}>
                      <Col lg={2} style={{ width: 120 }}>
                        <img src={currentUser?.profile_picture ? currentUser?.profile_picture : require("../../assets/images/om.png")} style={{ width: 100, height: 100, borderRadius: 50 }} />
                      </Col>
                      <Col lg={3} style={{ alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', display: 'flex' }}>
                        <p style={{ fontWeight: 'bold', fontSize: 20, marginBottom: 0 }}>{currentUser?.name}</p>
                        {currentUser?.city &&
                          <p style={{}}>({currentUser?.city})</p>
                        }
                      </Col>
                      <Col lg={3} style={{ alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', display: 'flex' }}>
                        <p style={{ marginBottom: 0 }}>{currentUser?.email}</p>
                        {currentUser?.mobile &&
                          <p style={{}}>{currentUser?.mobile}</p>
                        }
                      </Col>
                    </Row>
                    :
                    <Row lg={12}>
                      <Col lg={2} style={{ width: 120 }}>
                        <img src={currentUser?.profile_picture ? currentUser?.profile_picture : require("../../assets/images/om.png")} style={{ width: 100, height: 100, borderRadius: 50 }} />
                      </Col>
                      <Col lg={4} style={{ alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', display: 'flex' }}>
                        <p style={{ fontWeight: 'bold', fontSize: 20, marginBottom: 0 }}>{currentUser?.title}</p>
                        <p style={{}}>( Visitors: {currentUser?.navigators[0]?.count} )</p>
                      </Col>
                    </Row>
                  }
                </CardSubtitle>
              </CardBody>
            </Card>
          </Col>
          {data?.map((item) => (
            <Col lg={3}>
              <Card>
                <CardBody>
                  <div style={{ marginLeft: 'auto' }}>
                    {currentUser?.for == 'wallpaper' ?
                      <img src={"https://eeasy.s3.ap-south-1.amazonaws.com/balaji/app" + item?._id} style={{ width: '100%' }} />
                      :
                      <h6>{item?._id}</h6>
                    }
                    {deviceId ?
                      <div style={{ paddingLeft: 10, backgroundColor: '#82b74b', color: '#fff', marginBottom: 10, borderRadius: 5 }}>
                        <p className="mb-0 p-2">{item?.registered} Times</p>
                      </div>
                      :
                      <div style={{ marginTop: 20 }}>
                        {item?.registered > 0 &&
                          <div style={{ paddingLeft: 10, backgroundColor: '#82b74b', color: '#fff', marginBottom: 10, borderRadius: 5 }}>
                            <p className="mb-0 p-2">Registered : {item?.registered}</p>
                          </div>
                        }
                        {item?.unregistered > 0 &&
                          <div style={{ paddingLeft: 10, backgroundColor: '#c94c4c', color: '#fff', marginBottom: 10, borderRadius: 5 }}>
                            <p className="mb-0 p-2">Unregistered : {item?.unregistered}</p>
                          </div>
                        }
                        {item?.unregistered + item?.registered > 0 &&
                          <div style={{ paddingLeft: 10, backgroundColor: '#36486b', color: '#fff', marginBottom: 10, borderRadius: 5 }}>
                            <p className="mb-0 p-2">Total : {item?.unregistered + item?.registered}</p>
                          </div>
                        }
                      </div>
                    }
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>

      </div>

    </React.Fragment >
  )
}

export default Table;
