import React from "react"
import { Redirect } from "react-router-dom"
import Dashboard from "../pages/Dashboard/index";
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Login1 from "../pages/AuthenticationInner/Login"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"
import UserProfile from "../pages/Authentication/user-profile"
import Users from "../pages/Users";
import UnregisteredUsers from "../pages/Users/Unregistered";
import UnregisteredToRegistered from "../pages/Users/UnregisteredToRegistered";
import RegisteredUsers from "../pages/Users/Registered";
import RegisteredToPaid from "../pages/Users/RegisteredToPaid";
import Paid from "../pages/Users/Paid";
import UserSales from "../pages/UserSales";
import Screen from "../pages/Screen";
import RamNaam from '../pages/RamNaam';
import Banner from '../pages/Banner';
//blog
import Tags from "../pages/Tags";
import BlogCat from "../pages/Blog/category";
import Blog from "../pages/Blog";

import NotificationData from "../pages/NotificationData";
import Setting from "../pages/Setting";
import Brand from '../pages/Store/Brand';
import Category from '../pages/Store/Category';
import Product from "../pages/Store/Product";
import Notification from "../pages/Notification";
import Property from "../pages/Property";
import Anayltics from "../pages/Anayltics";
import Ads from "../pages/Ads";
import StoryCat from "../pages/Story/Category";
import StoryAuth from "../pages/Story/Author";
import Story from "../pages/Story/Story";
import Popup from "../pages/Popup";
import ShareData from "../pages/ShareData";
import Mantra from "../pages/Mantra";
import WallCate from "../pages/Wallpaper/Category";
import Wallpaper from "../pages/Wallpaper";
import Admin from "../pages/admin";
import RaamWinner from "../pages/RaamWinner";
import Enquiry from "../pages/Enquiry";
import TempleRegion from "../pages/Temples/Region";
import Temple from "../pages/Temples/Temple";
import Plans from "../pages/Plans";
import Coupon from "../pages/Coupon";
import CalMonth from "../pages/Calender/Month";
import CalEvent from "../pages/Calender/Event";
import CalCat from "../pages/Calender/Category";
import WishCat from "../pages/Wishes/Category";
import WishImages from "../pages/Wishes";
import WishMessage from "../pages/Wishes/Message";
import BannerFooter from "../pages/BannerFooter";
import Rashifal from "../pages/Rashifal";
import RashifalName from "../pages/Rashifal/RashiName";
import SubAdmin from "../pages/SubAdmin";
import Store from "../pages/ImageStore";
import Whatsapp from "../pages/Whatsapp";
import SocialMedia from "../pages/SocialMedia";
import Traffic from "../pages/Traffic";

const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  // { path: '/dashboard2', component: Dashboard2 },
  { path: "/users", component: Users },
  { path: "/user/unregistered", component: UnregisteredUsers },
  { path: "/user/unregistered-to-registered", component: UnregisteredToRegistered },
  { path: "/user/registered", component: RegisteredUsers },
  { path: "/user/registered-to-paid", component: RegisteredToPaid },
  { path: "/user/paid", component: Paid },
  { path: "/user-sales", component: UserSales },
  { path: "/screen", component: Screen },
  { path: "/ram-naam", component: RamNaam },
  { path: "/popup", component: Popup },
  { path: "/banner", component: Banner },
  { path: "/banner-footer", component: BannerFooter },
  { path: "/notification-data", component: NotificationData },
  { path: "/setting", component: Setting },
  { path: "/brand", component: Brand },
  { path: "/category", component: Category },
  { path: "/product", component: Product },
  { path: "/property", component: Property },
  { path: "/property/:id", component: Property },
  { path: "/anayltic", component: Anayltics },
  { path: "/anayltic/user/:id", component: Anayltics },
  { path: "/anayltic/screen/:id", component: Anayltics },
  { path: "/anayltic/raam/:id", component: Anayltics },
  { path: "/ads", component: Ads },

  { path: '/tags', component: Tags },
  { path: '/store', component: Store },
  { path: '/blog/category', component: BlogCat },
  { path: '/blog', component: Blog },

  { path: '/story-author', component: StoryAuth },
  { path: '/story-categories', component: StoryCat },
  { path: '/story', component: Story },
  { path: '/share-data', component: ShareData },
  { path: '/mantra', component: Mantra },
  { path: '/wallpaper-category', component: WallCate },
  { path: '/wallpaper', component: Wallpaper },
  { path: '/admin', component: Admin },
  { path: '/ram-naam-winner', component: RaamWinner },
  { path: '/enquiry', component: Enquiry },
  { path: '/temple-region', component: TempleRegion },
  { path: '/temple', component: Temple },
  { path: '/plans', component: Plans },
  { path: '/coupon', component: Coupon },
  { path: '/calendar-month', component: CalMonth },
  { path: '/calendar-event', component: CalEvent },
  { path: '/calendar-category', component: CalCat },
  { path: '/wishes/category', component: WishCat },
  { path: '/wishes/images', component: WishImages },
  { path: '/wishes', component: WishMessage },
  //Notification
  { path: "/notification", component: Notification },
  { path: "/sub-admin", component: SubAdmin },
  { path: "/whatsapp", component: Whatsapp },
  { path: "/social-media", component: SocialMedia },
  { path: "/traffic", component: Traffic },
  // //profile
  { path: "/profile", component: UserProfile },

  { path: "/rashifal", component: Rashifal },
  { path: "/rashifalname", component: RashifalName },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  // { path: "/pages-404", component: Pages404 },
  // { path: "/pages-500", component: Pages500 },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/auth-lock-screen", component: LockScreen },
]

export { userRoutes, authRoutes }