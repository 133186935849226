import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody } from "reactstrap"
import Utils from '../Utility';
import { post, get, put, upload } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { AvForm, AvField } from "availity-reactstrap-validation";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import './style.css';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import moment from "moment";

const Table = (props) => {
  const { user, role, currentData, from, setCurrentData, path, setLoading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [cats, setCats] = useState([]);
  const [numModal, setNumModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [file, setFile] = useState(null);
  const [img, setImg] = useState(null);
  const imageInput = useRef(null);
  const cropper = useRef(null);
  const [isAddForm, setIsAddForm] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (user?.token) {
      getData();
      getCats();
    }
  }, [user]);

  useEffect(() => {
    if (!isAddForm) {
      setFile(null);
      setCurrentData(null);
      setImg(null);
    }
  }, [isAddForm])

  const getCats = () => {
    let url = "/wallpaper/category";
    get(url, { token: user?.token })
      .then(json => {
        console.log('response from get banner list', json);
        if (json?.statusCode == 200) {
          setCats(json?.data);
        } else {
          toast.error(json?.error);
          setError(true);
        }
      })
      .catch(error => {
        console.log('error while getting project list', error);
      });
  }

  const getData = () => {
    let url = "/wallpaper/list/admin";
    get(url, { token: user?.token })
      .then(json => {
        console.log('response from get banner list', json);
        if (json?.statusCode == 200) {
          setData(json?.data);
        } else {
          toast.error(json?.error);
          setError(true);
        }
      })
      .catch(error => {
        console.log('error while getting project list', error);
      });
  }

  useEffect(() => {
    setColumns([
      {
        name: "Position",
        cell: (row, index) => <>
          <Draggable key={row._id} draggableId={row._id} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <i className="mdi mdi-menu" style={{ fontSize: 20, color: Utils.themeColor }}></i>
              </div>
            )}
          </Draggable>
        </>,
        maxWidth: '50px'
      },
      {
        name: 'Image',
        cell: (row) => <>
          <img title={'image'} src={row?.image} style={{ width: 60, height: 'auto', marginTop: 5, marginBottom: 5 }} />
        </>,
      },
      {
        name: "Created At",
        selector: row => moment(row?.createdAt).format("DD-MMM-YYYY hh:mm a"),
        sortable: true,
        maxWidth: "180px"
      },
      {
        name: 'Download',
        selector: row => row?.download,
        cell: row => (
          <Button onClick={() => { if (user?.role == "Admin") handleUpdateNumber(row, "Download") }}
            style={{ backgroundColor: 'blue', paddingLeft: 20, paddingRight: 20, border: 'none' }}>
            <span className="text-white" style={{}}>
              {row?.download}
            </span>
          </Button>
        ),
        maxWidth: '120px',
        sortable: true,
      },
      {
        name: 'Likes',
        selector: row => row?.likes,
        cell: row => (
          <Button onClick={() => { if (user?.role == "Admin") handleUpdateNumber(row, "Likes") }}
            style={{ backgroundColor: 'blue', paddingLeft: 20, paddingRight: 20, border: 'none' }}>
            <span className="text-white" style={{}}>
              {row?.likes}
            </span>
          </Button>
        ),
        maxWidth: '100px',
        sortable: true,
      },
      {
        name: 'Apply',
        selector: row => row?.apply,
        cell: row => (
          <Button onClick={() => { if (user?.role == "Admin") handleUpdateNumber(row, "Apply") }}
            style={{ backgroundColor: 'blue', paddingLeft: 20, paddingRight: 20, border: 'none' }}>
            <span className="text-white" style={{}}>
              {row?.apply}
            </span>
          </Button>
        ),
        maxWidth: '100px',
        sortable: true,
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        selector: row => row?.isActive,
        sortable: true,
        name: 'Status',
        maxWidth: '120px'
      },
      {
        cell: (row) => <>
          {user?.role == "Admin" ?
            <>
              <Button onClick={() => handleUpdateProj(row)}
                title={"Edit"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
              <Button onClick={() => handleDeleteProj(row)}
                title={"Delete"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
            </>
            :
            <>
              {user?.permissions?.length && user?.permissions[7]?.wallpaper?.update &&
                <Button onClick={() => handleUpdateProj(row)}
                  title={"Edit"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
              }
              {user?.permissions?.length && user?.permissions[7]?.wallpaper?.delete &&
                <Button onClick={() => handleDeleteProj(row)}
                  title={"Delete"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
              }
            </>}
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: '100px'
      },
    ])
  }, [data]);

  const handleUpdateNumber = (proj, type) => {
    setCurrentData({ ...proj, updateType: type });
    setNumModal(true);
  }

  const handleUpdateProj = (proj) => {
    // console.log('updating proj', proj);
    setCurrentData(proj);
    setFile(proj?.image);
    setImg(proj?.image);
    setIsAddForm(true);
  }

  const handleUpdateStatus = (item) => {
    console.log('updating proj', item);
    setCurrentData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData({ ...proj, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      wallpaperId: currentData?._id,
      token: user?.token
    }
    let url = "/wallpaper/update";
    if (currentData?.actionType == 'Status') {
      body = { ...body, isActive: !currentData?.isActive };
    } else {
      body = { ...body, isDeleted: true };
    }
    put(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const onChangeFile = async (e) => {
    // console.log(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1800,
        600,
        "webp",
        60,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handleValidSubmit = async (e, v) => {
    if (currentData) {
      let image = img;
      if (file.substring(0, 4) == "blob") {
        setLoading(true);
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        const obj = await urltoFile(fileData, new Date().getTime() + '.png', 'image/png');
        // const temp = await resizeFile(obj);
        const form = new FormData();
        form.append("image", obj);
        const imageRes = await upload("/wallpaper/image_upload", form);
        image = imageRes?.data;
      }
      const body = {
        ...v,
        token: user?.token,
        image,
        wallpaperId: currentData?._id
      }
      put("/wallpaper/update", body)
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            getData();
            toast.success(res?.message);
            setCurrentData(null);
            setFile(null);
            setImg("");
            setIsAddForm(false);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while updating banner', error);
        })
    } else {
      if (file) {
        let image = "";
        setLoading(true);
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        const obj = await urltoFile(fileData, new Date().getTime() + '.png', 'image/png');
        // const temp = await resizeFile(obj);
        const form = new FormData();
        form.append("image", obj);
        const imageRes = await upload("/wallpaper/image_upload", form);
        image = imageRes?.data;
        const body = {
          ...v,
          image,
          token: user?.token
        }
        post("/wallpaper/add", body)
          .then(res => {
            setLoading(false);
            if (res?.statusCode == 200) {
              toast.success(res?.messgae);
              setIsAddForm(false);
              getData();
              setFile(null);
              setImg("");
            } else {
              toast.error(res?.error);
            }
          })
          .catch(error => {
            setLoading(false);
            toast.error('Something Went Wrong!');
            console.log('error while adding banner', error);
          })
      } else
        toast.error('Please choose a banner image!')
    }
  }

  const handleBannerHeight = (val) => {
    console.log('changing banner height ', val);
    setBannerHeight(val);
    if (val > 255) {
      put("/setting/update", { bannerHeight: val, token: user?.token })
        .then(res => {
          console.log('res from banner update', res);
        })
        .catch(err => {
          console.log('error while updating banner height', err);
        })
    }
  }

  const handleStatusProj = (item) => {
    props?.setCurrentData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleSubmitNumber = (e, v) => {
    const body = {
      ...v,
      [currentData?.updateType.toLowerCase()]: v?.number,
      token: user?.token,
      wallpaperId: currentData?._id
    }
    put("/wallpaper/update", body)
      .then(res => {
        setLoading(false);
        if (res?.statusCode == 200) {
          getData();
          toast.success(res?.message);
          setCurrentData(null);
          setNumModal(false);
        } else {
          toast.error(res?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error("Something Went Wrong!")
        console.log('error while updating banner', error);
      })
  }

  const handleDragEnd = (result) => {
    // console.log('dragging item', result);
    // Handle the drag-and-drop logic here
    if (!result.destination) return; // Item was dropped outside the list
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/wallpaper/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            data={currentData}
          />
          <Modal isOpen={isAddForm} toggle={() => setIsAddForm(!isAddForm)} centered={true} style={{ maxWidth: 540 }}>
            <ModalBody>
              <div className="me-3 position-relative">
                {file ?
                  <>
                    {img ?
                      <>
                        <img
                          src={img}
                          alt=""
                          style={{ width: 360, height: 'auto' }}
                        // onClick={(e) => imageInput.current.click(e)}
                        />
                        <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                          onClick={() => { setFile(null); setImg("") }}
                        >
                          <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                        </div>
                      </>
                      :
                      <Cropper
                        style={{ height: 'auto', width: 480 }}
                        aspectRatio={480 / 840}
                        preview=".img-preview"
                        guides={true}
                        src={file}
                        ref={cropper}
                      />
                    }
                  </>
                  :
                  <div style={{
                    position: 'relative',
                    width: 500,
                    height: 320,
                    border: '1px dashed ' + Utils.themeColor,
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: Utils.themeColor,
                    fontSize: 22
                  }} onClick={(e) => imageInput.current.click(e)}>
                    <span
                      style={{
                        padding: 5,
                        paddingLeft: 15, paddingRight: 15,
                        border: '1px solid ' + Utils.themeColor,
                        borderRadius: 5
                      }}
                    >Choose Image</span>
                    <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                    >
                      <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                    </div>
                  </div>
                }
                <input type="file" id="file"
                  ref={imageInput}
                  onChange={onChangeFile}
                  style={{ display: "none" }} />
                <AvForm
                  className="form-horizontal mt-4"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                  }}
                >
                  <Row>
                    <div className="form-group">
                      <AvField
                        label="Category"
                        name="catId"
                        type="select"
                        value={currentData?.catId}
                        required
                      >
                        <option value="">Select Category</option>
                        {cats?.map((item) => (
                          <option value={item?._id}>{item?.title}</option>
                        ))}
                      </AvField>
                    </div>
                    <div className="text-center mt-4">
                      {currentData ?
                        <Button type="submit" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                          Update Wallpaper
                        </Button>
                        :
                        <Button type="submit" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                          Add Wallpaper
                        </Button>
                      }
                    </div>
                  </Row>
                </AvForm>
              </div>
            </ModalBody>
          </Modal>
          <Modal isOpen={numModal} toggle={() => setNumModal(!numModal)} centered={true} style={{ maxWidth: 540 }}>
            <ModalBody>
              <h5><b>Update {currentData?.updateType}</b></h5>
              <AvForm
                className="form-horizontal mt-4"
                onValidSubmit={(e, v) => {
                  handleSubmitNumber(e, v)
                }}
              >
                <Row>
                  <div className="form-group">
                    <AvField
                      label="Number"
                      name="number"
                      type="number"
                      value={currentData?.updateType ? currentData[currentData?.updateType.toLowerCase()] | "" : ""}
                      required
                    />
                  </div>
                  <div className="text-center mt-4">
                    <Button type="submit" color="primary" style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                      Update {currentData?.updateType}
                    </Button>
                    <Button type="reset" color="primary" onClick={() => setNumModal(false)} style={{ marginLeft: 30, backgroundColor: Utils.themeColor, border: 'none' }}>
                      Cancel
                    </Button>
                  </div>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <div>
                    <CardTitle className="h4">{props.role}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {role} you have added before, All the <code>{role} </code>will reflect on your <code>Website and App.</code>
                    </CardSubtitle>
                  </div>
                  <div style={{ marginLeft: 'auto' }}>
                  </div>
                  {user?.role == "Admin" ?
                    <div className="text-center" style={{ marginLeft: 'auto' }}>
                      <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                        Add
                      </Button>
                    </div>
                    :
                    <>
                      {user?.permissions?.length && user?.permissions[7]?.wallpaper?.write ?
                        <div className="text-center" style={{ marginLeft: 'auto' }}>
                          <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                            Add
                          </Button>
                        </div>
                        : null}
                    </>
                  }
                </div>
                <Row>
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="table">
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          <DataTable
                            columns={columns}
                            data={data}
                            pagination
                            customStyles={{
                              headCells: {
                                style: {
                                  color: 'black',
                                  fontWeight: 'bold',
                                  fontSize: 15,
                                  maxWidth: 80
                                },
                              },
                              cells: {
                                style: {
                                  maxWidth: 50
                                }
                              }
                            }}
                            conditionalRowStyles={[{
                              when: row => row?.seen?.length < 1,
                              style: { backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, color: 'white', cursor: 'pointer' },
                            }]}
                          // onRowClicked={(r, e) => seenNoti(r)}
                          />
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}
export default Table;
