import React, { useEffect, useState, useRef } from 'react';

import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { get, post } from '../../helpers/api_helper';
import { CardTitle, Col, Label, Modal, ModalBody, Row, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import "./style.css";
import moment from 'moment-timezone';
import Utils from "../../pages/Utility";
import { AvField, AvForm } from 'availity-reactstrap-validation';

function UserProfile(props) {
    // console.log('props on profile', props);
    const { userId, show, onCloseClick, user } = props;
    const [userData, setUserData] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [isManualPay, setIsManualPay] = useState(false);
    const [plans, setPlans] = useState([]);
    const form = useRef();

    useEffect(() => {
        if (user?.token)
            getPlan();
    }, [user]);

    useEffect(() => {
        if (userId)
            getUserData();
    }, [userId]);

    const getPlan = () => {
        get("/plan", { token: user?.token })
            .then(res => {
                if (res?.statusCode == 200)
                    setPlans(res?.data);
            })
    }

    const getUserData = () => {
        if (!loading) {
            setLoading(true);
            post("/user/detail", { userId, token: user?.token })
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setUserData(res?.data);
                    } else {
                        toast.error(res?.error);
                    }
                })
                .catch(err => {
                    console.log('error while getting user', err);
                    setLoading(false);
                    toast.error("Something Went Wrong!");
                })
        }
    }

    const handleValidSubmit = (e, v) => {
        let body = {
            ...v,
            token: userData?.token
        }
        post("/plan/subscribe", body)
            .then(res => {
                if (res?.statusCode == 200) {
                    toast.success(res?.message);
                    setIsManualPay(false);
                    getUserData();
                    // console.log("for ref", form);
                    // form.reset();
                }
            })
            .catch(err => {
                console.log("error while manual txn", err);
                toast.error("Something Went Wrong!");
            })
    }

    return (
        <Modal isOpen={show} toggle={onCloseClick} centered={true} style={{ maxWidth: 800 }}>
            <ModalBody className="py-3 px-5">
                <h5 className='text-center my-2 mb-4'><b>User Details</b></h5>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col md={4}>
                        <div className='profile_container'>
                            <img className='profile_image' src={userData?.profile_picture ? userData?.profile_picture : require("../../assets/images/om.png")} />
                        </div>
                    </Col>
                    <Col md={8}>
                        <h5><b>{userData?.name}</b></h5>
                        <Row>
                            <Col md={4} className='mt-0'>
                                <span className='key'>Installed On : </span>
                            </Col>
                            <Col md={8} className='mt-0 text-start'>
                                <span className='value'>{moment.tz(userData?.installedOn, 'Asia/Kolkata').format("DD MMM, YYYY hh:mm a")}</span>
                            </Col>
                            <Col md={4} className='mt-0'>
                                <span className='key'>Registered On : </span>
                            </Col>
                            <Col md={8} className='mt-0 text-start'>
                                <span className='value'>{moment.tz(userData?.createdAt, 'Asia/Kolkata').format("DD MMM, YYYY hh:mm a")}</span>
                            </Col>
                            {userData?.trans?.length ?
                                <>
                                    <Col md={4} className='mt-0'>
                                        <span className='key'>Subscribed on : </span>
                                    </Col>
                                    <Col md={8} className='mt-0 text-start'>
                                        <span className='value'>{moment.tz(userData?.trans[0]?.createdAt, 'Asia/Kolkata').format("DD MMM, YYYY hh:mm a")}</span>
                                    </Col>
                                    <Col md={4} className='mt-0'>
                                        <span className='key'>Expiry : </span>
                                    </Col>
                                    <Col md={8} className='mt-0 text-start'>
                                        <span className='value'>{moment.tz(userData?.expiry, 'Asia/Kolkata').format("DD MMM, YYYY hh:mm a")}</span>
                                    </Col>
                                    <Col md={4} className='mt-0'>
                                        <span className='key'>Plan : </span>
                                    </Col>
                                    <Col md={8} className='mt-0 text-start'>
                                        <span className='value text-uppercase'>{userData?.trans[0]?.title}</span>
                                    </Col>
                                </>
                                : null}
                        </Row>
                    </Col>
                    <Col md={6} className='mt-5'>
                        <span className='key'>Device : </span>
                    </Col>
                    <Col md={6} className='mt-5'>
                        <span className='value text-uppercase'>{userData?.deviceType}</span>
                    </Col>
                    <Col md={6} className='mt-1'>
                        <span className='key'>Phone : </span>
                    </Col>
                    <Col md={6} className='mt-1'>
                        <span className='value'>{userData?.mobile}</span>
                    </Col>
                    <Col md={6} className='mt-1'>
                        <span className='key'>Email : </span>
                    </Col>
                    <Col md={6} className='mt-1'>
                        <span className='value'>{userData?.email}</span>
                    </Col>
                    <Col md={6} className='mt-1'>
                        <span className='key'>State : </span>
                    </Col>
                    <Col md={6} className='mt-1'>
                        <span className='value'>{userData?.state}</span>
                    </Col>
                    <Col md={6} className='mt-1'>
                        <span className='key'>Country : </span>
                    </Col>
                    <Col md={6} className='mt-1'>
                        <span className='value'>{userData?.country}</span>
                    </Col>
                </Row>
                <Row>
                    <Col md={9}>
                    </Col>
                    <Col md={3}>
                        <Button
                            title={"Manually Add Transaction"}
                            onClick={() => setIsManualPay(!isManualPay)}
                            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none', float: 'right' }}>
                            {isManualPay ? "Cancel" : "Manual Txn"}
                        </Button>
                    </Col>
                    {isManualPay ?
                        <Col md={12} className='mt-4'>
                            <CardTitle><b>Manual Transaction</b></CardTitle>
                            <AvForm onValidSubmit={handleValidSubmit} ref={form}>
                                <AvField
                                    name="planId"
                                    label="Plan *"
                                    type="select"
                                    required
                                >
                                    <option value="">Select Plan</option>
                                    {plans?.map((item) => (
                                        <option value={item?._id}>{item?.title}</option>
                                    ))}
                                </AvField>
                                <div className="mt-3">
                                    <AvField
                                        name="txnId"
                                        label="Transaction ID *"
                                        placeholder="Enter the transaction id from razorPay or apple pay"
                                        required
                                    />
                                </div>
                                <Row className='mt-3'>
                                    <Col md={6}>
                                        <button
                                            className="btn btn-primary w-100 waves-effect waves-light"
                                            style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                                            onClick={() => { setIsManualPay(false) }}
                                            type="reset"
                                        >
                                            Cancel
                                        </button>
                                    </Col>
                                    <Col md={6}>
                                        <button
                                            className="btn btn-primary w-100 waves-effect waves-light"
                                            type="submit"
                                            style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                                        >
                                            Submit
                                        </button>
                                    </Col>
                                </Row>
                            </AvForm>
                        </Col>
                        : null}
                </Row>
                {userData?.trans?.length ?
                    <>
                        <h5 className='text-center my-2 mb-4 mt-4'><b>Transactions</b></h5>
                        {userData?.trans?.map((item) => (
                            <Row key={item?._id}>
                                <Col md={2} className='mt-0'>
                                    <span className='key'>Plan : </span>
                                </Col>
                                <Col md={4} className='mt-0 text-start'>
                                    <span className='value text-uppercase'>{item?.title}</span>
                                </Col>
                                <Col md={3} className='mt-0'>
                                    <span className='key'>Duration : </span>
                                </Col>
                                <Col md={3} className='mt-0 text-start'>
                                    <span className='value text-uppercase'>{item?.duration} Days</span>
                                </Col>
                                <Col md={2} className='mt-0'>
                                    <span className='key'>TXN_ID : </span>
                                </Col>
                                <Col md={4} className='mt-0 text-start'>
                                    <span className='value text-uppercase'>{item?.txnId}</span>
                                </Col>
                                <Col md={3} className='mt-0'>
                                    <span className='key'>Price : </span>
                                </Col>
                                <Col md={3} className='mt-0 text-start'>
                                    <span className='value text-uppercase'>INR {item?.price}</span>
                                </Col>
                                <Col md={2} className='mt-0'>
                                    <span className='key'>Amount Paid : </span>
                                </Col>
                                <Col md={4} className='mt-0 text-start'>
                                    <span className='value text-uppercase'>INR {item?.amountPaid}</span>
                                </Col>
                                <Col md={3} className='mt-0'>
                                    <span className='key'>Status : </span>
                                </Col>
                                <Col md={3} className='mt-0 text-start'>
                                    {item?.status == "Success" ?
                                        <span className='value text-uppercase' style={{ backgroundColor: "green", padding: "2px 5px", color: "white", fontWeight: 'bold' }}>{item?.status}</span>
                                        : null}
                                    {item?.status == "Pending" ?
                                        <span className='value text-uppercase' style={{ backgroundColor: "yellow", padding: "2px 5px", color: "black", fontWeight: 'bold' }}>{item?.status}</span>
                                        : null}
                                    {item?.status == "Cancelled" ?
                                        <span className='value text-uppercase' style={{ backgroundColor: "red", padding: "2px 5px", color: "white", fontWeight: 'bold' }}>{item?.status}</span>
                                        : null}
                                    {item?.status == "Failed" ?
                                        <span className='value text-uppercase' style={{ backgroundColor: "red", padding: "2px 5px", color: "white", fontWeight: 'bold' }}>{item?.status}</span>
                                        : null}
                                </Col>
                                <Col md={2} className='mt-0'>
                                    <span className='key'>Subscribed on: </span>
                                </Col>
                                <Col md={4} className='mt-0 text-start'>
                                    <span className='value text-uppercase'>{moment(item?.createdAt).format("DD MMM YYYY")}</span>
                                </Col>
                                <Col md={3} className='mt-0'>
                                    <span className='key'>Expiry : </span>
                                </Col>
                                <Col md={3} className='mt-0 text-start'>
                                    <span className='value text-uppercase'>{moment(item?.expiry).format("DD MMM YYYY")}</span>
                                </Col>
                                <hr className='mt-3'></hr>
                            </Row>
                        ))}
                    </>
                    : null}
            </ModalBody>
        </Modal>
    )
}
const mapStatetoProps = state => {
    const { user } = state.Profile;
    return { user }
}

export default withRouter(
    connect(mapStatetoProps, {})(UserProfile)
);