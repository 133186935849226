import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody, } from "reactstrap";
import Utils from '../Utility';
import { post, get, put, upload } from "../../helpers/api_helper";
import 'react-vertical-timeline-component/style.min.css';
import DeleteModal from './DeleteModal';
import { AvField, AvForm } from "availity-reactstrap-validation";
import { toast } from 'react-toastify';
import Multiselect from 'multiselect-react-dropdown';
import avatar from '../../assets/images/small/sample.png';
import HTMLRenderer from 'react-html-renderer';
import DataTable from 'react-data-table-component';
import { Draggable } from "react-drag-reorder";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";

const PropertyDetail = (props) => {
  const { user, setLoading, setIsTable, close, currentUser } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState(null);
  const [transactions, setTransaction] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modalData, setModalData] = useState({ type: "Delete" });
  const [form, setForm] = useState(false);
  const [selectedOppor, setSelectedOppor] = useState([]);
  const [cropperModal, setCropperModal] = useState(false);
  const cropper = useRef();
  const [remarkModal, setRemarkModal] = useState(false);
  const [currentRemark, setCurrentRemark] = useState(null);

  useEffect(() => {
    console.log('user on single from props', currentUser);
    if (currentUser) {
      getData();
    }
  }, [currentUser]);

  const getData = async () => {
    post("/user/user", { token: user?.token, userId: currentUser?._id })
      .then(json => {
        console.log('response from get comp single', json);
        if (json?.statusCode == 200) {
          setData(json?.data);
        }
      })
      .catch(error => {
        console.log('error while getting user detail', error);
      })

    post("/user/transaction", { token: user?.token, userId: currentUser?._id })
      .then(json => {
        console.log('response from get comp single', json);
        if (json?.statusCode == 200) {
          setTransaction(json?.data);
          setColumns([
            {
              name: 'Type',
              selector: row => row?.type,
            },
            {
              name: 'Amount',
              selector: row => row?.amount,
            },
            {
              name: 'Remark',
              selector: row => `Amount ${row.type == 'Credit' ? "paid to" : "deducted from "} your account`,
            },
            // {
            //   cell: (row) => <>
            //     <Button onClick={() => handleDeleteCred(row)}
            //       title={"Delete"}
            //       style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
            //   </>,
            //   name: 'Action',
            //   ignoreRowClick: true,
            //   // allowOverflow: true,
            //   // button: true,
            // },
          ]);
        }
      })
      .catch(error => {
        console.log('error while getting user detail', error);
      })

  }

  const handleUpdateReviewStatus = (item) => {
    let body = {
      reviewId: item?._id,
      isActive: !item?.isActive,
      token: user?.token
    }
    console.log('body after upload images', body);
    put("/review/update", body)
      .then((json) => {
        console.log('response from adding comp', json);
        setLoading(false);
        if (json.statusCode == 200) {
          console.log('property added');
          toast.success(json?.message);
          getData();
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while adding oppor', error);
        toast.error(JSON.stringify(error));
        setLoading(false);
      })
  }

  const handleValidSubmit = (e, values) => {
    console.log('values ', values);
    if (selectedOppor.length < 1) {
      toast.error('Select at least one Opportunity');
      return;
    }
    let temp = [];
    selectedOppor.map((item) => {
      temp.push(item.id);
    });
    let body = {
      ...values,
      companyId: data?._id,
      opporIds: temp,
      companyName: data?.title,
      addedBy: user?._id,
      token: user?.token
    };
    setLoading(true);
    post('/user/add', body)
      .then(json => {
        setLoading(false);
        if (json.statusCode == 200) {
          setForm(false);
          toast.success(json?.message);
          getData();
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
        toast.error("Something Went Wrong");
      })
  }

  const handleDeleteCred = (cred) => {
    console.log('cred need to delete', cred);
    setModalData(cred);
    setDeleteModal(true);
  }

  const onDelete = () => {
    setLoading(true);
    post("/review/delete", { reviewId: modalData?._id, token: user?.token })
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          setModalData(null);
        } else
          toast.error(json?.error);
      })
      .catch(error => {
        setLoading(false);
        toast.error('Something Went Wrong');
        console.error('error while deleting user', error);
      })
  }

  const handleChangeInput = (list) => {
    setSelectedOppor(list);
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1800,
        600,
        "png",
        60,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handlePositionChange = async (currentPos, newPos) => {
    let newBanners = await changeValuePosition(data?.banners, currentPos, newPos);
    console.log('on position changed', newBanners);
    let body = {
      propertyId: data?._id,
      banners: newBanners,
      token: user?.token
    }
    console.log('body after upload images', body);
    put("/property/update", body)
      .then((json) => {
        console.log('response from adding comp', json);
        setLoading(false);
        if (json.statusCode == 200) {
          console.log('property added');
          toast.success(json?.message);
          getData();
          setCropperModal(false);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while adding oppor', error);
        toast.error(JSON.stringify(error));
        setLoading(false);
      })
  }

  const changeValuePosition = (arr, init, target) => {
    [arr[init], arr[target]] = [arr[target], arr[init]];
    return arr;
  }

  const cropImage = (e, v) => {
    setLoading(true);
    const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
    urltoFile(fileData, new Date().getTime() + '.png', 'image/png')
      .then(async (obj) => {
        console.log('values', obj);
        const image = await resizeFile(obj);
        const form = new FormData();
        form.append("image", image);
        upload("/hotel/image_upload", form)
          .then(res => {
            console.log('response from image upload', res);
            if (res?.statusCode == 200) {
              // toast.success(res?.message);
              // getData();
              // setCropperModal(false);
              let banners = data?.banners;
              banners.push(res?.data);
              let body = {
                hotelId: data?._id,
                banners: banners,
                token: user?.token
              }
              console.log('body after upload images', body);
              put("/hotel/update", body)
                .then((json) => {
                  console.log('response from adding comp', json);
                  setLoading(false);
                  if (json.statusCode == 200) {
                    console.log('property added');
                    toast.success(json?.message);
                    getData();
                    setCropperModal(false);
                    // props?.history.go(0);
                  } else {
                    toast.error(json?.error);
                  }
                })
                .catch(error => {
                  console.error('error while adding oppor', error);
                  toast.error(JSON.stringify(error));
                  setLoading(false);
                })
            } else {
              setLoading(false);
              toast.error(res?.error);
            }
          })
          .catch(error => {
            setLoading(false);
            console.log('error while uploading image', error);
            toast.error(error);
          })
      });
  }

  return (
    <React.Fragment>
      <Row>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => onDelete()}
          onCloseClick={() => setDeleteModal(false)}
        />
        {/* Add Remark */}
        <Modal className="modal_form" isOpen={remarkModal} toggle={() => { setRemarkModal(false); setCurrentRemark(null) }} centered={true}>
          <ModalBody className="py-3 px-5">
            <h5 className="text-black font-size-20">{'Add Amount to ' + currentUser?.name + "'s Account"}</h5>
            <div className="p-2">
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v)
                }}
              >
                <Row>
                  <Col lg={12} md={12}>
                    <div className="mb-3 mt-2">
                      <AvField
                        name="message"
                        label="Message *"
                        className="form-control"
                        placeholder="Enter message"
                        type="text"
                        required
                      />
                    </div>
                  </Col>
                  <Col lg={12} md={12}>
                    <div className="mt-4">
                      <Row>
                        <Col md={6}>
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            style={{ backgroundColor: Utils.themeColor }}
                            onClick={() => { setRemarkModal(false); setCurrentRemark(null) }}
                            type="reset"
                          >
                            Cancel
                          </button>
                        </Col>
                        <Col md={6}>
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                            style={{ backgroundColor: Utils.themeColor }}
                          >
                            Send Remark
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </div>
          </ModalBody>
        </Modal>

        <Row>
          <Col xl={12}>
            <Row className="d-flex justify-content-between">
              <Col md={6}>
                <span style={{ color: 'black', fontWeight: 'bold', fontSize: 20 }}>{data?.name}</span>
              </Col>
              <Col md={6} className="d-flex justify-content-end align-items-center" >
                {/* <div style={{ textAlignLast: 'right', marginRight: 10 }}>
                  <Button style={{ backgroundColor: Utils.themeColor }} onClick={() => { props?.setCurrentUser(data); props?.setIsAddForm(true); }}>
                    Update
                  </Button>
                </div> */}
                <div style={{ textAlignLast: 'right' }}>
                  <Button style={{ backgroundColor: Utils.themeColor }} onClick={() => close(false)}>
                    Back
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            {data &&
              <Card className="p-3">
                <Row className="justify-content-around">
                  <Col md={3}>
                    <p className="m-0"><b>Phone : </b>{data?.mobile}</p>
                    <p className="m-0"><b>Alternative Phone : </b>{data?.altMobile}</p>
                    <p className="m-0"><b>Email : </b>{data?.email}</p>
                    <p className="m-0"><b>DOB : </b>{data?.dob}</p>
                  </Col>
                  <Col md={3}>
                    <p className="m-0"><b>House / Flat : </b>{data?.houseNo}</p>
                    <p className="m-0"><b>Tower / Building : </b>{data?.tower}</p>
                    <p className="m-0"><b>Street : </b>{data?.street}</p>
                    <p className="m-0"><b>Landmark : </b>{data?.landmark}</p>
                    <p className="m-0"><b>City : </b>{data?.city}</p>
                    <p className="m-0"><b>State : </b>{data?.state}</p>
                    <p className="m-0"><b>Pin Code : </b>{data?.pinCode}</p>
                  </Col>
                </Row>
              </Card>
            }
            <Card className="">
              <CardTitle>
                <span style={{ color: 'black', fontWeight: 'bold', fontSize: 20 }}>Transactions</span>
                {/* <h5>Transactions</h5> */}
              </CardTitle>
              <DataTable
                columns={columns}
                data={transactions}
                pagination
                conditionalRowStyles={[{
                  when: row => row?.style,
                  style: row => ({ width: row?.style?.width }),
                },
                ]}
                customStyles={{
                  headCells: {
                    style: {
                      color: 'black',
                      fontWeight: 'bold',
                      fontSize: 15,
                      width: 0
                    },
                  },
                  cells: {
                    style: {
                      width: 0
                    }
                  }
                }}
              />
            </Card>
          </Col>
        </Row>
      </Row>
      <Row>
        <Col xl={12}>
          <Row className="d-flex justify-content-between">
            <Col md={6}>
              <span style={{ color: 'black', fontWeight: 'bold', fontSize: 20 }}>Remarks</span>
            </Col>
            <Col md={6} className="d-flex justify-content-end align-items-center" >
              {/* <div style={{ textAlignLast: 'right', marginRight: 10 }}>
                  <Button style={{ backgroundColor: Utils.themeColor }} onClick={() => { props?.setCurrentUser(data); props?.setIsAddForm(true); }}>
                    Update
                  </Button>
                </div> */}
              <div style={{ textAlignLast: 'right' }}>
                <Button style={{ backgroundColor: Utils.themeColor }} onClick={() => setRemarkModal(true)}>
                  Add
                </Button>
              </div>
            </Col>
          </Row>
          <DataTable
            columns={columns}
            data={transactions}
            pagination
            conditionalRowStyles={[{
              when: row => row?.style,
              style: row => ({ width: row?.style?.width }),
            },
            ]}
            customStyles={{
              headCells: {
                style: {
                  color: 'black',
                  fontWeight: 'bold',
                  fontSize: 15,
                  width: 0
                },
              },
              cells: {
                style: {
                  width: 0
                }
              }
            }}
          />
        </Col>
      </Row>
    </React.Fragment >
  )
}

export default PropertyDetail;
