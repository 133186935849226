import React, { useEffect, useState, useRef } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody, Label } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';
import { AvField, AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import { toast } from 'react-toastify';
import Utils from '../../Utility';
import { post, get, put, upload, download } from "../../../helpers/api_helper";
import DeleteModal from '../DeleteModal';
import DataTable from 'react-data-table-component';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import { saveAs } from 'file-saver';
import moment from "moment";
// import './Card.scss';

const Table = (props) => {
  const { user, role, currentUser, path, setLoading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentModalData, setCurrentModalData] = useState(null);
  const [isAddForm, setIsAddForm] = useState(false);
  const [file, setFile] = useState(null);
  const [imageChanged, setImageChanged] = useState(false);
  const [error, setError] = useState(false);
  const imageInput = useRef();
  const cropper = useRef();

  useEffect(() => {
    if (user?.token) {
      getMetas();
    }
  }, [user, path]);

  const getMetas = () => {
    get("/rashifal/list/admin/rashifalName", { token: user?.token })
      .then(json => {
        console.log('response from get project list', path);
        if (json?.statusCode == 200) {
          setData(json?.data);
        } else {
          toast.error(json?.error);
          setError(true);
        }
      })
      .catch(error => {
        console.log('error while getting project list', error);
      })
  }

  useEffect(() => {
    setColumns([
      {
        name: 'Image',
        cell: (row) => <>
          <img title={'logo'} src={row?.image} style={{ width: 50, height: 'auto' }} />
        </>,
        maxWidth: "100px"
      },
      {
        name: 'Name',
        selector: row => row?.title,
      },
      {
        name: 'Name Hindi',
        selector: row => row?.titleHi,
      },
      {
        name: 'Created At',
        selector: row => moment(row?.createdAt).format("DD-MMM-YYYY hh:mm a"),
        sortable: true,
        maxWidth: "180px"
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
        maxWidth: "120px"
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateEdit(row, 'bank')}
            title={"Edit"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
          <Button onClick={() => handleDelete(row, 'bank')}
            title={"Delete"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: "120px"
      },
    ])
  }, [data]);

  useEffect(() => {
    if (!isAddForm) {
      setCurrentModalData(null);
      // imageInput.current.value = "";
    }
  }, [isAddForm])

  const handleUpdateEdit = (proj) => {
    console.log('updating proj', proj);
    setCurrentModalData(proj);
    setFile(proj?.image);
    setImageChanged(false);
    setIsAddForm(true);
  }

  const handleDelete = (proj, metaType) => {
    console.log('updating proj', proj);
    setCurrentModalData({ ...proj, metaType, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const handleUpdateStatus = (item) => {
    console.log('updating proj', item);
    setCurrentModalData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentModalData);
    let body = {
      RashiNameId: currentModalData?._id,
      token: user?.token
    }
    post(`/rashifal/delete/rashiName`, body)
      .then(json => {
        console.log('response from delete user', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getMetas();
          setDeleteModal(false);
          setCurrentModalData(null);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const onStatus = () => {
    console.log('status', currentModalData);
    setLoading(true);
    let body = {
      RashiNameId: currentModalData?._id,
      isActive: !currentModalData?.isActive,
      token: user?.token
    }
    let url = `/rashifal/update/rashiName`;
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getMetas();
          setDeleteModal(false);
          setCurrentModalData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1800,
        600,
        "webp",
        60,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handleValidSubmit = async (e, values, type) => {
    // console.log('values', values, file);
    setLoading(true);
    if (currentModalData) {
      if (imageChanged) {
        console.log('updating state changed', typeof file);
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        urltoFile(fileData, new Date().getTime() + '.png', 'image/png')
          .then(async (obj) => {
            console.log('values', obj);
            const image = await resizeFile(obj);
            const form = new FormData();
            form.append("image", image);
            upload("/rashifal/image_upload", form)
              .then(res => {
                console.log('response from image upload', res);
                if (res?.statusCode == 200) {
                  const body = {
                    ...values,
                    RashiNameId: currentModalData?._id,
                    image: res?.data,
                    token: user?.token
                  }
                  put("/rashifal/update/rashiName", body)
                    .then(json => {
                      console.log('response from add state', json);
                      setLoading(false);
                      if (json.statusCode == 200) {
                        toast.success(json?.message);
                        getMetas();
                        setFile(null);
                        setIsAddForm(false);
                        setCurrentModalData(null);
                      } else {
                        toast.error(json?.error);
                      }
                    })
                    .catch(error => {
                      setLoading(false);
                      console.log('error while uploading image', error);
                      toast.error(error);
                    })
                } else {
                  setLoading(false);
                  toast.error(res?.error);
                }
              })
              .catch(error => {
                setLoading(false);
                console.log('error while uploading image', error);
                toast.error(error);
              });
          });
      } else {
        const body = {
          ...values,
          RashiNameId: currentModalData?._id,
          token: user?.token
        }
        put("/rashifal/update/rashiName", body)
          .then(json => {
            console.log('response from add state', json);
            setLoading(false);
            if (json.statusCode == 200) {
              toast.success(json?.message);
              getMetas();
              setFile(null);
              setIsAddForm(false);
              setCurrentModalData(null);
            } else {
              toast.error(json?.error);
            }
          })
          .catch(error => {
            setLoading(false);
            console.log('error while uploading image', error);
            toast.error(error);
          });
      }
    } else {
      const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
      urltoFile(fileData, new Date().getTime() + '.png', 'image/png')
        .then(async (obj) => {
          console.log('values', obj);
          const image = await resizeFile(obj);
          const form = new FormData();
          form.append("image", image);
          upload("/rashifal/image_upload", form)
            .then(res => {
              console.log('response from image upload', res);
              if (res?.statusCode == 200) {
                const body = {
                  ...values,
                  image: res?.data,
                  token: user?.token
                }
                post("/rashifal/add/rashifalName", body)
                  .then(json => {
                    console.log('response from add state', json);
                    setLoading(false);
                    if (json.statusCode == 200) {
                      toast.success(json?.message);
                      getMetas();
                      setFile(null);
                      setIsAddForm(false);
                    } else {
                      toast.error(json?.error);
                    }
                  })
                  .catch(error => {
                    setLoading(false);
                    console.log('error while uploading image', error);
                    toast.error(error);
                  })
              } else {
                setLoading(false);
                toast.error(res?.error);
              }
            })
            .catch(error => {
              setLoading(false);
              console.log('error while uploading image', error);
              toast.error(error);
            })
        });
    }
  }

  const onChangeFile = (e) => {
    console.log('getting event on input img', e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
    setImageChanged(true);
  }

  const downloadZip = () => {
    download("/continent/download")
      .then(res => {
        console.log('response from download images', res);
        var blob = new Blob([res], { type: "application/octet-stream" });
        console.log('after blob', blob);
        saveAs(blob, `continent${new Date().getTime()}.zip`);
      })
      .catch(err => {
        console.log('error while downloading image', err);
      })
  }

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => currentModalData?.actionType == 'Status' ? onStatus() : onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            actionType={currentModalData?.actionType}
            data={currentModalData}
          />
          {/* Add or Update depart form */}
          <Modal isOpen={isAddForm} toggle={() => { setIsAddForm(false); setFile(null) }} centered={true}>
            <ModalBody className="py-3 px-5">
              <h5 className="text-black font-size-20">{currentModalData ? `Update ${role}!` : `Add New ${role}!`}</h5>
              <div className="p-2">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v, 'bank')
                  }}
                >
                  <Row>
                    <Col lg={12} md={12}>
                      <div className="mb-3">
                        <AvField
                          name="title"
                          label="Name"
                          className="form-control"
                          placeholder="Enter name"
                          type="text"
                          required
                          value={currentModalData?.title}
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="mb-3">
                        <AvField
                          name="titleHi"
                          label="Hindi Name"
                          className="form-control"
                          placeholder="Enter name in Hindi"
                          type="text"
                          required
                          value={currentModalData?.titleHi}
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="mb-3 mt-2">
                        {file ?
                          <>
                            <CardTitle >Image</CardTitle>
                            <div style={{ flexDirection: 'row', position: 'relative', cursor: 'pointer', marginTop: 20 }}>
                              {currentModalData?.image ?
                                <img src={file} style={{ width: 100, height: 'auto' }} />
                                :
                                <Cropper
                                  style={{ height: 'auto', width: 400 }}
                                  aspectRatio={1 / 1}
                                  preview=".img-preview"
                                  guides={true}
                                  src={file}
                                  ref={cropper}
                                />
                              }
                              <i className="mdi mdi-close" style={{
                                color: 'white', backgroundColor: Utils.themeColor, fontSize: 20,
                                position: 'absolute', top: -16, right: -28, borderRadius: 15, width: 30, height: 30, textAlign: 'center'
                              }}
                                onClick={() => { setFile(null); if (currentModalData) setCurrentModalData({ ...currentModalData, image: undefined }) }}
                              />
                            </div>
                          </>
                          :
                          <AvField
                            name="fileInput"
                            label="Image"
                            className="form-control"
                            placeholder="upload bank logo"
                            type="file"
                            onChange={e => onChangeFile(e)}
                            required
                            multiple={false}
                            ref={imageInput}
                            accept="image/png, image/jpeg"
                          />
                        }
                      </div>
                    </Col>
                  </Row>
                  <div className="mt-4">
                    <Row>
                      <Col md={6}>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                          onClick={() => { setIsAddForm(false); setFile(null) }}
                          type="reset"
                        >
                          Cancel
                        </button>
                      </Col>
                      <Col md={6}>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                          style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                        >
                          {currentModalData ? 'Update' : 'Add'}
                        </button>
                      </Col>
                    </Row>
                  </div>
                </AvForm>
              </div>
            </ModalBody>
          </Modal>

          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <div>
                    <CardTitle className="h4">{props.role}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {role} you have added before, All the <code>Products </code> will be added according to {role}.
                    </CardSubtitle>
                  </div>
                </div>

                <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <h5>{role}s</h5>
                  {!error &&
                    <div className="text-center" style={{ marginLeft: 'auto' }}>
                      <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                        Add
                      </Button>
                    </div>
                  }
                </div>

                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  customStyles={{
                    headCells: {
                      style: {
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: 15,
                        maxWidth: 80
                      },
                    },
                    cells: {
                      style: {
                        maxWidth: 50
                      }
                    }
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    </React.Fragment >
  )
}

export default Table;
