import PropTypes from "prop-types";
import React, { useEffect, useState } from "react"
import { Row, Col, CardBody, Card, Progress } from "reactstrap"
import { withRouter, Link } from "react-router-dom";

import { connect } from "react-redux";
import { getProfile } from '../../store/auth/profile/actions';
import { AvForm, AvField } from 'availity-reactstrap-validation';

//Import Components
import LineChart from "./line-chart";
import PaymentChart from "./paymentChart";
import RevenueChart from "./revenue-chart";
import SalesAnalytics from "./sales-analytics";
import DeviceAnalytics from "./devices";
import Products from "./product";
import LatestTransaction from "./latest-transaction";

//Import Image
import Revenue from './Revenue';
import { get } from "../../helpers/api_helper";
import Utils from "../Utility";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
const { io } = require("socket.io-client");

const Dashboard = (props) => {
  const { user } = props;
  const [data, setData] = useState(undefined);
  const [screens, setScreens] = useState([]);
  const [ramCounter, setRamCounter] = useState(0);
  const [time, setTime] = useState(1);
  const [users, setUsers] = useState(null);
  const [cities, setCities] = useState([]);
  const [devices, setDevices] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // console.log('props on home ', user);
    if (user?.token) {
      getStats();
    }
  }, [user, time]);

  const getStats = () => {
    // console.log('calling get stat');
    if (!loading) {
      setLoading(true);
      get("/screen/stat", { token: user?.token })
        .then(json => {
          setLoading(false);
          // console.log('response from get stat', json);
          if (json?.statusCode == 200) {
            setScreens(json?.data?.screens);
            setData(json?.data);
            setDevices(json?.data?.devices);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.error('Error while getting stat', error);
          toast.error(error);
        });
    }
  }

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              {/* <h4 className="page-title mb-0 font-size-18">Dashboard</h4> */}

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Welcome to {Utils.projectName} Dashboard</li>
                </ol>
              </div>
              <div className="right-section-breadcrumb">
                <button
                  type="button"
                  onClick={() => {
                    toggleFullscreen();
                  }}
                  className="btn noti-icon waves-effect"
                  data-toggle="fullscreen"
                >
                  <i className="mdi mdi-fullscreen" style={{ color: 'white' }}></i>
                </button>
                <Link to="/logout" className="dropdown-item text-white">
                  <i className="bx bx-power-off font-size-16 align-middle me-1 text-white"></i>{" "}
                  <span>{'Logout'}</span>
                </Link>
              </div>
            </div>
          </div>
        </Row>
        <Row className="mt-2">
          <Col lg={12}>
            {/* <LineChart data={screens} /> */}
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <Card style={{ height: '87%' }}>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary rounded">
                      <i className="mdi mdi-account-multiple-outline"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">All Users ({data?.registered?.allUsers})</div>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                  {/* <Link to={{ pathname: "/users", state: { type: "Registered", time: "" } }} style={{ textDecoderation: 'none' }}> */}
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                    <h4 className="mt-4" style={{ fontSize: 18 }}>{data?.registered?.allPaidUser}</h4>
                    <p style={{ fontSize: 12, color: '#000' }}>Paid</p>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                    <h4 className="mt-4" style={{ fontSize: 18 }}>{data?.registered?.allUsers}</h4>
                    <p style={{ fontSize: 12, color: '#000' }}>Registered</p>
                  </div>
                  {/* </Link>
                  <Link to={{ pathname: "/users", state: { type: "Unregistered", time: "" } }} style={{ textDecoderation: 'none' }}> */}
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h4 className="mt-4" style={{ fontSize: 18 }}>{data?.unregistered?.allUsers}</h4>
                    <p style={{ fontSize: 12, color: '#000' }}>Unregistered</p>
                  </div>
                  {/* </Link> */}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3}>
            <Card style={{ height: '87%' }}>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary rounded">
                      <i className="mdi mdi-account-multiple-outline"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-14 mt-2">Returns Weekly ({data?.registered?.returnsWeekly})</div>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                  {/* <Link to={{ pathname: "/users", state: { type: "Registered", time: "Week" } }} style={{ textDecoderation: 'none' }}> */}
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h4 className="mt-4" style={{ fontSize: 18 }}>{data?.registered?.returnsWeeklyPaid}</h4>
                    <p style={{ fontSize: 12, color: '#000' }}>Paid</p>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h4 className="mt-4" style={{ fontSize: 18 }}>{data?.registered?.returnsWeekly}</h4>
                    <p style={{ fontSize: 12, color: '#000' }}>Registered</p>
                  </div>
                  {/* </Link> */}
                  {/* <Link to={{ pathname: "/users", state: { type: "Unregistered", time: "Week" } }} style={{ textDecoderation: 'none' }}> */}
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h4 className="mt-4" style={{ fontSize: 18 }}>{data?.unregistered?.returnsWeekly}</h4>
                    <p style={{ fontSize: 12, color: '#000' }}>Unregistered</p>
                  </div>
                  {/* </Link> */}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3}>
            <Card style={{ height: '87%' }}>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary rounded">
                      <i className="mdi mdi-account-multiple-outline"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-14 mt-2">Active In 24 Hour ({data?.registered?.activeLast24Hours + data?.unregistered?.activeLast24Hours})</div>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h4 className="mt-4" style={{ fontSize: 18 }}>{data?.registered?.activeLast24HoursPaid}</h4>
                    <p style={{ fontSize: 12 }}>Paid</p>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h4 className="mt-4" style={{ fontSize: 18 }}>{data?.registered?.activeLast24Hours}</h4>
                    <p style={{ fontSize: 12 }}>Registered</p>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h4 className="mt-4" style={{ fontSize: 18 }}>{data?.unregistered?.activeLast24Hours}</h4>
                    <p style={{ fontSize: 12 }}>Unregistered</p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3}>
            <Card style={{ height: '87%' }}>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary rounded">
                      <i className="mdi mdi-account-multiple-outline"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-14 mt-2">New Last Week ({data?.registered?.newLastWeek + data?.unregistered?.newLastWeek})</div>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                  {/* <Link to={{ pathname: "/users", state: { type: "Registered", time: "Week" } }} style={{ textDecoderation: 'none' }}> */}
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h4 className="mt-4" style={{ fontSize: 18 }}>{data?.registered?.newLastWeekPaid}</h4>
                    <p style={{ fontSize: 12, color: '#000' }}>Paid</p>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h4 className="mt-4" style={{ fontSize: 18 }}>{data?.registered?.newLastWeek}</h4>
                    <p style={{ fontSize: 12, color: '#000' }}>Registered</p>
                  </div>
                  {/* </Link> */}
                  {/* <Link to={{ pathname: "/users", state: { type: "Unregistered", time: "Week" } }} style={{ textDecoderation: 'none' }}> */}
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h4 className="mt-4" style={{ fontSize: 18 }}>{data?.unregistered?.newLastWeek}</h4>
                    <p style={{ fontSize: 12, color: '#000' }}>Unregistered</p>
                  </div>
                  {/* </Link> */}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3}>
            <Card style={{ height: '91%' }}>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary rounded">
                      <i className="mdi mdi-account-multiple-outline"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-14 mt-2">New In 24 Hour ({data?.registered?.newIn24Hour + data?.unregistered?.newIn24Hour})</div>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h4 className="mt-4" style={{ fontSize: 18 }}>{data?.registered?.newIn24HourPaid}</h4>
                    <p style={{ fontSize: 12 }}>Paid</p>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h4 className="mt-4" style={{ fontSize: 18 }}>{data?.registered?.newIn24Hour}</h4>
                    <p style={{ fontSize: 12 }}>Registered</p>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h4 className="mt-4" style={{ fontSize: 18 }}>{data?.unregistered?.newIn24Hour}</h4>
                    <p style={{ fontSize: 12 }}>Unregistered</p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3}>
            <DeviceAnalytics source={devices} />
          </Col>
          {/* <Col lg={3}>
            <Card style={{ height: '87%' }}>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <img src={require("../../assets/images/om.png")} style={{ width: 40, height: 40 }} />
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Ram Counter</div>
                  </div>
                </div>
                <h4 className="mt-4" style={{ textAlign: 'center' }}>{ramCounter}</h4>
              </CardBody>
            </Card>
          </Col> */}
          <Col lg={6}>
            <SalesAnalytics source={data?.registered?.userByCity} />
            {/* <Inbox /> */}
          </Col>
          <Col lg={6}>
            {/* <Products source={products} /> */}
            {/* <Inbox /> */}
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <PaymentChart data={data?.payments} />
          </Col>
          {/* <Col lg={4}>
            <ScatterChart />
          </Col> */}
          <Col lg={4}>
            {/* <Inbox dealers={data?.dealers} /> */}
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            {/* <Inbox moderator={data?.moderator} /> */}
            {/* <Card className="bg-primary" style={{ height: 342 }}>
              <CardBody>
                <div className="text-white">
                  <h5 className="text-white">2400 + New Users</h5>
                  <p>At vero eos et accusamus et iusto odio dignissimos ducimus</p>
                  <div>
                    <Link to="#" className="btn btn-outline-success btn-sm">View more</Link>
                  </div>
                </div>
                <Row className="justify-content-end">
                  <div className="col-8">
                    <div className="mt-4">
                      <img src={widgetImage} alt=""
                        className="img-fluid mx-auto d-block" />
                    </div>
                  </div>
                </Row>
              </CardBody>
            </Card> */}
          </Col>
          {/* <Reviews /> */}
          {/* <Revenue /> */}
        </Row>

        <Row>
          {/* <LatestTransaction user={user} history={props?.history} /> */}
        </Row>

      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  getProfile: PropTypes.func
}

const mapStateToProps = state => {
  // console.log('state at dashboard', state);
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStateToProps, { getProfile })(Dashboard)
)