import React from 'react';
import { Watch } from 'react-loader-spinner';
const loading = require('../../assets/images/loading.gif');
import Utils from '../../pages/Utility';

const Loader = (props) => {
    if (props.visible)
        // if(true)
        return (
            <div style={{ width: '90%', height: '90%', alignItems: 'center', justifyContent: 'center', backgroundColor: '#ffffff88', zIndex: 1051, display: 'flex', position: 'fixed' }}>
                <Watch
                    height={100}
                    width={150}
                    radius={48}
                    color={Utils.themeColor}
                    secondaryColor='#4fa94d'
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle={{}}
                    visible={true}
                />
                {/* <img src={loading} style={{height:100, width:100, backgroundColor:'transparent'}} /> */}
            </div>
        )
    else
        return null;
}

export default Loader;