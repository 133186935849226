import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Breadcrumb from "../../../components/Common/Breadcrumb";
import Utils from '../../Utility'
import Table from './Table';
import Loader from "../../../components/Loader";

const Region = props => {
  const { user } = props;
  const [isAddForm, setIsAddForm] = useState(false);
  const [isTable, setIsTable] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [path, setPath] = useState("bank");
  // console.log('props on company', props);

  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Regions" />
        {isTable &&
          <Card>
            <CardBody>
              <Table
                role={"Region"}
                path={path}
                user={user}
                history={props.history}
                setLoading={setLoading}
              />
            </CardBody>
          </Card>
        }

      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStatetoProps, {})(Region)
)
