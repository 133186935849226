import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button,
    CardTitle,
    CardSubtitle,
} from "reactstrap"

import { connect } from "react-redux"
import { withRouter, useParams, useLocation } from "react-router-dom"
import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility';
import Loader from "../../components/Loader";
import DataTable from 'react-data-table-component';
import { get, post, put } from '../../helpers/api_helper'
import { toast } from 'react-toastify';
import DeleteModal from './DeleteModal'
import ReplyModal from './ReplyModal'
import moment from 'moment/moment'

const Enquiry = props => {
    const { user } = props;
    const [currentData, setCurrentData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [replyMod, setReplyMod] = useState(false);

    useEffect(() => {
        if (user?.token)
            getData();
    }, [user]);

    const getData = () => {
        if (!loading) {
            setLoading(true);
            get("/enquiry", { token: user?.token })
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setData(res?.data);
                    } else {
                        toast.error(res?.error);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    console.log('error while getting enquiries', err);
                    toast.error('Something Went Wrong!');
                })
        }
    }

    useEffect(() => {
        setColumns([
            {
                name: 'Name',
                selector: row => row?.user[0]?.name,
                cell: (row) => <div>
                    <span style={{ textTransform: 'capitalize', marginLeft: 5 }}>{row?.user[0]?.name}</span>
                </div>,
                sortable: true,
                maxWidth: '195px'
            },
            {
                name: 'Email',
                selector: row => row?.user[0]?.email,
                sortable: true,
                maxWidth: '200px'
            },
            {
                name: 'Mobile',
                selector: row => row?.user[0]?.mobile,
                sortable: true,
                maxWidth: '110px'
            },
            {
                name: 'Message',
                selector: row => row?.message,
                sortable: true
            },
            {
                name: 'Created At',
                selector: row => moment(row?.createdAt).format("DD-MMM-YYYY hh:mm a"),
                sortable: true,
                maxWidth: "180px"
            },
            {
                cell: (row) => <>
                    <Button onClick={() => handleUpdateStatus(row)}
                        title={row?.isActive ? "Completed" : "Pending"}
                        style={{ backgroundColor: !row.isActive ? 'green' : 'orange', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
                        <span className="text-white" style={{}}>
                            {!row?.isActive ? 'Completed' : 'Pending'}
                        </span>
                    </Button>
                </>,
                name: 'Status',
                ignoreRowClick: true,
                maxWidth: '130px'
            },

            {
                cell: (row) => <>
                    {user?.role == "Admin" ?
                        <>
                            <Button onClick={() => handleReplyProj(row)}
                                title={"Reply"}
                                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-comment" style={{ fontSize: 20 }}></span></Button>
                            <Button onClick={() => handleDeleteProj(row)}
                                title={"Delete"}
                                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
                        </>
                        :
                        <>
                            {user?.permissions?.length && user?.permissions[10]?.contact_us?.update &&
                                <Button onClick={() => handleReplyProj(row)}
                                    title={"Reply"}
                                    style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-comment" style={{ fontSize: 20 }}></span></Button>
                            }
                            {user?.permissions?.length && user?.permissions[10]?.contact_us?.delete &&
                                <Button onClick={() => handleDeleteProj(row)}
                                    title={"Delete"}
                                    style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
                            }
                        </>}
                </>,
                name: 'Action',
                ignoreRowClick: true,
                maxWidth: '50px'
            },
        ]);
    }, [data]);

    const handleUpdateStatus = (row) => {
        setCurrentData({ ...row, actionType: 'Status' });
        setDeleteModal(true);
    }

    const handleDeleteProj = (row) => {
        setCurrentData(row);
        setDeleteModal(true);
    }

    const handleReplyProj = (row) => {
        setCurrentData(row);
        setReplyMod(true);
    }

    const onDelete = () => {
        setLoading(true);
        let body = {
            enquiryId: currentData?._id,
            token: user?.token
        }
        if (currentData?.actionType == 'Status')
            body = { ...body, isActive: !currentData?.isActive }
        else
            body = { ...body, isDeleted: true }
        put("/enquiry", body)
            .then(res => {
                setLoading(false);
                if (res?.statusCode == 200) {
                    getData();
                    setDeleteModal(false);
                    setCurrentData(null);
                } else {
                    toast.error(res?.error);
                }
            })
            .catch(err => {
                setLoading(false);
                console.log('error while getting enquiries', err);
                toast.error('Something Went Wrong!');
            })
    }

    const onReply = (v) => {
        setLoading(true);
        let body = {
            ...v,
            enquiryId: currentData?._id,
            isActive: false,
            token: user?.token
        }
        post("/enquiry/reply", body)
            .then(res => {
                setLoading(false);
                if (res?.statusCode == 200) {
                    getData();
                    setReplyMod(false);
                    setCurrentData(null);
                } else {
                    toast.error(res?.error);
                }
            })
            .catch(err => {
                setLoading(false);
                console.log('error while getting enquiries', err);
                toast.error('Something Went Wrong!');
            })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Loader visible={loading} />
                <Breadcrumb title={Utils.projectName} breadcrumbItem="Enquiry" />
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={() => onDelete()}
                    onCloseClick={() => setDeleteModal(false)}
                    data={currentData}
                />
                <ReplyModal
                    show={replyMod}
                    onReplyClick={(v) => onReply(v)}
                    onCloseClick={() => setReplyMod(false)}
                    data={currentData}
                />
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={12}>
                                <CardTitle className="h4">{props.role}</CardTitle>
                                <CardSubtitle className="mb-3">
                                    Enquiries those who filled the contact us form, All the <code>Users will not be able </code> to fill that form again until you don't <code>Completed</code> that <code>Quiry.</code>
                                </CardSubtitle>
                            </Col>
                            <h4>Enquiries</h4>
                        </Row>
                        <DataTable
                            columns={columns}
                            data={data}
                            pagination
                            // paginationComponent={CustomPagination}
                            // pagination
                            paginationPerPage={100}
                            paginationRowsPerPageOptions={[100, 200, 300, 400]}
                            customStyles={{
                                headCells: {
                                    style: {
                                        color: 'black',
                                        fontWeight: 'bold',
                                        fontSize: 15,
                                        maxWidth: 80
                                    },
                                },
                                cells: {
                                    style: {
                                        maxWidth: 50
                                    }
                                }
                            }}
                        />
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}

const mapStatetoProps = state => {
    const { user } = state.Profile;
    return { user }
}

export default withRouter(
    connect(mapStatetoProps, {})(Enquiry)
)
