import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody, Label } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { AvForm, AvField } from "availity-reactstrap-validation";
import Utils from '../Utility';
import { post, get, put, upload } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import './Card.scss';
import { toast } from "react-toastify";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";

const NotificationTable = (props) => {
  const { user, role, setLoading, loading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isAddForm, setIsAddForm] = useState(false);
  const [currentModalData, setCurrentModalData] = useState(null);
  const [file, setFile] = useState(null);
  const [img, setImg] = useState(null);
  const [shareImg, setShareImg] = useState(null);
  const imageInput = useRef();
  const imageInputShare = useRef();
  const cropper = useRef();
  const [cropperModal, setCropperModal] = useState(false);
  const [currentImageType, setCurrentImageType] = useState("notificaiton");
  const [shareMessage, setShareMessage] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    if (user)
      getData();
  }, [user]);

  const getData = () => {
    get("/setting", {token:user?.token})
      .then(res => {
        if (res?.statusCode == 200) {
          setImg(res?.data?.notificationImage);
          setShareImg(res?.data?.shareImage);
          setShareMessage(res?.data?.shareMessage);
        } else {
          toast.error(res?.error);
          setError(true);
        }
      })
  }

  const handleValidSubmit = async (e, values, type) => {
    // console.log('values', values, file);
    setLoading(true);
    if (currentModalData) {
      const body = {
        ...values,
        dohaId: currentModalData?._id,
        token: user?.token
      }
      put("/doha/update", body)
        .then(json => {
          console.log('response from add state', json);
          setLoading(false);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getData();
            setIsAddForm(false);
            setCurrentModalData(null);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while uploading image', error);
          toast.error(error);
        })
        .catch(error => {
          setLoading(false);
          console.log('error while uploading image', error);
          toast.error(error);
        });
    } else {
      const body = {
        ...values,
        token: user?.token
      }
      post("/doha/add", body)
        .then(json => {
          console.log('response from add state', json);
          setLoading(false);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getData();
            setIsAddForm(false);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while uploading image', error);
          toast.error(error);
        })
    }
  }

  const handleUpdateProj = (proj) => {
    console.log('updating proj', proj);
    setCurrentModalData(proj);
    setIsAddForm(true);
  }

  const handleDeleteProj = (proj) => {
    console.log('deleting proj', proj);
    setCurrentModalData(proj);
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentModalData);
    post("/doha/delete", { dohaId: currentModalData?._id, token: user?.token })
      .then(json => {
        console.log('response from delete noti', json);
        if (json.statusCode == 200) {
          getData();
          setDeleteModal(false);
          setCurrentModalData(null);
        }
      })
      .catch(error => {
        console.error('error while deleting noti', error);
      })
  }

  const onChangeFile = async (e, to) => {
    console.log(e.target.files[0]);
    setCurrentImageType(to);
    if (to == 'share') {
      setFile(URL.createObjectURL(e.target.files[0]));
    }
    setCropperModal(true);
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1800,
        600,
        "webp",
        60,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const cropImage = async (e, v) => {
    setLoading(true);
    const fileData = await cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
    const fileTemp = await urltoFile(fileData, new Date().getTime() + '.png', 'image/png');
    console.log('fileTemp', fileTemp);
    const image = await resizeFile(fileTemp);
    const form = new FormData();
    form.append("image", image);
    let uploadImage = undefined;
    if (currentImageType == 'share')
      uploadImage = await upload("/share_app_image", form);
    else
      uploadImage = await upload("/notification_banner", form);
    console.log('res', uploadImage);
    // setImg();
    setLoading(false);
    if (uploadImage?.statusCode == 200) {
      setCropperModal(false);
      setImg(uploadImage?.data?.notificationImage);
      setShareImg(uploadImage?.data?.shareImage);
    }
  }

  const handleValidMessage = async (e, v) => {
    let body = {
      ...v,
      token: user?.token
    }
    put("/setting/update", body)
      .then(res => {
        if (res?.statusCode == 200) {
          toast.success(res?.message);
        }
        else
          toast.error(res?.error);
      })
      .catch(err => {
        console.log('error whiling setting setting', err);
        toast.error(err + "");
      })
  }

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
          />
          <Modal isOpen={isAddForm} toggle={() => { setIsAddForm(false); }} centered={true}>
            <ModalBody className="py-3 px-5">
              <h5 className="text-black font-size-20">{currentModalData ? `Update ${role}!` : `Add New ${role}!`}</h5>
              <div className="p-2">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v, 'bank')
                  }}
                >
                  <Row>
                    <Col lg={12} md={12}>
                      <div className="mb-3">
                        <AvField
                          name="title"
                          label="Title"
                          className="form-control"
                          placeholder="Enter Doha / Chaupaee or notification description"
                          type="textarea"
                          rows={3}
                          required
                          value={currentModalData?.title}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="mt-4">
                    <Row>
                      <Col md={6}>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                          onClick={() => { setIsAddForm(false); }}
                          type="reset"
                        >
                          Cancel
                        </button>
                      </Col>
                      <Col md={6}>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                          style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                        >
                          {currentModalData ? 'Update' : 'Add'}
                        </button>
                      </Col>
                    </Row>
                  </div>
                </AvForm>
              </div>
            </ModalBody>
          </Modal>
          <Modal isOpen={cropperModal} toggle={() => { setCropperModal(false); setFile(null) }} centered={true}>
            <ModalBody className="py-3 px-5">
              <h5 className="text-black font-size-20">Crop Image</h5>
              <div className="p-2">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    cropImage()
                  }}
                >
                  <div className="mt-0">
                    <Row>
                      <Col md={6}>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                          onClick={() => { setCropperModal(false); setFile(null) }}
                          type="reset"
                        >
                          Cancel
                        </button>
                      </Col>
                      <Col md={6}>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                          style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                        >
                          Submit
                        </button>
                      </Col>
                    </Row>
                  </div>
                  <div className="mb-3 mt-3">
                    <Cropper
                      style={{ height: 'auto', width: 400 }}
                      aspectRatio={currentImageType == 'notificaiton' ? 512 / 256 : 1080 / 1920}
                      preview=".img-preview"
                      guides={true}
                      src={file}
                      ref={cropper}
                    />
                  </div>
                </AvForm>
              </div>
            </ModalBody>
          </Modal>
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <div>
                    <CardTitle className="h4">{role}</CardTitle>
                    <CardSubtitle className="mb-3">
                      <code>Share Data</code> will used for Sharing Doha onto a image and share message.
                    </CardSubtitle>
                  </div>
                </div>
                {!error &&
                  <div className="d-flex">
                    {shareImg ?
                      <div style={{ position: 'relative', width: '20%', display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                        <Label>Share Doha Image</Label>
                        <img src={shareImg ? shareImg : require("../../assets/images/bg-effect-1.jpg")} style={{ width: 256, height: 480 }} />
                        <i className=" mdi mdi-close" style={{ cursor: 'pointer', position: 'absolute', top: 25, right: 25, fontSize: 22, background: Utils.themeColor, color: 'white', height: 30, width: 30, borderRadius: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                          onClick={() => { setShareImg(null); setFile(null); }}
                        />
                      </div>
                      :
                      <div style={{ position: 'relative', width: '20%', marginLeft: 10 }}>
                        <Label>Share Doha Image</Label>
                        <div style={{ width: '100%', border: '1px dashed ' + Utils.themeColor, height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Button style={{
                            fontSize: 12, background: Utils.themeColor, color: 'white', height: 40, borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center',
                            border: 'none'
                          }}
                            onClick={() => imageInputShare.current.click()}
                          >
                            Choose Image
                          </Button>
                          <input type="file" id="file"
                            ref={imageInputShare}
                            onChange={(e) => onChangeFile(e, 'share')}
                            style={{ display: "none" }} />
                        </div>
                      </div>
                    }
                    <div style={{ position: 'relative', width: '70%', marginLeft: 10, display: 'flex', flexDirection: 'column' }}>
                      <Label> Share Message (* for bold, ~ for linethrough, _ for italic)</Label>
                      <AvForm onValidSubmit={handleValidMessage}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyItems: 'center' }}>
                          <AvField
                            type="textarea"
                            name="shareMessage"
                            rows={20}
                            value={shareMessage}
                            onChange={(e) => setShareMessage(e.target.value)}
                            placeholder="Enter Share message"
                            required
                          />
                          <Button style={{ backgroundColor: Utils.themeColor, border: 'none', marginTop: 10 }} type="submit">Save Share Message</Button>
                        </div>
                      </AvForm>
                    </div>
                  </div>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    </React.Fragment>
  )
}

// export default NotificationTable;

const mapStateToProps = state => {
  const { notis } = state.notification;
  return { notis }
}

export default withRouter(connect(mapStateToProps, {})(NotificationTable));
