import React, { useEffect, useState } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';

import Utils from '../../Utility';
import { post, get, put, download } from "../../../helpers/api_helper";
import DeleteModal from '../DeleteModal';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { AvField, AvForm } from "availity-reactstrap-validation";
import { saveAs } from 'file-saver';
import moment from "moment";
// import './product.scss';

const Table = (props) => {
  const { user, role, currentData, from, setCurrentData, setLoading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [error, setError] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (user?.token) {
      getData();
    }
  }, [user, search]);

  const getData = () => {
    get("/temple?search=" + search, { token: user?.token })
      .then(json => {
        // console.log('response from get project list', json);
        if (json?.statusCode == 200) {
          setData(json?.data);
        } else {
          toast.error(json?.error);
          setError(true);
        }
      })
      .catch(error => {
        console.log('error while getting project list', error);
      })
  }

  const handleIsPopular = (id, val) => {
    console.log('changing popular', id, val);
    const body = {
      productId: id,
      isPopular: val == "true" ? false : true,
      token: user?.token
    }
    put("/product/update", body)
      .then(res => {
        console.log('response from changing popular', res);
        if (res?.statusCode == 200) {
          toast.success(res?.message);
        } else
          toast.error(res?.erorr);
      })
      .catch(error => {
        console.log('error while changing popular', error);
        toast.error(error);
      })
  }

  useEffect(() => {
    setColumns([
      {
        name: "Image",
        cell: (row) =>
          <div style={{ position: 'relative' }}>
            <img src={row?.image} style={{ width: 80, height: 80 }} />
            <div style={{ position: 'absolute', bottom: 0, right: -10, width: 40, height: 40, borderRadius: 40, overflow: 'hidden', padding: 1, border: "1px solid white" }}>
              <img src={row?.godImage} style={{ width: 40, height: 40, resizeMode: 'stretch' }} />
            </div>
          </div>,
        maxWidth: "110px"
      },
      {
        name: 'Region',
        selector: row => row?.region,
        maxWidth: "150px",
        sortable: true,
      },
      {
        name: 'Title',
        selector: row => row.title,
        sortable: true,
      },
      // {
      //   name: 'Visits',
      //   selector: row => row?.open,
      //   maxWidth: "100px",
      //   sortable: true
      // },
      {
        name: 'Created At',
        selector: row => moment(row.createdAt).format("DD-MMM-YYYY hh:mm a"),
        sortable: true,
        maxWidth: '180px'
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        selector: row => row?.isActive,
        ignoreRowClick: true,
        sortable: true,
        maxWidth: '110px'
      },
      {
        cell: (row) => <>
          {user?.role == "Admin" ?
            <>
              <Button onClick={() => handleUpdateProj(row)}
                title={"Edit"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
              <Button onClick={() => handleDeleteProj(row)}
                title={"Delete"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
            </>
            :
            <>
              {user?.permissions?.length && user?.permissions[8]?.temple?.update &&
                <Button onClick={() => handleUpdateProj(row)}
                  title={"Edit"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
              }
              {user?.permissions?.length && user?.permissions[8]?.temple?.delete &&
                <Button onClick={() => handleDeleteProj(row)}
                  title={"Delete"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
              }
            </>}
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: '110px'
      },
    ])
  }, [data]);

  const handleOnHome = (id, val) => {
    console.log('checking on home', id, val);
    let body = {
      productId: id,
      onHome: val == "false" ? true : false,
      token: user?.token
    }
    put('/product/update', body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          if (!toast.isActive("product"))
            toast.success(json?.message, {
              toastId: "product"
            });
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          if (!toast.isActive("product"))
            toast.error(json?.error, {
              toastId: "product"
            });
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const handleUpdateProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData(proj);
    props.add(true);
  }

  const handleUpdateStatus = (item) => {
    setCurrentData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData({ ...proj, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      templeId: currentData?._id,
      token: user?.token
    }
    let url = "/temple";
    if (currentData?.actionType == 'Status') {
      body = { ...body, isActive: !currentData?.isActive };
      put(url, body)
        .then(json => {
          console.log('response from on click action', json);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getData();
            setDeleteModal(false);
            props?.setCurrentData(null);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          console.error('error while deleting user', error);
        })
    } else {
      url = "/store/product/delete"
      body = { ...body };
      post(url, body)
        .then(json => {
          console.log('response from on click action', json);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getData();
            setDeleteModal(false);
            props?.setCurrentData(null);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          console.error('error while deleting user', error);
        })
    }

  }

  const downloadZip = () => {
    setLoading(true);
    download("/product/download")
      .then(res => {
        console.log('response from download images', res);
        var blob = new Blob([res], { type: "application/octet-stream" });
        console.log('after blob', blob);
        setLoading(false);
        saveAs(blob, `store${new Date().getTime()}.zip`);
      })
      .catch(err => {
        console.log('error while downloading image', err);
      })
  }

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            data={currentData}
          />
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <div>
                    <CardTitle className="h4">{props.role}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {role}s you have added before,<code> Anybody</code> can <code>check</code> and <code>visit</code> on these. if <code>Status</code> is Active.
                    </CardSubtitle>
                  </div>
                  {user?.role == "Admin" ?
                    <div className="text-center" style={{ marginLeft: 'auto' }}>
                      <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => props?.add(true)}>
                        Add
                      </Button>
                    </div>
                    :
                    <>
                      {user?.permissions?.length && user?.permissions[8]?.temple?.write ?
                        <div className="text-center" style={{ marginLeft: 'auto' }}>
                          <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => props?.add(true)}>
                            Add
                          </Button>
                        </div>
                        : null}
                    </>
                  }
                </div>
                <Row className="my-3">
                  <Col md={8}>
                    <AvForm>
                      <div>
                        <AvField
                          name="search"
                          label="Search"
                          placeholder="Search by title and region"
                          type="text"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        >
                        </AvField>
                      </div>
                    </AvForm>
                  </Col>
                </Row>
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  conditionalRowStyles={[{
                    when: row => row?.style,
                    style: row => ({ width: row?.style?.width }),
                  },
                  ]}
                  customStyles={{
                    headCells: {
                      style: {
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: 15,
                        width: 0
                      },
                    },
                    cells: {
                      style: {
                        width: 0
                      }
                    }
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    </React.Fragment>
  )
}

export default Table;
