import PropTypes from 'prop-types'
import React from "react"
import { Col, Modal, ModalBody, Row } from "reactstrap"
import Utils from '../Utility';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import Card from '../Notification/Card';


const ReplyModal = ({ show, onReplyClick, onCloseClick, data }) => {
    console.log("=>>=>", data)
    return (
        <Modal isOpen={show} style={{ width: '80%' }} className="custom-modal-body" toggle={onCloseClick} centered={true}>
            <ModalBody className="py-3 px-5 " >
                <Row>
                    <Col lg={12}>
                        <div className="text-start">
                            <h4>User Enquiry</h4>

                            <div className='para'>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col md={2}>
                                                <p>User - </p>
                                            </Col>
                                            <Col>
                                                <p style={{ color: 'black' }}>{data?.user[0]?.name}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={2}>
                                                <p>Email - </p>
                                            </Col>
                                            <Col>
                                                <p style={{ color: 'black' }}>{data?.user[0]?.email}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={2}>
                                                <p>Mobile - </p>
                                            </Col>
                                            <Col>
                                                <p style={{ color: 'black' }}>{data?.user[0]?.mobile}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>

                            <div className="mt-4">
                                <p style={{ color: 'black' }}>
                                    {data?.
                                        message}
                                </p>
                            </div>

                            <AvForm
                                className="form-horizontal"
                                onValidSubmit={(e, v) => {
                                    onReplyClick(v)
                                }}
                            >
                                <AvField
                                    name="reply"
                                    label={"Reply"}
                                    value={data?.reply}
                                    className="form-control"
                                    placeholder={`Enter Message`}
                                    type="textarea"
                                    rows={4}
                                // required
                                />
                                <div className="mt-4">
                                    <Row>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                                                onClick={onCloseClick}
                                                type="reset"
                                            >
                                                Cancel
                                            </button>
                                        </Col>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                type="submit"
                                                style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                                            >
                                                {data ? 'Update' : 'Add'}
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </AvForm>
                        </div>
                    </Col>
                </Row>

            </ModalBody>
        </Modal>
    )
}

ReplyModal.propTypes = {
    onCloseClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    show: PropTypes.any
}

export default ReplyModal
