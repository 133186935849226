import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts"
import {
    Card,
    CardBody,
} from "reactstrap";

const LineChart = (props) => {

    const { data, time } = props;
    // console.log('open array on line chart', open);
    const [screens, setScreens] = useState([]);
    const [series, setSeries] = useState([]);

    useEffect(() => {
        if (data)
            modifyOpenRecords();
    }, [props]);

    const modifyOpenRecords = () => {
        // console.log(monthN);
        let screensTemp = [];
        let success = [], pending = [], cancelled = [], failed = [];
        data?.map((item) => {
            // console.log('item of screen', item);
            if (item?._id)
                screensTemp.push(item?._id);
            success.push(item?.statuses[0]?.count);
            failed.push(item?.statuses[3]?.count);
            cancelled.push(item?.statuses[2]?.count);
            pending.push(item?.statuses[1]?.count);
        });
        setScreens(screensTemp);
        setSeries([
            {
                name: "Success",
                data: success
            },
            {
                name: "Pending",
                data: pending
            },
            {
                name: "Cancelled",
                data: cancelled
            },
            {
                name: "Failed",
                data: failed
            },
        ])
    }

    const options = {
        chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
                show: true
            },
            zoom: {
                enabled: true
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 10,
                    dataLabels: {
                        total: {
                            enabled: true,
                            style: {
                                fontSize: '13px',
                                fontWeight: 900
                            }
                        }
                    }
                },
            }
        },
        colors: ['#026e1d', '#d19b04', "#ff0000", "#820000"],
        dataLabels: {
            enabled: true,
        },
        // stroke: {
        //     curve: 'smooth',
        //     width: '3',
        //     dashArray: [4, 0],
        // },

        // markers: {
        //     size: 3
        // },
        xaxis: {
            categories: screens,
            title: {
                text: 'Status'
            }
        },
        fill: {
            type: 'solid',
            opacity: 1,
        },

        legend: {
            position: 'top',
            horizontalAlign: 'left',
        }
    }

    return (
        <React.Fragment >
            <Card>
                <CardBody>
                    <h4 className="card-title mb-4">Payment Report</h4>
                    <div id="chart">
                        <ReactApexChart
                            options={options}
                            series={series}
                            height="260"
                            type="bar"
                        // className="apex-charts"
                        />
                    </div>
                </CardBody>
            </Card>
        </React.Fragment >
    )
}

export default LineChart