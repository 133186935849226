import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardTitle,
  CardSubtitle,
  CardBody,
  Button,
  Modal,
  ModalBody
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";

// availity-reactstrap-validation

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility'

import avatar from "../../assets/images/companies/img-4.png"
// actions
import { profileSuccess, getProfile } from '../../store/auth/profile/actions';
import Table from './Table';
import Loader from "../../components/Loader";
import Single from './Single';
import DataTable from 'react-data-table-component';
import { del, get, post, put, upload } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import DeleteModal from './DeleteModal';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";


const Hotels = props => {
  const { user } = props;
  const [isTable, setIsTable] = useState(true);
  const [isAddForm, setIsAddForm] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [path, setPath] = useState("bank");
  const [singleId, setSingleId] = useState(null);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [file, setFile] = useState(null);
  const cropper = useRef();

  // console.log('props on company', props);

  useEffect(() => {
    if (user?.token) {
      getData();
    }
  }, [user?.token]);


  const getData = () => {
    get("/blog/category", { token: user?.token })
      .then(res => {
        if (res?.statusCode == 200) {
          setData(res?.data);
        } else {
          toast.error(res?.error);
        }
      })
  }

  useEffect(() => {
    if (!isAddForm) {
      setCurrentData(null);
      setFile(null);
    }
  }, [isAddForm])

  useEffect(() => {
    if (data) {
      setColumns([
        {
          name: 'Title',
          selector: row => row?.title,
        },
        {
          name: 'Blogs',
          selector: row => row?.blogs?.length,
        },
        {
          cell: (row) => <>
            <Button onClick={() => handleUpdateStatus(row)}
              title={row?.isActive ? "Inactive" : "Active"}
              style={{ backgroundColor: !row.isActive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
              <span className="text-white" style={{}}>
                {!row?.isActive ? 'Inactive' : 'Active'}
              </span>
            </Button>
          </>,
          name: 'Status',
          ignoreRowClick: true,
          maxWidth: '120px'
        },
        {
          cell: (row) => <>
            {user?.role == 'Admin' ?
              <>
                <Button onClick={() => handleUpdateEdit(row)}
                  title={"Edit"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
                  <span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
                <Button onClick={() => handleDelete(row)}
                  title={"Delete"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
                  <span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
              </>
              :
              <>
                {user?.permissions?.length && user?.permissions[16]?.blog?.update &&
                  <Button onClick={() => handleUpdateEdit(row)}
                    title={"Edit"}
                    style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
                    <span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
                }
                {user?.permissions?.length && user?.permissions[16]?.blog?.delete &&
                  <Button onClick={() => handleDelete(row)}
                    title={"Delete"}
                    style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
                    <span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
                }
              </>
            }
          </>,
          name: 'Action',
          ignoreRowClick: true,
          maxWidth: '120px'
        },
      ])
    }
  }, [data]);

  const handleValidSubmit = async (e, v) => {
    setLoading(true);
    if (currentData) {
      let body = { ...v, categoryId: currentData?._id, token: user?.token };
      // try {
      //   const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
      //   if (fileData) {
      //     const fileObj = await urltoFile(fileData, (new Date().getTime() + 300) + '.png', 'image/png')
      //     const image2 = await resizeFile(fileObj);
      //     const form2 = new FormData();
      //     form2.append("image", image2);
      //     const uploadedBanner = await upload("/category/image_upload", form2)
      //     if (uploadedBanner?.statusCode == 200)
      //       body.image = uploadedBanner?.data;
      //   }
      // } catch (error) {
      //   console.log('error while getting canvas', error);
      // }
      put("/blog/category", body)
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            setCurrentData(null);
            setIsAddForm(false);
            toast.success(res?.message);
            setFile(null);
            getData();
          } else {
            toast.error(res?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          toast.error(error);
        })
    } else {
      let body = {
        ...v, token: user?.token
      }
      // try {
      //   const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
      //   if (fileData) {
      //     const fileObj = await urltoFile(fileData, (new Date().getTime() + 300) + '.png', 'image/png')
      //     const image2 = await resizeFile(fileObj);
      //     const form2 = new FormData();
      //     form2.append("image", image2);
      //     const uploadedBanner = await upload("/category/image_upload", form2)
      //     console.log('response from image upload', uploadedBanner);
      //     if (uploadedBanner?.statusCode == 200)
      //       body.image = uploadedBanner?.data;
      //   }
      // } catch (error) {
      //   console.log('error while getting canvas', error);
      // }
      post("/blog/category", body)
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            setCurrentData(null);
            setIsAddForm(false);
            toast.success(res?.message);
            getData();
          } else {
            toast.error(res?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          toast.error(error);
        })
    }
  }

  const handleUpdateEdit = (data) => {
    setCurrentData(data);
    setFile(data?.image);
    setIsAddForm(true);
  }

  const handleDelete = (data) => {
    setCurrentData({ ...data, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const handleUpdateStatus = (data) => {
    if (user?.role == "Admin") {
      setCurrentData({ ...data, actionType: 'Status' });
      setDeleteModal(true);
    } else
      toast.error("You're not authorized for this action!");
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      categoryId: currentData?._id,
      token: user?.token
    }
    del(`/blog/category`, body)
      .then(json => {
        console.log('response from delete user', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          setCurrentData(null);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const onStatus = () => {
    console.log('status', currentData);
    setLoading(true);
    let body = {
      categoryId: currentData?._id,
      isActive: !currentData?.isActive,
      token: user?.token
    }
    let url = `/blog/category`;
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1800,
        600,
        "webp",
        60,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const onChangeFile = (e, to) => {
    setFile(URL.createObjectURL(e.target.files[0]));
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => currentData?.actionType == 'Status' ? onStatus() : onDelete()}
          onCloseClick={() => setDeleteModal(false)}
          actionType={currentData?.actionType}
          data={currentData}
        />
        {/* Render Breadcrumb */}
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Blog Category" />
        <Card>
          <CardBody>
            <Modal className="modal_form" isOpen={isAddForm} toggle={() => { setIsAddForm(false) }} centered={true}>
              <ModalBody className="py-3 px-5">
                <h5 className="text-black font-size-20">{currentData ? 'Update Blog Category!' : 'Add New Blog Category!'}</h5>
                <div className="p-2">
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <AvField
                            name="title"
                            label="Title"
                            className="form-control"
                            placeholder="Enter title"
                            type="text"
                            required
                            value={currentData?.title}
                          />
                        </div>
                      </Col>
                      {/* <Col lg={12} md={12}>
                        <div className="mb-3 mt-2">
                          {file ?
                            <>
                              <CardTitle >Image</CardTitle>
                              <div style={{ flexDirection: 'row', position: 'relative', cursor: 'pointer', marginTop: 20 }}>
                                {currentData?.image ?
                                  <img src={file} style={{ width: 400, height: 'auto' }} />
                                  :
                                  <Cropper
                                    style={{ height: 'auto', width: 400 }}
                                    aspectRatio={100 / 100}
                                    preview=".img-preview"
                                    guides={true}
                                    src={file}
                                    ref={cropper}
                                  />
                                }
                                <i className="mdi mdi-close" style={{
                                  color: 'white', backgroundColor: Utils.themeColor, fontSize: 20,
                                  position: 'absolute', top: -16, right: -28, borderRadius: 15, width: 30, height: 30, textAlign: 'center'
                                }}
                                  onClick={() => { setFile(null); if (currentData) setCurrentData({ ...currentData, image: undefined }) }}
                                />
                              </div>
                            </>
                            :
                            <AvField
                              name="fileInput"
                              label="Image"
                              className="form-control"
                              placeholder="upload list image"
                              type="file"
                              onChange={e => onChangeFile(e, 'image')}
                              multiple={false}
                              accept="image/png, image/jpeg"
                            />
                          }
                        </div>
                      </Col> */}
                      <Col lg={12} md={12}>
                        <div className="mt-4">
                          <Row>
                            <Col md={6}>
                              <button
                                className="btn btn-primary w-100 waves-effect waves-light"
                                style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                                onClick={() => { setIsAddForm(false) }}
                                type="reset"
                              >
                                Cancel
                              </button>
                            </Col>
                            <Col md={6}>
                              <button
                                className="btn btn-primary w-100 waves-effect waves-light"
                                type="submit"
                                style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                              >
                                {currentData ? 'Update' : 'Add'}
                              </button>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </div>
              </ModalBody>
            </Modal>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                    <div>
                      <CardTitle className="h4">{props.role}</CardTitle>
                      <CardSubtitle className="mb-3">
                        Category you have added before, All the <code>blogs </code> will be added accordingly by Category.
                      </CardSubtitle>
                    </div>
                  </div>
                  <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                    <h5>Category</h5>
                    <div className="text-center" style={{ marginLeft: 'auto' }}>
                      <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                        Add
                      </Button>
                    </div>
                  </div>
                  <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    customStyles={{
                      headCells: {
                        style: {
                          color: 'black',
                          fontWeight: 'bold',
                          fontSize: 15,
                          maxWidth: 80
                        },
                      },
                      cells: {
                        style: {
                          maxWidth: 50
                        }
                      }
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

Hotels.propTypes = {
  getProfile: PropTypes.func,
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStatetoProps, { getProfile })(Hotels)
)
