import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";

const SalesAnalytics = (props) => {
  const { source } = props;
  const [series, setSeries] = useState([38, 26, 14]);
  const [options, setOptions] = useState({
    labels: [],
    plotOptions: {
      pie: {
        donut: {
          size: '15%'
        }
      }
    },
    legend: {
      show: false,
    },
    colors: ['#3b5de7', '#45cb85', '#eeb902', '#ff0000', '#ff33cc', '#009999', '#b6b6b6', '#cdfa12'],
  });

  useEffect(() => {
    if (source) {
      modifyData();
    }
  }, [source]);

  const modifyData = () => {
    let labels = [], seriesTemp = [];
    source.map((item) => {
      labels.push(item?._id);
      seriesTemp.push(item?.count);
    });
    setOptions({ ...options, labels });
    setSeries(seriesTemp);
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">Users By Device</h4>

          <Row className="align-items-center" style={{ height: '100%' }}>
            <Col sm={5}>
              <div id="chart">
                <ReactApexChart
                  options={options}
                  series={series}
                  type="donut"
                  height={187}
                // className="apex-charts"
                />
              </div>
            </Col>
            <Col sm={6}>
              <div>
                <Row>
                  {source.map((item, index) => {
                    if (index < 8)
                      return (
                        <div className="col-12" key={item?._id}>
                          <div className="py-0" style={{ textAlignLast: 'center', display: 'flex', fontSize: 12 }}>
                            <p className="mb-1 text-truncate" style={{ textTransform: 'capitalize' }}><i
                              className="mdi mdi-circle me-1" style={{ color: options?.colors[index] }}></i>{" "}{item?._id}
                            </p>
                            <h5 style={{ fontSize: 12, marginLeft: 5 }}>({item?.count})</h5>
                          </div>
                        </div>
                      )
                  })}
                  {/* <div className="col-6">
                    <div className="py-3">
                      <p className="mb-1 text-truncate"><i
                        className="mdi mdi-circle text-success me-1"></i>{" "}Offline</p>
                      <h5>$ 2,284</h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="py-3">
                      <p className="mb-1 text-truncate"><i
                        className="mdi mdi-circle text-warning me-1"></i>{" "}Marketing</p>
                      <h5>$ 1,753</h5>
                    </div>
                  </div> */}
                </Row>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default SalesAnalytics;
