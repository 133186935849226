import React, { useEffect, useState } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';

import Utils from '../../Utility';
import { post, get, put, download } from "../../../helpers/api_helper";
import DeleteModal from '../DeleteModal';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { AvField, AvForm } from "availity-reactstrap-validation";
import { saveAs } from 'file-saver';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import moment from "moment";

const Table = (props) => {
  const { user, role, currentData, from, setCurrentData, setLoading, setIsAddForm } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [error, setError] = useState(false);
  const [search, setSearch] = useState("");
  const [tagModal, setTagModal] = useState(false);

  useEffect(() => {
    if (user?.token) {
      getData();
    }
  }, [user, search]);

  const getData = () => {
    get("/story/admin/list?search=" + search, { token: user?.token })
      .then(json => {
        // console.log('response from get project list', json);
        if (json?.statusCode == 200) {
          setData(json?.data);
        } else {
          toast.error(json?.error);
          setError(true);
        }
      })
      .catch(error => {
        console.log('error while getting project list', error);
      })
  }

  useEffect(() => {
    setColumns([
      {
        name: "Position",
        cell: (row, index) => <>
          <Draggable key={row._id} draggableId={row._id} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <i className="mdi mdi-menu" style={{ fontSize: 20, color: Utils.themeColor }}></i>
              </div>
            )}
          </Draggable>
        </>,
        maxWidth: '50px'
      },
      {
        name: 'Image',
        cell: (row) => <div className="d-flex align-items-center">
          <img title={'banner'} src={row?.image} style={{ width: 40, height: 'auto', marginTop: 5, marginBottom: 5 }} />
          {row?.isPrimary &&
            <i className="mdi mdi-alpha-p" style={{ marginLeft: 10, fontSize: 20, color: Utils.themeColor }}></i>
          }
          {row?.isSecondary &&
            <i className="mdi mdi-alpha-s" style={{ marginLeft: row?.isPrimary ? -5 : 10, fontSize: 20, color: Utils.themeColor }}></i>
          }
        </div>,
        maxWidth: "80px"
      },
      {
        name: 'Category',
        selector: row => row?.category[0]?.titleEn,
        // maxWidth:"220px"
      },
      {
        name: 'Title En',
        selector: row => row.titleEn,
      },
      {
        name: 'Title Hi',
        selector: row => row.titleHi,
      },
      {
        name: 'Created At',
        selector: row => moment(row.createdAt).format("DD-MMM-YYYY hh:mm a"),
        sortable: true,
        maxWidth: "180px"
      },
      {
        name: 'Tag',
        selector: row => row?.tag,
        cell: (row) => <>
          <Button onClick={() => handleUpdateTag(row)}
            title={row?.tag}
            style={{ backgroundColor: 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {row?.tag ? row?.tag : " - "}
            </span>
          </Button>
        </>,
        sortable: true,
        maxWidth: '120px'
      },
      {
        name: 'Views',
        selector: row => row?.count,
        cell: row => <div>
          <AvForm>
            <AvField
              name="count"
              value={row?.count}
              onChange={e => handleCountChange(row?._id, e.target.value)}
            />
          </AvForm>
        </div>,
        maxWidth: "120px"
      },
      {
        name: 'Likes',
        selector: row => row?.likes,
        cell: row => <div>
          <AvForm>
            <AvField
              name="likes"
              value={row?.likes}
              onChange={e => handleLikeChange(row?._id, e.target.value)}
              disabled={row?.likes < 4}
            />
          </AvForm>
        </div>,
        maxWidth: "120px"
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateStatus(row)}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
        maxWidth: "110px"
      },
      {
        cell: (row) => <>
          {user?.role == "Admin" ?
            <>
              <Button onClick={() => handleUpdateProj(row)}
                title={"Edit"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
              <Button onClick={() => handleDeleteProj(row)}
                title={"Delete"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
            </>
            :
            <>
              {user?.permissions?.length && user?.permissions[5]?.story?.update &&
                <Button onClick={() => handleUpdateProj(row)}
                  title={"Edit"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
              }
              {user?.permissions?.length && user?.permissions[5]?.story?.delete &&
                <Button onClick={() => handleDeleteProj(row)}
                  title={"Delete"}
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
              }
            </>}
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: "100px"
      },
    ])
  }, [data]);

  const handleOnHome = (id, val) => {
    console.log('checking on home', id, val);
    let body = {
      productId: id,
      onHome: val == "false" ? true : false,
      token: user?.token
    }
    put('/product/update', body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          if (!toast.isActive("product"))
            toast.success(json?.message, {
              toastId: "product"
            });
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          if (!toast.isActive("product"))
            toast.error(json?.error, {
              toastId: "product"
            });
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const handleUpdateProj = (proj) => {
    // console.log('updating proj', proj);
    setCurrentData(proj);
    setIsAddForm(true);
  }

  const handleUpdateStatus = (item) => {
    console.log('updating proj', item);
    setCurrentData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData({ ...proj, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const handleUpdateTag = (row) => {
    setCurrentData(row);
    setTagModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      storyId: currentData?._id,
      token: user?.token
    }
    let url = "/story/update";
    if (currentData?.actionType == 'Status') {
      body = { ...body, isActive: !currentData?.isActive };
      put(url, body)
        .then(json => {
          console.log('response from on click action', json);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getData();
            setDeleteModal(false);
            props?.setCurrentData(null);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          console.error('error while deleting user', error);
        })
    } else {
      url = "/story/update"
      body = { ...body, isDeleted: true };
      put(url, body)
        .then(json => {
          console.log('response from on click action', json);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getData();
            setDeleteModal(false);
            props?.setCurrentData(null);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          console.error('error while deleting user', error);
        })
    }

  }

  const downloadZip = () => {
    setLoading(true);
    download("/product/download")
      .then(res => {
        console.log('response from download images', res);
        var blob = new Blob([res], { type: "application/octet-stream" });
        console.log('after blob', blob);
        setLoading(false);
        saveAs(blob, `store${new Date().getTime()}.zip`);
      })
      .catch(err => {
        console.log('error while downloading image', err);
      })
  }

  const handleCountChange = (id, val) => {
    let body = {
      storyId: id,
      count: val,
      token: user?.token
    }
    put("/story/update", body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          // getData();
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const handleLikeChange = (id, val) => {
    let body = {
      storyId: id,
      likes: val,
      token: user?.token
    }
    put("/story/update", body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          // getData();
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const handleDragEnd = (result) => {
    // console.log('dragging item', result);
    // Handle the drag-and-drop logic here
    if (!result.destination) return; // Item was dropped outside the list
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/story/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };

  const handleValidSubmitTag = (e, v) => {
    let body = {
      storyId: currentData?._id,
      tag: v?.tag,
      token: user?.token
    }
    put("/story/update", body)
      .then(res => {
        setLoading(false);
        if (res?.statusCode == 200) {
          getData();
          setTagModal(false);
        }
      })
      .catch(err => {
        setLoading(false);
        console.log("error while updating tag", err);
      })
  }

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            data={currentData}
          />
          <Modal isOpen={tagModal} centered={true}>
            <ModalBody className="py-3 px-5">
              <h5 className="text-black font-size-20">Update Tag</h5>
              <div className="p-2">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={handleValidSubmitTag}
                >
                  <Row>
                    <Col lg={12} md={12}>
                      <div className="mb-3">
                        <AvField
                          name="tag"
                          label="Tag *"
                          className="form-control"
                          placeholder="Enter tag"
                          type="text"
                          value={currentData?.tag}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="mt-4">
                    <Row>
                      <Col md={6}>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                          onClick={() => { setTagModal(false); }}
                          type="reset"
                        >
                          Cancel
                        </button>
                      </Col>
                      <Col md={6}>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                          style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                        >
                          {currentData ? 'Update' : 'Add'}
                        </button>
                      </Col>
                    </Row>
                  </div>
                </AvForm>
              </div>
            </ModalBody>
          </Modal>
          <Col className="col-12">
            <Card>
              <CardBody>
                {from != 'Dashboard' &&
                  <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                    <div>
                      <CardTitle className="h4">{props.role}</CardTitle>
                      <CardSubtitle className="mb-3">
                        Stories you have added before,<code> Anybody</code> can <code>check</code> and <code>Listen</code> on these. if <code>Status</code> is Active.
                      </CardSubtitle>
                    </div>
                    {user?.role == "Admin" ?
                      <div className="text-center" style={{ marginLeft: 'auto' }}>
                        <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                          Add
                        </Button>
                      </div>
                      :
                      <>
                        {user?.permissions?.length && user?.permissions[5]?.story?.write ?
                          <div className="text-center" style={{ marginLeft: 'auto' }}>
                            <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                              Add
                            </Button>
                          </div>
                          : null}
                      </>
                    }
                  </div>
                }
                <Row className="my-3">
                  <Col md={8}>
                    <AvForm>
                      <div>
                        <AvField
                          name="search"
                          label="Search"
                          placeholder="Search by title and category"
                          type="text"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        >
                        </AvField>
                      </div>
                    </AvForm>
                  </Col>
                </Row>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="table">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <DataTable
                          columns={columns}
                          data={data}
                          pagination
                          paginationPerPage={30}
                          paginationRowsPerPageOptions={[30, 60, 90, 120]}
                          customStyles={{
                            headCells: {
                              style: {
                                color: 'black',
                                fontWeight: 'bold',
                                fontSize: 15,
                                width: 0
                              },
                            },
                            cells: {
                              style: {
                                width: 0
                              }
                            }
                          }}
                        />
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    </React.Fragment>
  )
}

export default Table;
