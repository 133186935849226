import React, { useEffect, useState } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody, Label } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';

import Utils from '../Utility';
import { post, get, put } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { AvField, AvForm } from "availity-reactstrap-validation";
import './style.css';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Link } from 'react-router-dom';
const { io } = require("socket.io-client");

const Table = (props) => {
  const { user, role, from, path, loading, setLoading, view } = props;
  const [columns, setColumns] = useState([]);
  const [naamdata, setNaamData] = useState([]);
  const [ramData, setRamData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isNaamForm, setIsNaamModal] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [currentTab, setCurrentTab] = useState("ram");
  const [messageEnd, setMessagesEnd] = useState(null);
  const [isTempForm, setIsTempForm] = useState(false);
  const [tempUser, setTempUser] = useState([]);
  const [tempCity, setTempCity] = useState([]);
  const [socket, setSocket] = useState(undefined);
  const [totalCounter, setTotalCounter] = useState({ english: '0', hindi: '0' });
  const [topList, setTopList] = useState([]);
  const [naamCount, setNaamCount] = useState("");
  const [naamTime, setNaamTime] = useState("");

  useEffect(() => {
    if (user) {
      getData();
    }
  }, [user]);

  const getData = () => {
    if (!loading) {
      setLoading(true);
      let url = "/ram/type";
      get(url, { token: user?.token })
        .then(json => {
          // console.log('response from get project list', json);
          if (json?.statusCode == 200) {
            setNaamData(json?.data);
            get("/ram/top_list", { token: user?.token })
              .then(json => {
                // console.log('response from get project list', json);
                if (json?.statusCode == 200) {
                  setTopList(json?.data);
                }
              })
              .catch(error => {
                console.log('error while getting project list', error);
              });
            get("/setting/", { token: user?.token })
              .then(json => {
                setLoading(false);
                // console.log('response from get project list', json);
                if (json?.statusCode == 200) {
                  setNaamCount(json?.data?.naamCount);
                  setNaamTime(json?.data?.naamTime);
                }
              })
              .catch(error => {
                setLoading(false);
                console.log('error while getting project list', error);
              });
            const socket = io(Utils.API_URL + "/ram", { transports: ["websocket"], upgrade: false });
            socket.on("get", (res) => {
              console.log('socket on list', res);
              if (res?.statusCode == 200) {
                console.log(res?.data)
                setRamData(res?.data);
                setTotalCounter(res?.counter);
              }
            });
            socket.on("connect", (socket2) => {
              console.log('socket connect', socket2);
              socket.emit("list", { auth: user?.token });
            });
            setSocket(socket);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while getting project list', error);
        });
    }
  }

  useEffect(() => {
    if (currentTab == 'type') {
      setColumns([
        {
          name: 'TitleEn',
          selector: row => row?.titleEn,
        },
        {
          name: 'TitleHi',
          selector: row => row?.titleHi,
        },
        {
          name: 'Used',
          selector: row => row?.used ? row?.used : "-",
        },
        {
          cell: (row) => <>
            <Button onClick={() => handleUpdateProj(row)}
              title={"Edit"}
              style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={"mdi mdi-pencil"} style={{ fontSize: 20 }}></span></Button>
            <Button onClick={() => handleDeleteProj(row)}
              title={"Delete"}
              style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={"mdi mdi-delete"} style={{ fontSize: 20 }}></span></Button>
          </>,
          name: 'Action',
          ignoreRowClick: true,
        }
      ]);
    } else {
      setColumns([
        {
          name: 'Name',
          selector: row => row?.name,
        },
        {
          cell: (row) => <>
            {from != 'Dashboard' &&
              <>
                <Button onClick={() => handleDeleteProj(row)}
                  title="Delete"
                  style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={"mdi mdi-delete"} style={{ fontSize: 20 }}></span></Button>
              </>
            }
          </>,
          name: 'Action',
          ignoreRowClick: true,
        }
      ]);
    }
  }, [naamdata, currentTab]);

  const handleUpdateProj = (proj) => {
    console.log('updating proj', proj);
    setCurrentData(proj);
    setIsNaamModal(true);
  }

  const handleUpdateStatus = (item) => {
    console.log('updating proj', item);
    setCurrentData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj) => {
    console.log('updating proj', proj);
    setCurrentData({ ...proj, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      naamId: currentData?._id,
      token: user?.token
    }
    let url = "/ram/type/delete";
    if (currentTab == 'arji') {
      body = {
        deleteId: currentData?._id,
        token: user?.token
      };
      url = "/user/temp/delete";
    }
    post(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const handleValidSubmit = (e, v) => {
    setLoading(true);
    let body = {
      ...v,
      token: user?.token
    }
    if (currentData) {
      body = { ...body, naamId: currentData?._id };
      put('/ram/type/update', body)
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            toast.success(res?.message);
            getData();
            setIsNaamModal(false);
          } else
            toast.error(res?.error);
        })
        .catch(err => {
          console.log('error while adding money to user account!', err);
          setLoading(false);
        })
    } else {
      post('/ram/type/add', body)
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            toast.success(res?.message);
            getData();
            setIsNaamModal(false);
          } else
            toast.error(res?.error);
        })
        .catch(err => {
          console.log('error while adding money to user account!', err);
          setLoading(false);
        })
    }
  }

  const submitNameCity = (e, v) => {
    let names = [], cities = [];
    v?.name?.split(",").forEach(element => {
      if (element)
        names.push(element?.trim());
    });
    v?.city?.split(",").forEach(element => {
      if (element)
        cities.push(element?.trim());
    });
    console.log('names after split', names, cities);
    let body = {
      token: user?.token
    };
    if (names?.length > 0)
      body = { ...body, names };
    if (cities?.length > 0)
      body = { ...body, cities };
    post('/user/add', body)
      .then(res => {
        if (res?.statusCode == 200) {
          toast.success(res?.message);
          setIsTempForm(false);
        }
      })
      .catch(err => {
        console.log('error while adding temp names', err);
      })
  }

  const hanldeNaamCount = (val) => {
    if (val > 107) {
      const body = {
        naamCount: val,
        token: user?.token
      }
      put("/setting/update", body)
        .then(res => {
          if (res?.statusCode == 200) {
            toast.success(res?.message);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          toast.error(res?.err);
        })
    } else {
      toast.error("Naam Count must be more than 108!")
    }
  }

  const handleNaamTime = (min) => {
    if (min != "") {
      const body = {
        naamTime: min,
        token: user?.token
      }
      put("/setting/update", body)
        .then(res => {
          if (res?.statusCode == 200) {
            toast.success(res?.message);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          toast.error(res?.err);
        })
    }
  }

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            data={currentData}
          />
          {/* Add money to user account popup */}
          <Modal className="modal_form" isOpen={isNaamForm} toggle={() => { setIsNaamModal(false); setCurrentData(null) }} centered={true}>
            <ModalBody className="py-3 px-5">
              <h5 className="text-black font-size-20">{currentData ? "Update Naam" : "Add new Naam"}</h5>
              <div className="p-2">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                  }}
                >
                  <Row>
                    <Col lg={12} md={12}>
                      <div className="mb-3 mt-2">
                        <AvField
                          name="titleEn"
                          label="TitleEn"
                          className="form-control"
                          placeholder="Enter title in English"
                          required
                          value={currentData?.titleEn}
                        // validate={{min:{value:1}}}
                        />
                      </div>
                      <div className="mb-3 mt-2">
                        <AvField
                          name="titleHi"
                          label="TitleHi"
                          className="form-control"
                          placeholder="Enter title in Hindi"
                          required
                          value={currentData?.titleHi}
                        // validate={{min:{value:1}}}
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <div className="mt-4">
                        <Row>
                          <Col md={6}>
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                              onClick={() => { setIsNaamModal(false); setCurrentData(null) }}
                              type="reset"
                            >
                              Cancel
                            </button>
                          </Col>
                          <Col md={6}>
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              type="submit"
                              style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                            >
                              Submit
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </div>
            </ModalBody>
          </Modal>
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="d-flex mb-5" style={{ marginLeft: 'auto', justifyContent: 'space-between' }}>
                  <div>
                    <CardTitle className="h4">{props.role}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {role} written globally by All the <code>Users</code>.
                    </CardSubtitle>
                  </div>
                  <div style={{ marginRight: 20, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <h4>Total</h4>
                    <p style={{ fontWeight: 'bold', fontSize: 20 }}>{totalCounter?.english} ({totalCounter?.hindi})</p>
                  </div>
                  {naamdata?.length > 0 &&
                    <div style={{ marginRight: 20 }}>
                      <AvForm>
                        <div style={{ display: 'flex' }}>
                          <AvField
                            name="count"
                            label="Naam Interval Count"
                            placeholder="Can continuously emit"
                            onChange={(e) => hanldeNaamCount(e.target.value)}
                            value={naamCount}
                            validate={{ min: { value: 108 } }}
                          />
                          <div style={{ marginLeft: 20 }}>
                            <AvField
                              name="time"
                              label="Naam Interval Time"
                              placeholder="Enter waiting minutes"
                              onChange={(e) => handleNaamTime(e.target.value)}
                              value={naamTime}
                            />
                          </div>
                        </div>
                      </AvForm>
                    </div>
                  }
                </div>
                <Row>
                  <Col lg={4} className="pe-0">
                    {currentTab == 'type' ?
                      <div className="tab-active">
                        <span>Ram Naam Types</span>
                      </div>
                      :
                      <div className="tab" onClick={() => setCurrentTab("type")}>
                        <span>Ram Naam Types</span>
                      </div>
                    }
                  </Col>
                  <Col lg={4} className="pe-0 ps-0">
                    {currentTab == 'ram' ?
                      <div className="tab-active">
                        <span>Ram Naam Live</span>
                      </div>
                      :
                      <div className="tab" onClick={() => setCurrentTab("ram")}>
                        <span>Ram Naam Live</span>
                      </div>
                    }
                  </Col>
                  {/* <Col lg={4} className="ps-0">
                    {currentTab == 'arji' ?
                      <div className="tab-active">
                        <span>Ram Naam Arji</span>
                      </div>
                      :
                      <div className="tab" onClick={() => setCurrentTab("arji")}>
                        <span>Ram Naam Arji</span>
                      </div>
                    }
                  </Col> */}
                  <Col lg={4} className="ps-0">
                    {currentTab == 'board' ?
                      <div className="tab-active">
                        <span>Ram Naam Top List</span>
                      </div>
                      :
                      <div className="tab" onClick={() => setCurrentTab("board")}>
                        <span>Ram Naam Top List</span>
                      </div>
                    }
                  </Col>
                </Row>
                {currentTab == 'type' &&
                  <>
                    <Row className="mt-4">
                      <Col lg={10}></Col>
                      <Col lg={2} className="tab-active-type tab-active-ram" style={{ cursor: 'pointer' }} onClick={() => setIsNaamModal(true)}>
                        <span>Add New Naam</span>
                      </Col>
                    </Row>
                    <DataTable
                      columns={columns}
                      data={naamdata}
                      pagination
                      customStyles={{
                        headCells: {
                          style: {
                            color: 'black',
                            fontWeight: 'bold',
                            fontSize: 15,
                            maxWidth: 80
                          },
                        },
                        cells: {
                          style: {
                            maxWidth: 50
                          }
                        }
                      }}
                    />
                  </>
                }
                {currentTab == 'ram' &&
                  <>
                    <div style={{ height: 500, overflow: 'scroll', marginTop: 30 }}>
                      <div id="messages">
                        {ramData?.length < 1 ?
                          <span style={{ position: 'absolute', top: '50%', left: '45%', fontSize: 12 }} >No Messages!</span>
                          :
                          <div style={{}} ref={(el) => { setMessagesEnd(el) }}>
                            {ramData?.map((item, index) => {
                              return (
                                <Card key={item?._id}>
                                  <div style={{}}>
                                    <div style={{ float: 'right' }}>
                                      <div className="my-msg">
                                        <div style={{ textAlign: 'right', margin: 'auto' }}>
                                          <div className="msg-view">
                                            <span className="msg"
                                              style={{ backgroundColor: Utils.themeColor }}
                                            > {item?.msg} </span>
                                          </div>
                                          <span className="my-time">{item?.createdAt}</span>
                                        </div>
                                        <div style={{ textAlign: '-webkit-center', margin: 'auto' }}>
                                          <div style={{ border: `1px solid ${Utils.themeColor}`, justifyContent: 'center' }} className="img-view">
                                            <img src={item?.profile_picture ? item?.profile_picture : require("../../assets/images/logo-sm-dark.png")} className="img" />
                                          </div>
                                          <span className="name">{item?.name} {item?.city ? `(${item?.city})` : ''}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Card>
                              )
                            })}
                          </div>
                        }
                      </div>
                    </div>
                  </>
                }
                {currentTab == 'arji' &&
                  <Row>
                    <Col>
                      <Card>
                        <CardBody>
                          {isTempForm ?
                            <div>
                              <AvForm onValidSubmit={submitNameCity}>
                                <AvField
                                  className="mb-2"
                                  name="name"
                                  label="Name"
                                  type="text"
                                />
                                <AvField
                                  className="mb-2"
                                  name="city"
                                  label="City"
                                  type="text"
                                />
                                <Col lg={12} md={12}>
                                  <div className="mt-4">
                                    <Row>
                                      <Col md={6}>
                                        <button
                                          className="btn btn-primary w-100 waves-effect waves-light"
                                          style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                                          onClick={() => { setIsTempForm(false) }}
                                          type="reset"
                                        >
                                          Cancel
                                        </button>
                                      </Col>
                                      <Col md={6}>
                                        <button
                                          className="btn btn-primary w-100 waves-effect waves-light"
                                          type="submit"
                                          style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                                        >
                                          Submit
                                        </button>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </AvForm>
                            </div>
                            :
                            <Row>
                              <Row className="mt-4">
                                <Col lg={10}></Col>
                                <Col lg={2} className="tab-active-type tab-active-ram" style={{ cursor: 'pointer' }} onClick={() => setIsTempForm(true)}>
                                  <span>Add Temp Names</span>
                                </Col>
                              </Row>
                              <Col lg={6}>
                                <Label>Temp Users</Label>
                                <DataTable
                                  columns={columns}
                                  data={tempUser}
                                  pagination
                                  customStyles={{
                                    headCells: {
                                      style: {
                                        color: 'black',
                                        fontWeight: 'bold',
                                        fontSize: 15,
                                        maxWidth: 80
                                      },
                                    },
                                    cells: {
                                      style: {
                                        maxWidth: 50
                                      }
                                    }
                                  }}
                                />
                              </Col>
                              <Col lg={6}>
                                <Label>Temp Cities</Label>
                                <DataTable
                                  columns={columns}
                                  data={tempCity}
                                  pagination
                                  customStyles={{
                                    headCells: {
                                      style: {
                                        color: 'black',
                                        fontWeight: 'bold',
                                        fontSize: 15,
                                        maxWidth: 80
                                      },
                                    },
                                    cells: {
                                      style: {
                                        maxWidth: 50
                                      }
                                    }
                                  }}
                                />
                              </Col>
                            </Row>
                          }
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                }
                {currentTab == 'board' &&
                  <div style={{ width: '90%' }}>
                    {topList?.map((item, index) => {
                      return (
                        <VerticalTimeline
                          layout="1-column-left"
                          lineColor={Utils.themeColor}
                        >
                          <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: Utils.themeColor, }}
                            contentArrowStyle={{ borderRight: Utils.themeColor }}
                            date={item?.createdAt}
                            iconStyle={{ background: Utils.themeColor, textAlignLast: 'center' }}
                            icon={<span style={{ color: '#fff', fontSize: index < 99 ? 28 : 20 }}>{index + 1}</span>}
                            position={'right'}
                          >
                            < Link to={{ pathname: "/anayltic/raam/" + item?.user[0]?._id, state: { "userId": item?.user[0]?._id } }}>
                              <p style={{ color: 'white', fontWeight: 'bold', fontSize: 20 }}>{item?.user[0]?.name} ( {item?.count} )</p>
                            </Link>
                          </VerticalTimelineElement>
                        </VerticalTimeline>
                      )
                    })}
                  </div>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}
export default Table;
