import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  CardTitle,
  CardSubtitle,
  Modal,
  ModalBody,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility';
import { AvField, AvForm } from "availity-reactstrap-validation";
import Loader from "../../components/Loader";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { get, post, put, upload } from '../../helpers/api_helper'
import { toast } from 'react-toastify';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { loginSuccess } from '../../store/actions';
import AddForm from "./AddUserForm";
import moment from 'moment'


const lang = props => {
  const { user } = props;
  const [isAddForm, setIsAddForm] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [image, setImage] = useState(null);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [cats, setCats] = useState([]);

  useEffect(() => {
    if (user?.token) {
      getData();
    }
  }, [user?.token]);

  useEffect(() => {
    if (!isAddForm)
      setCurrentData(null);
  }, [isAddForm]);

  const getData = () => {
    if (!loading) {
      setLoading(true);
      get("/user/admin", { token: user?.token })
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            setData(res?.data);
          } else
            toast.error(res?.error);
        })
        .catch(err => {
          toast.error('Something Went Wrong!');
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    setColumns([
      {
        name: 'Name',
        selector: row => row?.name,
        maxWidth: "220px"
      },
      {
        name: 'Email',
        selector: row => row?.email,
      },
      {
        name: 'Last Active',
        selector: row => moment(row?.lastSeen).format("DD-MMM-YYYY hh:mm a"),
        maxWidth: "180px"
      },
      {
        name: 'Created At',
        selector: row => moment(row.createdAt).format("DD-MMM-YYYY hh:mm a"),
        maxWidth: "180px"
      },
      {
        cell: (row) => <>
          <Button onClick={() => handleUpdateProj(row)}
            title={"Edit"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
          <Button onClick={() => handleDeleteProj(row, 'user')}
            title={"Ban"}
            style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: '120px'
      },
    ]);
  }, [data]);

  const handleUpdateProj = (row) => {
    setImage(row?.image);
    setCurrentData(row);
    setIsAddForm(true);
  }

  const handleUpdateStatus = (row) => {
    setCurrentData({ ...row, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj) => {
    setCurrentData(proj);
    setDeleteModal(true);
  }

  const onStatus = () => {
    // console.log('status', currentModalData);
    setLoading(true);
    let body = {
      userId: currentData?._id,
      isActive: !currentData?.isActive,
      token: user?.token
    }
    let url = `/user/update`;
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const onDelete = () => {
    setLoading(true);
    let body = {
      userId: currentData?._id,
      token: user?.token
    }
    let url = `/user/update`;
    if (currentData?.actionType == 'Paid') {
      body = { ...body, isPaid: !currentData?.isPaid };
    } else {
      body = { ...body, isDeleted: true };
    }
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return; // Item was dropped outside the list
    const items = [...data];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    let ids = [], position = [];
    items.forEach((item, index) => {
      ids.push(item?._id);
      position.push(index);
    })
    let body = {
      ids,
      position,
      token: user?.token
    }
    put("/lang/position", body)
      .then(res => {
        // if (res?.statusCode == 200)
        setData(items);
      })
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Sub Admin" />
        <Card>
          <CardBody>
            <Row>
              <DeleteModal
                show={deleteModal}
                onDeleteClick={() => currentData?.actionType == 'Status' ? onStatus() : onDelete()}
                onCloseClick={() => setDeleteModal(false)}
                actionType={currentData?.actionType}
                data={currentData}
              />
              {isAddForm ?
                <AddForm
                  data={currentData}
                  loading={loading}
                  setLoading={setLoading}
                  close={setIsAddForm}
                  user={user}
                  getData={getData}
                />
                :
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                        <div>
                          <CardTitle className="h4">Sub Admin</CardTitle>
                          <CardSubtitle className="mb-3">
                            Sub Admin Added Before, All the <code>Users</code> will also be able perform <code>actions</code> As per given <code>permissions.</code>
                          </CardSubtitle>
                        </div>
                        {user?.role == 'Admin' &&
                          <div className="text-center" style={{ marginLeft: 'auto' }}>
                            <Button style={{ backgroundColor: Utils.themeColor, border: 'none' }} onClick={() => setIsAddForm(true)}>
                              Add
                            </Button>
                          </div>
                        }
                      </div>
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="table">
                          {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                              <DataTable
                                columns={columns}
                                data={data}
                                pagination
                                customStyles={{
                                  headCells: {
                                    style: {
                                      color: 'black',
                                      fontWeight: 'bold',
                                      fontSize: 15,
                                      maxWidth: 80
                                    },
                                  },
                                  cells: {
                                    style: {
                                      maxWidth: 50
                                    }
                                  }
                                }}
                                conditionalRowStyles={[{
                                  when: row => row?.seen?.length < 1,
                                  style: { backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, color: 'white', cursor: 'pointer' },
                                }]}
                              // onRowClicked={(r, e) => seenNoti(r)}
                              />
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </CardBody>
                  </Card>
                </Col>
              }
            </Row>
          </CardBody>
        </Card>
        <audio id="audio" src={currentAudio} />
      </div>
    </React.Fragment>
  )
}


const mapStatetoProps = state => {
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStatetoProps, { loginSuccess })(lang)
)