import React, { useEffect, useState, useRef } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody, Label } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';
import { AvField, AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import { toast } from 'react-toastify';
import Utils from '../Utility';
import { post, get, put, upload } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import UiDropdown from "../Ui/UiDropdown";
import SearchableDropdown from "../Ui/UiSearchableDropdown";
import { Link } from 'react-router-dom';

// import './Card.scss';

const Table = (props) => {
  const { user, role, currentData, setIsTable, setSingleId, path, loading, setLoading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [proData, setProData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentModalData, setCurrentModalData] = useState(null);
  const [isAddForm, setIsAddForm] = useState(false);
  const [file, setFile] = useState(null);
  const [imageChanged, setImageChanged] = useState(false);
  const imageInput = useRef();
  const [logo, setLogo] = useState(null);
  const [logoChanged, setLogoChanged] = useState(false);
  const [selectedPros, setSelectedPros] = useState([]);
  const logoInput = useRef();
  const cropper = useRef();
  const logoCropper = useRef();

  useEffect(() => {
    if (user?.token) {
      getMetas();
    }
  }, [user?.token]);

  const getMetas = () => {
    if (!loading) {
      setLoading(true);
      get("/navigator", { token: user?.token })
        .then(res => {
          // console.log('response from get properties', res);
          setLoading(false);
          if (res?.statusCode == 200)
            setData(res?.data);
          else {
            toast.error(res?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while getting properties on hotels', error);
        });
    }
  }

  useEffect(() => {
    if (!isAddForm) {
      setCurrentModalData(null);
      setSelectedPros([]);
      setLogo(null);
      setFile(null);
      // imageInput.current.value = "";
    }
  }, [isAddForm]);

  // console.log('selected pros', selectedPros?.length);

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="d-flex" style={{ marginLeft: 'auto' }}>
                  <div>
                    {/* <CardTitle className="h4">{props.role}</CardTitle> */}
                    <CardSubtitle className="">
                      All the <code>Screens </code> makes record when user visits on these.
                    </CardSubtitle>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          {data?.map((item) => (
            <Col lg={3}>
              <Card>
                <CardBody>
                  <div style={{ marginLeft: 'auto' }}>
                    <h6>{item?.title}</h6>
                    <div style={{ marginTop: 20 }}>
                      {item?.action > 0 &&
                        <Link to={"/anayltic/screen/" + item?._id}>
                          <div style={{ paddingLeft: 10, backgroundColor: '#cf9802', color: '#fff', marginBottom: 10, borderRadius: 5, cursor: 'pointer' }}>
                            <p className="mb-0 p-2">Events : {item?.action}</p>
                          </div>
                        </Link>
                      }
                      {item?.registered > 0 &&
                        <div style={{ paddingLeft: 10, backgroundColor: '#82b74b', color: '#fff', marginBottom: 10, borderRadius: 5 }}>
                          <p className="mb-0 p-2">Registered : {item?.registered}</p>
                        </div>
                      }
                      {item?.visit > 0 &&
                        <div style={{ paddingLeft: 10, backgroundColor: '#c94c4c', color: '#fff', marginBottom: 10, borderRadius: 5 }}>
                          <p className="mb-0 p-2">Unregistered : {item?.visit - item?.registered}</p>
                        </div>
                      }
                      {item?.visit > 0 &&
                        <div style={{ paddingLeft: 10, backgroundColor: '#36486b', color: '#fff', marginBottom: 10, borderRadius: 5 }}>
                          <p className="mb-0 p-2">Total : {item?.visit}</p>
                        </div>
                      }
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>

      </div>

    </React.Fragment >
  )
}

export default Table;
