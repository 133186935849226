import React, { useEffect, useState } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';

import Utils from '../Utility';
import { post, get, put, download } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { AvField, AvForm } from "availity-reactstrap-validation";
import { saveAs } from 'file-saver';
// import './product.scss';

const Table = (props) => {
  const { user, role, currentData, from, setCurrentData, setLoading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    if (user) {
      getData();
    }
  }, [user]);

  const getData = () => {
    get("/product/admin/list", { token: user?.token })
      .then(json => {
        // console.log('response from get project list', json);
        if (json?.statusCode == 200) {
          setData(json?.data);
        }
      })
      .catch(error => {
        console.log('error while getting project list', error);
      })
  }

  const handleIsPopular = (id, val) => {
    console.log('changing popular', id, val);
    const body = {
      productId: id,
      isPopular: val == "true" ? false : true,
      token: user?.token
    }
    put("/product/update", body)
      .then(res => {
        console.log('response from changing popular', res);
        if (res?.statusCode == 200) {
          toast.success(res?.message);
        } else
          toast.error(res?.erorr);
      })
      .catch(error => {
        console.log('error while changing popular', error);
        toast.error(error);
      })
  }

  useEffect(() => {
    setColumns([
      // {
      //   cell: (row) => <>
      //     <AvForm>
      //       <AvField
      //         name="isPopular"
      //         type="checkbox"
      //         value={row?.isPopular ? true : false}
      //         onChange={(e) => handleIsPopular(row?._id, e.target.value)}
      //       />
      //     </AvForm>
      //   </>,
      //   name: <i className="mdi mdi-star" style={{ fontSize: 17 }} />,
      //   ignoreRowClick: true,
      // },
      {
        name: 'Image',
        cell: (row) => <>
          <img title={'banner'} src={row?.images[0]} style={{ width: 80, height: 'auto', marginTop: 5, marginBottom: 5 }} />
        </>,
      },
      {
        name: 'Title',
        selector: row => row.name,
      },
      {
        name: 'price',
        cell: (row) => <>
          <span>{row?.price} INR - <span style={{ textDecoration: 'line-through' }}>{row?.offPrice} INR</span></span>
        </>
        // selector: row => row?.price + "-" +row?.offPrice,
      },
      {
        name: 'Weight',
        selector: row => row?.weight + " " + row?.unit
      },
    // {
    //   name: 'Created At',
    //   selector: row => row.createdAt,
    // },
    {
      cell: (row) => <>
        <Button onClick={() => handleUpdateStatus(row)}
          title={row?.isActive ? "Inactive" : "Active"}
          style={{ backgroundColor: !row.isActive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}>
          <span className="text-white" style={{}}>
            {!row?.isActive ? 'Inactive' : 'Active'}
          </span>
        </Button>
      </>,
      name: 'Status',
      ignoreRowClick: true,
    },
    {
      cell: (row) => <>
        <Button onClick={() => props?.history.push(`/product/` + row?._id)}
          title={"View"}
          style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-eye" style={{ fontSize: 20 }}></span></Button>
        {from != 'Dashboard' &&
          <>
            <Button onClick={() => handleUpdateProj(row)}
              title={"Edit"}
              style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
            <Button onClick={() => handleDeleteProj(row)}
              title={"Delete"}
              style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
          </>
        }
      </>,
      name: 'Action',
      ignoreRowClick: true,
      // allowOverflow: true,
      // button: true,
    },
    ])
  }, [data]);

const handleOnHome = (id, val) => {
  console.log('checking on home', id, val);
  let body = {
    productId: id,
    onHome: val == "false" ? true : false,
    token: user?.token
  }
  put('/product/update', body)
    .then(json => {
      console.log('response from on click action', json);
      if (json.statusCode == 200) {
        if (!toast.isActive("product"))
          toast.success(json?.message, {
            toastId: "product"
          });
        getData();
        setDeleteModal(false);
        props?.setCurrentData(null);
      } else {
        if (!toast.isActive("product"))
          toast.error(json?.error, {
            toastId: "product"
          });
      }
    })
    .catch(error => {
      console.error('error while deleting user', error);
    })
}

const handleUpdateProj = (proj) => {
  console.log('updating proj', proj);
  props?.setCurrentData(proj);
  props.add(true);
}

const handleUpdateStatus = (item) => {
  console.log('updating proj', item);
  setCurrentData({ ...item, actionType: 'Status' });
  setDeleteModal(true);
}

const handleDeleteProj = (proj) => {
  console.log('updating proj', proj);
  props?.setCurrentData({ ...proj, actionType: 'Delete' });
  setDeleteModal(true);
}

const onDelete = () => {
  console.log('deleted', currentData);
  let body = {
    productId: currentData?._id,
    token: user?.token
  }
  let url = "/product/update";
  if (currentData?.actionType == 'Status') {
    body = { ...body, isActive: !currentData?.isActive };
    put(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  } else {
    url = "/product/delete"
    body = { ...body };
    post(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

}

const downloadZip = () => {
  setLoading(true);
  download("/product/download")
    .then(res => {
      console.log('response from download images', res);
      var blob = new Blob([res], { type: "application/octet-stream" });
      console.log('after blob', blob);
      setLoading(false);
      saveAs(blob, `store${new Date().getTime()}.zip`);
    })
    .catch(err => {
      console.log('error while downloading image', err);
    })
}

return (
  <React.Fragment>
    <div className="">
      <Row>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => onDelete()}
          onCloseClick={() => setDeleteModal(false)}
          data={currentData}
        />
        <Col className="col-12">
          <Card>
            <CardBody>
              {from != 'Dashboard' &&
                <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <div>
                    <CardTitle className="h4">{props.role}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {role} you have added before,<code> Anybody</code> can <code>check</code> and <code>order</code> on these. if <code>Status</code> is Active.
                    </CardSubtitle>
                  </div>
                  <div className="text-center" style={{ marginLeft: 'auto' }}>
                    {/* <Button style={{ backgroundColor: Utils.themeColor, marginRight: 10 }} onClick={() => downloadZip()}>
                      Zip Images
                    </Button> */}
                    <Button style={{ backgroundColor: Utils.themeColor }} onClick={() => props?.add(true)}>
                      Add
                    </Button>
                  </div>
                </div>
              }
              <DataTable
                columns={columns}
                data={data}
                pagination
                conditionalRowStyles={[{
                  when: row => row?.style,
                  style: row => ({ width: row?.style?.width }),
                },
                ]}
                customStyles={{
                  headCells: {
                    style: {
                      color: 'black',
                      fontWeight: 'bold',
                      fontSize: 15,
                      width: 0
                    },
                  },
                  cells: {
                    style: {
                      width: 0
                    }
                  }
                }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>

  </React.Fragment>
)
}

export default Table;
