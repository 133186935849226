import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  CardSubtitle,
  Modal,
  ModalBody,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter, useParams, useLocation, Link } from "react-router-dom";
import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility';
import Loader from "../../components/Loader";
import { get, post, put } from '../../helpers/api_helper';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import DataTable from 'react-data-table-component';
import moment from "moment";
import UserProfile from "../../components/UserProfile";
import ConfirmModal from "../../components/ConfirmModal";
import { toast } from "react-toastify";

const Agent = props => {
  const { user } = props;
  const params = useParams();
  const location = useLocation();
  const [currentData, setCurrentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [search, setSearch] = useState("");
  const [profileModal, setProfileModal] = useState(false);
  const [whatsappModal, setWhatsappModal] = useState(false);
  const [messages, setMessages] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);

  useEffect(() => {
    if (user?.token)
      getMsgs();
  }, [user])

  useEffect(() => {
    if (user?.token)
      getData(0);
  }, [user, search]);

  const getData = (currentPage) => {
    if (!loading) {
      setLoading(true);
      let url = `/user/sales?currentPage=${currentPage}&search=${search}`;
      get(url, { token: user?.token })
        .then(json => {
          setLoading(false);
          if (json?.statusCode == 200) {
            setData(json?.data);
            setTotalPage(json?.totalPage);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while getting project list', error);
        });
    }
  }

  useEffect(() => {
    setColumns([
      {
        name: 'Name',
        selector: row => row?.name,
        cell: (row) => <div>
          <i className={row?.deviceType == 'android' ? "mdi mdi-android" : "mdi mdi-apple-ios"} style={{ fontSize: 18 }} />
          <span style={{ textTransform: 'capitalize', marginLeft: 5 }}>{row?.name}</span>
        </div>,
        sortable: true,
        maxWidth: '200px'
      },
      {
        name: 'Mobile',
        selector: row => row?.mobile,
        sortable: true,
        maxWidth: '110px'
      },
      {
        name: 'Last Active',
        selector: row => row?.lastSeen,
        cell: (row) => <>
          <span style={{}}>{moment(row?.lastSeen).fromNow()}</span>
          {/* <span style={{}}>{moment(row?.lastSeen).format("DD MMM YYYY, hh:mm A")}</span> */}
        </>,
        sortable: true,
        maxWidth: '150px'
      },
      {
        name: 'Subscribed On',
        selector: row => row?.trans?.length ? moment(row?.trans[0]?.createdAt).format("DD MMM YYYY, hh:mm A") : "-",
        cell: (row) => <>
          <span style={{ textTransform: 'capitalize' }}>{row?.trans?.length ? moment(row?.trans[0]?.createdAt).format("DD MMM YYYY, hh:mm A") : "-"}</span>
        </>,
        sortable: true,
        maxWidth: '180px'
      },
      {
        name: 'Coupon Used',
        selector: row => row?.trans?.length ? row?.trans[0]?.coupon?.length ? row?.trans[0]?.coupon[0]?.code : "-" : "-",
        cell: (row) => <>
          <span style={{ textTransform: 'capitalize' }}>{row?.trans?.length ? row?.trans[0]?.coupon?.length ? row?.trans[0]?.coupon[0]?.code : "-" : "-"}</span>
        </>,
        sortable: true,
        maxWidth: '180px'
      },
      {
        name: 'Registered On',
        selector: row => row?.createdAt,
        cell: (row) => <>
          <span style={{ textTransform: 'capitalize' }}>{moment(row?.createdAt).format("DD MMM YYYY, hh:mm A")}</span>
        </>,
        sortable: true,
        maxWidth: '180px'
      },
      {
        name: 'Call Remark',
        selector: row => row?.remark,
        cell: (row) => <>
          <span style={{ textTransform: 'capitalize' }}>{row?.remark}</span>
        </>,
        sortable: true,
        maxWidth: '180px'
      },
      {
        name: 'Next Follow Up',
        selector: row => row?.nextFollow,
        cell: (row) => <>
          <span style={{ textTransform: 'capitalize' }}>{row?.nextFollow ? moment(row?.nextFollow).format("DD MMM YYYY, hh:mm A") : "-"}</span>
        </>,
        sortable: true,
        maxWidth: '180px'
      },
      // {
      //   name: 'Added To Comm.',
      //   selector: row => row?.isAddedToComm,
      //   cell: (row) => <>
      //     <span style={{ textTransform: 'capitalize' }}>{row?.isAddedToComm ? "Yes" : "No"}</span>
      //   </>,
      //   sortable: true,
      //   maxWidth: '180px'
      // },
      {
        cell: (row) => <>
          <Button onClick={() => handleAddComm(row)}
            title={row?.isAddedToComm ? "Yes" : "No"}
            style={{ backgroundColor: !row.isAddedToComm ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}>
            <span className="text-white" style={{}}>
              {!row?.isAddedToComm ? 'No' : 'Yes'}
            </span>
          </Button>
        </>,
        name: 'Added To Comm.',
        sortable: true,
        ignoreRowClick: true,
        maxWidth: "110px"
      },
      {
        cell: (row) => <>
          <>
            <Button
              title={"Analytic"}
              style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><Link target="_blank" to={row?._id ? "/anayltic/user/" + row?._id : ""}><span className={"mdi mdi-finance"} style={{ fontSize: 20, color: 'white' }}></span></Link></Button>
            <Button
              onClick={() => { setCurrentData(row); setProfileModal(true) }}
              title={"View"}
              style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={"mdi mdi-eye"} style={{ fontSize: 20, color: 'white' }}></span></Button>
            <Button onClick={() => handleWhatsapp(row)}
              title={"Whatsapp"}
              style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={"mdi mdi-whatsapp"} style={{ fontSize: 20, color: 'white' }}></span></Button>
            <Button onClick={() => handleSMS(row)}
              title={"SMS"}
              style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={"mdi mdi-message-processing"} style={{ fontSize: 20, color: 'white' }}></span></Button>
            <Button onClick={() => handleCall(row)}
              title={"Call"}
              style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className={"mdi mdi-phone"} style={{ fontSize: 20, color: 'white' }}></span></Button>
          </>
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: '190px'
      }
    ]);
  }, [data]);

  const getMsgs = () => {
    get("/whatsapp/list", { token: user?.token })
      .then(json => {
        setLoading(false);
        if (json?.statusCode == 200) {
          setMessages(json?.data);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error while getting project list', error);
      });
  }

  const handlePageChange = (page) => {
    // console.log('paginat', page);
    getData((page - 1) * 100);
  }

  const handleWhatsapp = (row) => {
    setCurrentData(row);
    setWhatsappModal(true);
  }

  const sendWhatsapp = (e, v) => {
    // console.log("whatsappMsg", v, currentData);
    const msg = messages?.find(x => x?._id == v?.whatsappMsg);
    if (msg) {
      const encodedMessage = encodeURIComponent(`${msg?.message}`);
      const whatsappUrl = `https://wa.me/${currentData?.mobile}?text=${encodedMessage}`;
      window.open(whatsappUrl, '_blank');
    } else {
      toast.error("Message not found!");
    }
  }

  const handleSMS = (row) => {
    const encodedMessage = encodeURIComponent(`प्रिय ${row?.name ? row?.name : "उपभोक्ता"}, प्रार्थना ऐप की ओर से शुभकामनाएँ, प्रार्थना ऐप में आपका स्वागत है।`);
    const smsUrl = `sms:${row?.mobile}?body=${encodedMessage}`;
    window.location.href = smsUrl;
  }

  const handleCall = (row) => {
    const smsUrl = `tel:${row?.mobile}`;
    window.location.href = smsUrl;
  }

  const handleAddComm = (row) => {
    setCurrentData({ ...row, actionType: "Comm" });
    setConfirmModal(true);
  }

  const onConfirm = () => {
    // console.log("on confirm", currentData);
    if (currentData?.actionType == "Comm") {
      let body = {
        userId: currentData?._id,
        isAddedToComm: !currentData?.isAddedToComm,
        token: user?.token
      }
      put("/user/update", body)
        .then(res => {
          if (res?.statusCode == 200) {
            toast.success(res?.message);
            setConfirmModal(false);
            getData(0);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          console.log("error while sending sms", err);
          toast.error("Something Went Wrong!");
        })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Users" />
        {profileModal &&
          <UserProfile
            show={profileModal}
            onCloseClick={() => setProfileModal(false)}
            userId={currentData?._id}
          />
        }
        <ConfirmModal
          show={confirmModal}
          data={currentData}
          onConfirm={onConfirm}
          onCloseClick={() => { setConfirmModal(false); setCurrentData(null) }}
        />
        <Modal className="modal_form" isOpen={whatsappModal} toggle={() => { setWhatsappModal(false); setCurrentData(null) }} centered={true}>
          <ModalBody className="py-3 px-5">
            <h5 className="text-black font-size-20 mb-4">{`Send Whatsapp message to ${currentData?.name ? currentData?.name : currentData?.mobile}`}</h5>
            <div className="p-2">
              <AvForm
                className="form-horizontal"
                onValidSubmit={sendWhatsapp}
              >
                <Row>
                  <Col md={12}>
                    <div className="form-group mb-4">
                      <AvField
                        name="whatsappMsg"
                        label={"Whatsapp Message"}
                        className="form-control"
                        type="select"
                        required
                      >
                        <option value="">Select Whatsapp Message</option>
                        {messages?.map((item) => (
                          <option value={item?._id}>{item?.title}</option>
                        ))}
                      </AvField>
                    </div>
                  </Col>
                  <Col lg={12} md={12}>
                    <div className="mt-4">
                      <Row>
                        <Col md={6}>
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                            onClick={() => { setWhatsappModal(false); setCurrentData(null) }}
                            type="reset"
                          >
                            Cancel
                          </button>
                        </Col>
                        <Col md={6}>
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                            style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                          >
                            Send
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </div>
          </ModalBody>
        </Modal>
        <Card>
          <CardBody>
            <Row>
              <Col md={11}>
                <CardTitle className="h4">{props.role}</CardTitle>
                <CardSubtitle className="mb-3">
                  Users those who registered himself, All the <code>Users</code> will also be able see and <code>actions</code> on the <code>Properties.</code>
                </CardSubtitle>
              </Col>
              <Col md={1}>
                <h4>Users ({totalPage})</h4>
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={8}>
                <AvForm>
                  <div>
                    <AvField
                      name="search"
                      label="Search"
                      placeholder="Search by name, email, mobile"
                      type="text"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    >
                    </AvField>
                  </div>
                </AvForm>
              </Col>
            </Row>
            <DataTable
              columns={columns}
              data={data}
              pagination
              // paginationComponent={CustomPagination}
              // pagination
              paginationPerPage={100}
              paginationRowsPerPageOptions={[100, 200, 300, 400]}
              // paginationComponentOptions={{ selectAllRowsItem: true }}
              paginationServer
              paginationTotalRows={totalPage}
              // onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              customStyles={{
                headCells: {
                  style: {
                    color: 'black',
                    fontWeight: 'bold',
                    fontSize: 15,
                    maxWidth: 80
                  },
                },
                cells: {
                  style: {
                    maxWidth: 50
                  }
                }
              }}
            />
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStatetoProps, {})(Agent)
)
