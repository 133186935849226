import React, { useState, useEffect, useRef } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Modal, ModalBody, Label, CardTitle
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Utils from '../Utility'
import { post, upload, put, get } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import moment from 'moment/moment';
import Multiselect from "multiselect-react-dropdown";


const AddEvent = props => {
    const { user, data, setLoading, role } = props;
    const [file, setFile] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [infoImage, setInfoImage] = useState(null);
    const [audioFile, setAudioFile] = useState(undefined);
    const [thumbImage, setThumbImage] = useState(null);
    const [image, setImage] = useState(null);
    const [banner, setBanner] = useState(null);
    const imageInput = useRef();
    const imageInput2 = useRef();
    const cropper = useRef();
    const [currentData, setCurrentData] = useState(null);
    const [cropperModal, setCropperModal] = useState(false);
    const [thumbCropper, setThumbCropperModal] = useState(false);
    const [calen, setCalen] = useState([]);
    const [cat, setCat] = useState([]);

    const [currentModalData, setCurrentModalData] = useState(null);
    const [selectedCat, setSelectedCat] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const audioRef = useRef();

    useEffect(() => {
        if (data) {
            if (data?.image) {
                setImageFile(data?.image);
                setImage(data?.image);
            }
            if (data?.banner)
                setBanner(data?.banner);
            if (data?.catId?.length)
                setSelectedCat(data?.catId);
        }
    }, [data]);

    useEffect(() => {
        if (user?.token) {
            get('/calendar/drop', { token: user?.token })
                .then(res => {
                    setCalen(res?.data);
                })
            get('/calendar/cat/drop', { token: user?.token })
                .then(res => {
                    setCat(res?.data);
                })
        }
    }, [user?.token]);

    const onChangeFile = async (e, type) => {
        console.log('on change file', e.target);
        if (type == "info") {
            setFile(URL.createObjectURL(e.target.files[0]));
            setCropperModal(true);
        }
        if (type == "image") {
            setImageFile(URL.createObjectURL(e.target.files[0]));
            setThumbCropperModal(true);
        }
    }

    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1800,
                600,
                "webp",
                60,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    const cropImage = (e, v) => {
        setLoading(true);
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        console.log('===>', currentData)
        if (currentData == 'file') {
            setFile(fileData)
        }

        setCropperModal(false);
        setLoading(false);
    }
    const cropThumbImage = (e, v) => {
        // alert('dsds')
        setLoading(true);
        const fileData = imageInput?.current?.cropper?.getCroppedCanvas().toDataURL();
        console.log('cropThumbImage===>', fileData)
        // if (currentData == 'file') {
        setImageFile(fileData)
        // }

        setThumbCropperModal(false);
        setLoading(false);
    }

    const handleValidSubmit = async (e, v) => {
        if (!selectedCat?.length) {
            toast.error("Please Select Atleast one category!");
            return;
        }
        let image = "";
        if (imageFile?.length > 85) {
            const fileObj = await urltoFile(imageFile, (new Date().getTime() + 300) + '.webp', 'image/webp')
            const form = new FormData();
            form.append("image", fileObj);
            const uploadedBanner = await upload("/calendar/image_upload", form)
            console.log('response from image upload', uploadedBanner);
            if (uploadedBanner?.statusCode == 200)
                image = uploadedBanner?.data;
        } else {
            image = imageFile;
        }
        let tempCat = []
        selectedCat?.map((item) => (
            tempCat.push(item?._id)
        ))
        // setLoading(true);
        let body = {
            ...v,
            audio: audioFile,
            token: user?.token,
            catId: tempCat,
            // startTime: moment(v?.startTime, "YYYY-MM-DDTHH:mm").utc().toISOString(),
            // endTime: moment(v?.endTime, "YYYY-MM-DDTHH:mm").utc().toISOString()
        }
        console.log('add event in Calendar => ', body);
        if (image)
            body = { ...body, image };
        if (data?._id) {
            body = { ...body, eventId: data?._id, }
            put("/calendar/event", body)
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        toast.success(res?.message);
                        setImage(null);
                        setImageFile(null);
                        setFile(null);
                        props?.close(false);
                    } else {
                        toast.success(res?.error);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    console.log('err while adding story', err);
                    toast.error('Something Went Wrong!')
                })
        } else {
            post("/calendar/event", body)
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        toast.success(res?.message);
                        setImage(null);
                        setFile(null);
                        setImageFile(null);
                        props?.close(false);
                    } else {
                        toast.success(res?.error);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    console.log('err while adding story', err);
                    toast.error('Something Went Wrong!')
                })
        }
    }

    const togglePlay = () => {
        const audioElement = document.getElementById('audio');
        console.log("audio element", audioElement);
        if (!audioElement)
            return;
        if (isPlaying)
            audioElement.pause();
        else
            audioElement.play();
        setIsPlaying(!isPlaying);
    };

    const handleTimeUpdate = () => {
        setCurrentTime(audioRef.current.currentTime);
    };

    const handleSeek = (e) => {
        const seekTime = e.target.value;
        audioRef.current.currentTime = seekTime;
        setCurrentTime(seekTime);
    };

    return (
        <React.Fragment>
            <div>
                <Row>
                    {/*<Col lg="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg={11} md={11}>
                                        <Row>
                                            {file ?
                                                <Col lg={2} md={2} style={{ marginRight: '2rem' }}>
                                                    <div className="me-3 position-relative" style={{ width: 180, height: 180 }}>
                                                        <img
                                                            src={image ? image : avatar}
                                                            alt=""
                                                            style={{ width: '71%', height: '100%' }}
                                                        />
                                                        <div style={{ position: 'absolute', height: 40, width: 40, right: 35, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                            onClick={(e) => { setFile(null); setImage(null) }}
                                                        >
                                                            <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                                :
                                                <Col lg={2} md={2}>
                                                    <div className="me-3 position-relative" style={{ width: 180, height: 180 }}>
                                                        <div className='image_placeholder' style={{ width: '71%', height: '100%' }}
                                                            onClick={(e) => imageInput2.current.click(e)}
                                                        >
                                                            <button style={{ backgroundColor: Utils.themeColor, borderRadius: 5, color: '#fff', padding: '5px 10px', border: 'none' }}> + Add</button>
                                                            <div style={{ position: 'absolute', height: 40, width: 40, right: 35, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                            >
                                                                <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                                                            </div>
                                                            <input type="file" id="file"
                                                                ref={imageInput2}
                                                                onChange={(e) => onChangeFile(e, 'file')}
                                                                style={{ display: "none" }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                            }
                                            {banner &&
                                                <Col lg={2} md={2} style={{ marginRight: '2rem' }}>
                                                    <div className="me-3 position-relative" style={{ width: 360, height: 180 }}>
                                                        <img
                                                            src={banner ? banner : avatar}
                                                            alt=""
                                                            style={{ width: '100%', height: '100%' }}
                                                        />
                                                        <div style={{ position: 'absolute', height: 40, width: 40, right: -20, top: -20, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                            onClick={(e) => { setBanner(null) }}
                                                        >
                                                            <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                            }
                                        </Row>
                                    </Col>
                                    <Col lg={1} md={1}>
                                        <div className="text-center" style={{ marginLeft: 'auto' }}>
                                            <Button color="primary" onClick={() => { props.setCurrentData(null); props?.close(false) }} style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                                                Close
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col> */}
                </Row>
                {/* Image cropper modal */}
                <Modal isOpen={thumbCropper} toggle={() => { setThumbCropperModal(false); setImageFile(null) }} centered={true}>
                    <ModalBody className="py-3 px-5">
                        <h5 className="text-black font-size-20">Crop Thumb Image</h5>
                        <div className="p-2">
                            <AvForm
                                className="form-horizontal"
                                onValidSubmit={(e, v) => {
                                    cropThumbImage(e, v)
                                }}
                            >
                                <div className="mb-3 mt-2">
                                    <Cropper
                                        style={{ height: 'auto', width: 400 }}
                                        aspectRatio={1 / 1}
                                        preview=".img-preview"
                                        guides={true}
                                        src={imageFile}
                                        ref={imageInput}
                                    />
                                </div>
                                <div className="mt-4">
                                    <Row>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                style={{ backgroundColor: Utils.themeColor }}
                                                onClick={() => { setThumbCropperModal(false); setImageFile(null) }}
                                                type="reset"
                                            >
                                                Cancel
                                            </button>
                                        </Col>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                type="submit"
                                                style={{ backgroundColor: Utils.themeColor }}
                                            >
                                                Submit
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </AvForm>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={cropperModal} toggle={() => { setCropperModal(false); setFile(null) }} centered={true}>
                    <ModalBody className="py-3 px-5">
                        <h5 className="text-black font-size-20">Crop Image</h5>
                        <div className="p-2">
                            <AvForm
                                className="form-horizontal"
                                onValidSubmit={(e, v) => {
                                    cropImage()
                                }}
                            >
                                <div className="mb-3 mt-2">
                                    <Cropper
                                        style={{ height: 'auto', width: 400 }}
                                        aspectRatio={1.5 / 1}
                                        preview=".img-preview"
                                        guides={true}
                                        src={file}
                                        ref={cropper}
                                    />
                                </div>
                                <div className="mt-4">
                                    <Row>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                style={{ backgroundColor: Utils.themeColor }}
                                                onClick={() => { setCropperModal(false); setFile(null) }}
                                                type="reset"
                                            >
                                                Cancel
                                            </button>
                                        </Col>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                type="submit"
                                                style={{ backgroundColor: Utils.themeColor }}
                                            >
                                                Submit
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </AvForm>
                        </div>
                    </ModalBody>
                </Modal>

                <Card>
                    <CardBody>
                        <Row className='mb-4'>
                            <Col md={11}>
                                <h4 className="card-title mb-4">{data?._id ? 'Update ' + role : 'Add a ' + role}</h4>
                            </Col>
                            <Col lg={1} md={1}>
                                <div className="text-center" style={{ marginLeft: 'auto' }}>
                                    <Button color="primary" onClick={() => { props.setCurrentData(null); props?.close(false) }} style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                                        Close
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                handleValidSubmit(e, v)
                            }}
                        >
                            <Row>
                                {/* Month * Category * */}
                                <Row>
                                    <Col md={4}>
                                        <div className="form-group mb-4">
                                            <AvField
                                                name="monthId"
                                                label="Month *"
                                                className="form-control"
                                                type="select"
                                                value={data?.monthId}
                                                required
                                            >
                                                <option value={""} >Select Month</option>
                                                {calen?.map((item) => (
                                                    <option value={item?._id} >{item?.title}</option>
                                                ))}
                                            </AvField>
                                        </div>
                                    </Col>
                                    {/* <Col md={4}>
                                        <div className="form-group mb-4">
                                            <AvField
                                                name="catId"
                                                label="Category *"
                                                className="form-control"
                                                type="select"
                                                value={data?.catId}
                                                required
                                            >
                                                <option value={""} >Select Category</option>
                                                {cat?.map((item) => (
                                                    <option value={item?._id} >{item?.title}</option>
                                                ))}
                                            </AvField>
                                        </div>
                                    </Col> */}
                                    < Col lg={6} md={6}>
                                        <div className="mb-3">
                                            <label>Select Category *</label>
                                            <Multiselect
                                                options={cat}
                                                onSelect={(selectedList, selectedItem) => setSelectedCat(selectedList)}
                                                onRemove={(selectedList, removedItem) => setSelectedCat(selectedList)}
                                                displayValue="title"
                                                // selectionLimit={4}
                                                placeholder={"Select Category"}
                                                selectedValues={selectedCat}
                                                showCheckbox
                                            // name="Opportunities"
                                            />
                                        </div>
                                    </Col>

                                    <Col md={4}>
                                        <div className="form-group mb-4">
                                            <AvField
                                                name="paksha"
                                                label="Paksha *"
                                                className="form-control"
                                                type="select"
                                                value={data?.paksha}
                                                required
                                            >
                                                <option value={""} >Select Paksha</option>
                                                <option value={"Krishna"} >Krishna</option>
                                                <option value={"Shukla"} >Shukla</option>
                                            </AvField>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="form-group mb-4">
                                            <AvField
                                                name="date"
                                                label="Event Date *"
                                                value={moment(data?.date).format("YYYY-MM-DD")}
                                                className="form-control"
                                                type="date"
                                                // onChange={d=>console.log("date on change", d.target.value)}
                                                required
                                            >
                                            </AvField>
                                        </div>
                                    </Col>
                                </Row>
                                {/* Date * Timing * */}
                                <Row>
                                    <Col md={4}>
                                        <div className="form-group mb-4">
                                            <AvField
                                                name="startDate"
                                                label="Start Date *"
                                                value={moment(data?.startDate).format("YYYY-MM-DD")}
                                                className="form-control"
                                                type="date"
                                                // onChange={d=>console.log("date on change", d.target.value)}
                                                required
                                            >
                                            </AvField>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="form-group mb-4">
                                            <AvField
                                                name="endDate"
                                                label="End Date *"
                                                value={moment(data?.endDate).format("YYYY-MM-DD")}
                                                className="form-control"
                                                type="date"
                                                // onChange={d=>console.log("date on change", d.target.value)}
                                                required
                                            >
                                            </AvField>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="form-group mb-4">
                                            <AvField
                                                name="startTime"
                                                label="Start Time *"
                                                value={data?.startTime}
                                                className="form-control"
                                                type="time"
                                                // onChange={d=>console.log("date on change", d.target.value)}
                                                required
                                            >
                                            </AvField>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="form-group mb-4">
                                            <AvField
                                                name="endTime"
                                                label="End Time *"
                                                value={data?.endTime}
                                                className="form-control"
                                                placeholder="Enter Timing"
                                                type="time"
                                                required
                                            >
                                                {/* <option value="" >Select Date</option>
                                            {Array.from({ length: 31 }, (_, index) => index + 1).map((item) => (
                                                <option key={item} value={item} >{item}</option>
                                            ))
                                            } */}
                                            </AvField>
                                        </div>
                                    </Col>
                                </Row>

                                {/* Title  Hi En */}
                                <Row>
                                    <Col md={6}>
                                        <div className="form-group mb-4">
                                            <AvField
                                                name="titleHi"
                                                label="Title Hindi *"
                                                // autocomplete="off"
                                                value={data?.titleHi}
                                                className="form-control"
                                                placeholder="Enter Title in Hindi"
                                                type="text"
                                                required
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form-group mb-4">
                                            <AvField
                                                name="title"
                                                label="Title English *"
                                                value={data?.title}
                                                className="form-control"
                                                placeholder="Enter Title in English"
                                                type="text"
                                                required
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                {/* Mantra Hi En */}
                                <Row>
                                    <Col md={6}>
                                        <div className="form-group mb-4">
                                            <AvField
                                                name="mantraHi"
                                                label="Mantra in Hindi *"
                                                value={data?.mantraHi}
                                                className="form-control"
                                                placeholder="Enter Mantra in Hindi"
                                                type="textarea"
                                                rows={4}
                                                required
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form-group mb-4">
                                            <AvField
                                                name="mantra"
                                                label="Mantra in English *"
                                                value={data?.mantra}
                                                className="form-control"
                                                placeholder="Enter Mantra in English"
                                                type="textarea"
                                                rows={4}
                                                required
                                            />
                                        </div>
                                    </Col>

                                </Row>

                                {/* Vidhi  Hi En*/}
                                <Row>
                                    <Col md={6}>
                                        <div className="form-group mb-4">
                                            <AvField
                                                name="vidhiHi"
                                                label="Vidhi in Hindi*"
                                                value={data?.vidhiHi}
                                                className="form-control"
                                                placeholder="Enter Vidhi in Hindi"
                                                type="textarea"
                                                rows={4}
                                                required
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form-group mb-4">
                                            <AvField
                                                name="vidhi"
                                                label="Vidhi in English *"
                                                value={data?.vidhi}
                                                className="form-control"
                                                placeholder="Enter Vidhi in English"
                                                type="textarea"
                                                rows={4}
                                                required
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                {/* Mahatva Hi En */}
                                <Row>
                                    <Col md={6}>
                                        <div className="form-group mb-4">
                                            <AvField
                                                name="mahatvaHi"
                                                label="Mahatva in Hindi *"
                                                value={data?.mahatvaHi}
                                                className="form-control"
                                                placeholder="Enter Mahatva in Hindi"
                                                type="textarea"
                                                rows={4}
                                                required
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form-group mb-4">
                                            <AvField
                                                name="mahatva"
                                                label="Mahatva in English *"
                                                value={data?.mahatva}
                                                className="form-control"
                                                placeholder="Enter Mahatva in English"
                                                type="textarea"
                                                rows={4}
                                                required
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                {/* Katha Hi En */}
                                <Row>
                                    <Col md={6}>
                                        <div className="form-group mb-4">
                                            <AvField
                                                name="kathaHi"
                                                label="Katha in Hindi *"
                                                value={data?.kathaHi}
                                                className="form-control"
                                                placeholder="Enter Katha in Hindi"
                                                type="textarea"
                                                rows={4}
                                                required
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form-group mb-4">
                                            <AvField
                                                name="katha"
                                                label="Katha English *"
                                                value={data?.katha}
                                                className="form-control"
                                                placeholder="Enter Katha in English"
                                                type="textarea"
                                                rows={4}
                                                required
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                {/* Samagri Hi En */}
                                <Row>
                                    <Col md={6}>
                                        <div className="form-group mb-4">
                                            <AvField
                                                name="samagriHi"
                                                label="Samagri in Hindi *"
                                                value={data?.samagriHi}
                                                className="form-control"
                                                placeholder="Enter Samagri in Hindi"
                                                type="textarea"
                                                rows={4}
                                                required
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form-group mb-4">
                                            <AvField
                                                name="samagri"
                                                label="Samagri in English *"
                                                value={data?.samagri}
                                                className="form-control"
                                                placeholder="Enter Samagri in English"
                                                type="textarea"
                                                rows={4}
                                                required
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                {/* Notification */}
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="notification.title"
                                            label="Notification Title *"
                                            value={data?.notification?.title}
                                            className="form-control"
                                            placeholder="Enter notification"
                                            type="text"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="notification.body"
                                            label="Notification Body*"
                                            value={data?.notification?.body}
                                            className="form-control"
                                            placeholder="Enter notification"
                                            type="textarea"
                                            required
                                        />
                                    </div>
                                </Col>
                                {/* Audio */}
                                <Row>
                                    <Col md={6}>
                                        {audioFile ?
                                            <div className="form-group mb-4">
                                                <div className="audio-player">
                                                    <audio
                                                        id="audio"
                                                        ref={audioRef}
                                                        src={audioFile}
                                                        onTimeUpdate={handleTimeUpdate}
                                                    />
                                                    <Label>Audio </Label>
                                                    <div>
                                                        <button type="button" onClick={togglePlay}><i className={isPlaying ? "mdi mdi-pause" : 'mdi mdi-play'} /></button>
                                                        <input
                                                            type="range"
                                                            value={currentTime}
                                                            max={audioRef.current && audioRef.current.duration}
                                                            onChange={handleSeek}
                                                        />
                                                        <button type="button" onClick={() => setAudioFile(null)}><i className={'mdi mdi-delete'} /></button>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="form-group mb-4">
                                                <AvField
                                                    name="audio"
                                                    label="Audio *"
                                                    // value={data?.audio}
                                                    className="form-control"
                                                    placeholder="Enter banner related color code (i.e) 00ff00"
                                                    type="file"
                                                    accept="audio/mp3"
                                                    multiple={false}
                                                    onChange={(e) => setAudioFile(URL.createObjectURL(e.target.files[0]))}
                                                // required
                                                />
                                            </div>
                                        }
                                    </Col>
                                    <Col md={6}>
                                        <Label>Audio Duration *</Label>
                                        <div className="form-group mb-4 d-flex align-items-center">
                                            <AvField
                                                name="hour"
                                                value={data?.audioDuration?.hour}
                                                className="form-control"
                                                type="select"
                                            // required
                                            >
                                                <option value="">Select Hour</option>
                                                <option value={0}>0</option>
                                                <option value={1}>1</option>
                                                <option value={2}>2</option>
                                                <option value={3}>3</option>
                                                <option value={4}>4</option>
                                                <option value={5}>5</option>
                                                <option value={6}>6</option>
                                            </AvField>
                                            <span className='mx-2'>:</span>
                                            <AvField
                                                name="minute"
                                                value={data?.audioDuration?.minute}
                                                className="form-control"
                                                type="select"
                                            // required
                                            >
                                                <option value="">Select minute</option>
                                                <option value={0}>00</option>
                                                <option value={1}>01</option>
                                                <option value={2}>02</option>
                                                <option value={3}>03</option>
                                                <option value={4}>04</option>
                                                <option value={5}>05</option>
                                                <option value={6}>06</option>
                                                <option value={7}>07</option>
                                                <option value={8}>08</option>
                                                <option value={9}>09</option>
                                                <option value={10}>10</option>
                                                <option value={11}>11</option>
                                                <option value={12}>12</option>
                                                <option value={13}>13</option>
                                                <option value={14}>14</option>
                                                <option value={15}>15</option>
                                                <option value={16}>16</option>
                                                <option value={17}>17</option>
                                                <option value={18}>18</option>
                                                <option value={19}>19</option>
                                                <option value={20}>20</option>
                                                <option value={21}>21</option>
                                                <option value={22}>22</option>
                                                <option value={23}>23</option>
                                                <option value={24}>24</option>
                                                <option value={25}>25</option>
                                                <option value={26}>26</option>
                                                <option value={27}>27</option>
                                                <option value={28}>28</option>
                                                <option value={29}>29</option>
                                                <option value={30}>30</option>
                                                <option value={31}>31</option>
                                                <option value={32}>32</option>
                                                <option value={33}>33</option>
                                                <option value={34}>34</option>
                                                <option value={35}>35</option>
                                                <option value={36}>36</option>
                                                <option value={37}>37</option>
                                                <option value={38}>38</option>
                                                <option value={39}>39</option>
                                                <option value={40}>40</option>
                                                <option value={41}>41</option>
                                                <option value={42}>42</option>
                                                <option value={43}>43</option>
                                                <option value={44}>44</option>
                                                <option value={45}>45</option>
                                                <option value={46}>46</option>
                                                <option value={47}>47</option>
                                                <option value={48}>48</option>
                                                <option value={49}>49</option>
                                                <option value={50}>50</option>
                                                <option value={51}>51</option>
                                                <option value={52}>52</option>
                                                <option value={53}>53</option>
                                                <option value={54}>54</option>
                                                <option value={55}>55</option>
                                                <option value={56}>56</option>
                                                <option value={57}>57</option>
                                                <option value={58}>58</option>
                                                <option value={59}>59</option>
                                            </AvField>
                                            <span className='mx-2'>:</span>
                                            <AvField
                                                name="second"
                                                value={data?.audioDuration?.second}
                                                className="form-control"
                                                type="select"
                                            // required
                                            >
                                                <option value="">Select Second</option>
                                                <option value={0}>00</option>
                                                <option value={1}>01</option>
                                                <option value={2}>02</option>
                                                <option value={3}>03</option>
                                                <option value={4}>04</option>
                                                <option value={5}>05</option>
                                                <option value={6}>06</option>
                                                <option value={7}>07</option>
                                                <option value={8}>08</option>
                                                <option value={9}>09</option>
                                                <option value={10}>10</option>
                                                <option value={11}>11</option>
                                                <option value={12}>12</option>
                                                <option value={13}>13</option>
                                                <option value={14}>14</option>
                                                <option value={15}>15</option>
                                                <option value={16}>16</option>
                                                <option value={17}>17</option>
                                                <option value={18}>18</option>
                                                <option value={19}>19</option>
                                                <option value={20}>20</option>
                                                <option value={21}>21</option>
                                                <option value={22}>22</option>
                                                <option value={23}>23</option>
                                                <option value={24}>24</option>
                                                <option value={25}>25</option>
                                                <option value={26}>26</option>
                                                <option value={27}>27</option>
                                                <option value={28}>28</option>
                                                <option value={29}>29</option>
                                                <option value={30}>30</option>
                                                <option value={31}>31</option>
                                                <option value={32}>32</option>
                                                <option value={33}>33</option>
                                                <option value={34}>34</option>
                                                <option value={35}>35</option>
                                                <option value={36}>36</option>
                                                <option value={37}>37</option>
                                                <option value={38}>38</option>
                                                <option value={39}>39</option>
                                                <option value={40}>40</option>
                                                <option value={41}>41</option>
                                                <option value={42}>42</option>
                                                <option value={43}>43</option>
                                                <option value={44}>44</option>
                                                <option value={45}>45</option>
                                                <option value={46}>46</option>
                                                <option value={47}>47</option>
                                                <option value={48}>48</option>
                                                <option value={49}>49</option>
                                                <option value={50}>50</option>
                                                <option value={51}>51</option>
                                                <option value={52}>52</option>
                                                <option value={53}>53</option>
                                                <option value={54}>54</option>
                                                <option value={55}>55</option>
                                                <option value={56}>56</option>
                                                <option value={57}>57</option>
                                                <option value={58}>58</option>
                                                <option value={59}>59</option>
                                            </AvField>
                                        </div>
                                    </Col>
                                </Row>

                                <Col md={6}>
                                    <div >
                                        {imageFile ?
                                            <>
                                                <CardTitle>Thumb Image</CardTitle>
                                                <div style={{ flexDirection: 'row', position: 'relative', cursor: 'pointer', marginTop: 20 }}>
                                                    <img src={image} style={{ width: 100, height: 'auto' }} />
                                                    <i className="mdi mdi-close" style={{
                                                        color: 'white', backgroundColor: Utils.themeColor, fontSize: 20,
                                                        position: 'absolute', top: -16, right: 18, borderRadius: 15, width: 30, height: 30, textAlign: 'center'
                                                    }}
                                                        onClick={() => { console.log('====', imageFile); setImageFile(null); if (currentModalData) setCurrentModalData({ ...currentModalData, image: undefined }) }}
                                                    />
                                                </div>
                                            </>
                                            :
                                            <AvField
                                                name="image"
                                                label="Thumb Image"
                                                className="form-control"
                                                placeholder="upload thumb image"
                                                type="file"
                                                onChange={e => onChangeFile(e, 'image')}
                                                multiple={false}
                                                ref={imageInput2}
                                                accept="image/png, image/jpeg"
                                            />
                                        }
                                    </div>
                                </Col>

                                {/* Infography */}
                                <Row>
                                    <Col md={6}>
                                        <div >
                                            {infoImage ?
                                                <>
                                                    <CardTitle>Infographic Image</CardTitle>
                                                    <div style={{ flexDirection: 'row', position: 'relative', cursor: 'pointer', marginTop: 20 }}>
                                                        <img src={infoImage} style={{ width: 100, height: 'auto' }} />
                                                        <i className="mdi mdi-close" style={{
                                                            color: 'white', backgroundColor: Utils.themeColor, fontSize: 20,
                                                            position: 'absolute', top: -16, right: 18, borderRadius: 15, width: 30, height: 30, textAlign: 'center'
                                                        }}
                                                            onClick={() => { setFile(null); if (currentModalData) setCurrentModalData({ ...currentModalData, infography: undefined }) }}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <AvField
                                                    name="infoImage"
                                                    label="Infographic Image"
                                                    className="form-control"
                                                    placeholder="upload Infography image"
                                                    type="file"
                                                    onChange={e => onChangeFile(e, 'info')}
                                                    multiple={false}
                                                    ref={imageInput2}
                                                    accept="image/png, image/jpeg"
                                                />
                                            }
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <AvField
                                            name="fileInput"
                                            label="Video"
                                            className="form-control"
                                            placeholder="upload Video"
                                            type="file"
                                            onChange={e => onChangeFile(e)}
                                            // required
                                            multiple={false}
                                            ref={imageInput2}
                                            accept="video/mp4"
                                        />
                                    </Col>
                                </Row>

                            </Row>
                            {/* <AvField name="idx" value={user?.company._id} type="hidden" /> */}
                            <div className="text-center mt-4">
                                {data?._id ?
                                    <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                        Update {role}
                                    </Button>
                                    :
                                    <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                        Add {role}
                                    </Button>
                                }
                            </div>
                        </AvForm>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment >
    )
}

const mapStatetoProps = state => {
    const { user } = state.Profile;
    return { user }
}

export default withRouter(
    connect(mapStatetoProps, {})(AddEvent)
)
